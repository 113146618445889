import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonitoramentoRoutingModule } from './monitoramento-routing.module';
import { EstacoesComponent } from './pages/estacoes/estacoes.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoxSelectEstacoesComponent } from './componentes/box-select-estacoes/box-select-estacoes.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapColorLegendComponent } from './componentes/map-color-legend/map-color-legend.component';
import { ModalHistoricoEstacaoComponent } from './componentes/modal-historico-estacao/modal-historico-estacao.component';
import { MapaInterpolacaoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/mapa-interpolacao/mapa-interpolacao.component';
import { PublicEstacaoPopupComponent } from './componentes/public-estacao-popup/public-estacao-popup.component';
import { MonitoramentoLegendaComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/monitoramento-legenda/monitoramento-legenda.component';
import { LegendaEstacoesComponent } from './componentes/legenda-estacoes/legenda-estacoes.component';
import { ModalReservatoriosComponent } from './componentes/modal-reservatorios/modal-reservatorios.component';
import { TextWithLabelComponent } from './componentes/modal-reservatorios/text-with-label/text-with-label.component';
import { GraficosReservatoriosComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-reservatorios/graficos-reservatorios/graficos-reservatorios.component';
import { PopupReservatorioComponent } from './componentes/popup-reservatorio/popup-reservatorio.component';

import { ModalFitroMapaMonitoramentoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes/componentes/modal-fitro-mapa-monitoramento/modal-fitro-mapa-monitoramento.component';
import { EstacoesMonitoramentoMapaComponent } from './pages/estacoes/componentes/estacoes-monitoramento-mapa/estacoes-monitoramento-mapa.component';

import { PopupInformacoesPcdComponent } from './pages/variaveis/componentes/popup-informacoes-pcd/popup-informacoes-pcd.component';
import { MonitoramentoLegendaPCDComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/monitoramento-legenda-pcd/monitoramento-legenda-pcd.component';
import { PopupInformacoesChuvaEstadoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/popup-informacoes-chuva-estado/popup-informacoes-chuva-estado.component';
import { PopupInformacoesIapmComponent } from './pages/variaveis/componentes/popup-informacoes-iapm/popup-informacoes-iapm.component';
import { RedeMonitoramentoComponent } from './pages/rede-monitoramento/rede-monitoramento.component';
import { EstacoesPluviometricasComponent } from './pages/estacoes-pluviometricas/estacoes-pluviometricas.component';
import { PopupMapaDesvioComponent } from './pages/variaveis/componentes/popup-mapa-desvio/popup-mapa-desvio.component';
import { EstacoesAutomaticasComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes-automaticas/estacoes-automaticas.component';
import { PopupPadraoComponent } from './componentes/popup-padrao/popup-padrao.component';
import { PopupInformacoesNdcComponent } from './pages/variaveis/componentes/popup-informacoes-ndc/popup-informacoes-ndc.component';
import { MonitoramentoLegendaHorizontalComponent } from './pages/variaveis/componentes/monitoramento-legenda-horizontal/monitoramento-legenda-horizontal.component';

@NgModule({
	declarations: [
		EstacoesComponent,
		EstacoesMonitoramentoMapaComponent,
		BoxSelectEstacoesComponent,
		MapColorLegendComponent,
		ModalHistoricoEstacaoComponent,
		PublicEstacaoPopupComponent,
		MapaInterpolacaoComponent,
		MonitoramentoLegendaComponent,
		MonitoramentoLegendaHorizontalComponent,
		MonitoramentoLegendaPCDComponent,
		LegendaEstacoesComponent,
		ModalReservatoriosComponent,
		TextWithLabelComponent,
		GraficosReservatoriosComponent,
		PopupReservatorioComponent,
		ModalFitroMapaMonitoramentoComponent,
		EstacoesMonitoramentoMapaComponent,
		PopupInformacoesPcdComponent,
		PopupInformacoesChuvaEstadoComponent,
		PopupInformacoesIapmComponent,
		RedeMonitoramentoComponent,
		EstacoesPluviometricasComponent,
		PopupMapaDesvioComponent,
		EstacoesAutomaticasComponent,
		EstacoesPluviometricasComponent,
		PopupPadraoComponent,
		PopupInformacoesNdcComponent,
	],
	imports: [
		CommonModule,
		MonitoramentoRoutingModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		LeafletModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [
		EstacoesMonitoramentoMapaComponent,
		MapaInterpolacaoComponent,
		LegendaEstacoesComponent,
		MonitoramentoLegendaComponent,
		MonitoramentoLegendaHorizontalComponent,
		MonitoramentoLegendaPCDComponent,
	],
})
export class MonitoramentoModule {}
