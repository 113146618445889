<label class="text-tertiary text-center fw-semibold mt-3 w-100">
	{{ quantisType }} - {{ city }} - {{ periodoTitulo }}
</label>

<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />
<div
	*ngIf="isLoading; else tabela"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-template #tabela>
	<div style="max-width: 1300px" *ngIf="tableData; else semDados">
		<seira-tabela-relatorios-quantis
			[relatorios]="tableData"
			[isAnual]="isAnual" />
	</div>
</ng-template>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
