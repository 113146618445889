import { Component, Input, OnInit } from '@angular/core';
import {
	capitalizeFirstLetter,
	enumAsSelectOptions,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';
import { RelatorioNDCResponse } from '../../interfaces/relatorio-ndc';
import { corrigeDuplicacaoNome } from '../../utils';
import { Agrupamento } from '../../submodulos/monitoramento/interfaces/estacao-monitorada';

@Component({
	selector: 'seira-popup-ndc',
	templateUrl: './popup-ndc.component.html',
	styleUrls: ['./popup-ndc.component.scss'],
})
export class PopupNdcComponent implements OnInit {
	@Input() ndc?: RelatorioNDCResponse;
	@Input() headerColor = 'bg-primary';
	@Input() titleColor = 'white';
	@Input() agrupamento: string;
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
	nome: string;
	ngOnInit() {
		this.nome = corrigeDuplicacaoNome(this.nome);
	}

	get generoAgrupamento(): string {
		return ['MUNICIPIO_POSTO', 'MUNICIPIO'].includes(this.agrupamento)
			? 'o'
			: 'a';
	}

	get labelTipoAgrupamento(): string {
		return enumAsSelectOptions<string>(Agrupamento)
			.find(e => e.value === this.agrupamento)!
			.name!.toLowerCase();
	}
}
