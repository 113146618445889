import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'seira-select-list-menu',
	templateUrl: './select-list-menu.component.html',
	styleUrls: ['./select-list-menu.component.scss'],
})
export class SelectListMenuComponent {
	@Input() opcoes: { name: string; isFixed: boolean }[] = [];
	@Input() opcoesSelecionadas: string[] = [];
	@Input() disabled = false;
	@Input() tituloBotao = 'Personalizar';
	@Output() atualizarOpcoesSelecionadas = new EventEmitter<string[]>();

	toggleSelection(item: { name: string; isFixed: boolean }, event: Event) {
		event.stopPropagation();
		const index = this.opcoesSelecionadas.indexOf(item.name);
		if (index === -1) {
			this.opcoesSelecionadas.push(item.name);
		} else {
			this.opcoesSelecionadas.splice(index, 1);
		}
	}

	isSelected(item: { name: string; isFixed: boolean }): boolean {
		return this.opcoesSelecionadas.includes(item.name);
	}

	_atualizarOpcoesSelecionadas() {
		this.atualizarOpcoesSelecionadas.emit(this.opcoesSelecionadas);
	}
}
