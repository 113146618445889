<footer class="bg-transparent">
	<ng-container *seiraIfViewportSize="'desktop'">
		<div class="d-flex w-100 justify-content-center">
			<div
				class="d-flex justify-content-between align-items-center w-100 px-5"
				style="max-width: 900px">
				<div class="fw-normal fs-6" style="filter: opacity(0.65)">
					<i class="ph ph-copyright"></i>2024 SEIRA
				</div>
				<img
					*ngFor="let item of carouselItems"
					[src]="item.src"
					[alt]="item.alt" />
			</div>
		</div>
	</ng-container>

	<ng-container *seiraIfViewportSize="'mobile'">
		<div class="fw-semibold-1 fs-1 text-tertiary text-center">Realização</div>

		<div
			id="carrosselRealizacao"
			class="carousel slide vstack mb-3 bt-2"
			style="height: 82px"
			data-bs-ride="carousel"
			data-bs-touch="true">
			<div class="carousel-inner h-100">
				<div
					*ngFor="let item of carouselItems; index as index"
					[ngClass]="
						index === 0 ? 'carousel-item h-100 active' : 'carousel-item h-100'
					">
					<div
						class="d-flex justify-content-center w-100 h-100 align-items-center">
						<img [src]="item.src" class="carrossel-img" [alt]="item.alt" />
					</div>
				</div>
			</div>
			<button
				class="carousel-control-prev carrossel-acoes-button"
				type="button"
				data-bs-target="#carrosselRealizacao"
				data-bs-slide="prev">
				<i class="ph-caret-left carrossel-acoes fs-1" aria-hidden="true"></i>
				<span class="visually-hidden">Anterior</span>
			</button>
			<button
				class="carousel-control-next carrossel-acoes-button"
				type="button"
				data-bs-target="#carrosselRealizacao"
				data-bs-slide="next">
				<i class="ph-caret-right carrossel-acoes fs-1" aria-hidden="true"></i>
				<span class="visually-hidden">Próximo</span>
			</button>
		</div>

		<div class="bg-primary fs-6">
			<ng-container *seiraIfViewportSize="'mobile'">
				<div class="d-flex flex-column align-items-start gap-3 p-4">
					<div *ngFor="let info of sobreInfo" class="text-white">
						<span class="fw-normal">{{ info.title.toUpperCase() }}</span>
						<li class="ms-3" *ngFor="let link of info.links">
							<a class="text-white" [href]="link.path">{{ link.label }}</a>
						</li>
					</div>
				</div>
			</ng-container>
			<div class="container-lg">
				<hr class="hr m-0" style="color: rgba(255, 255, 255, 0.35)" />
			</div>
			<div class="text-white text-start p-4">
				<div class="fw-normal"><i class="ph-copyright"></i> Copyright</div>
				<div class="fw-normal">Todos os direitos reservados</div>
				<div>Termos de uso</div>
				<div>Política de privacidade</div>
			</div>
		</div>
	</ng-container>

	<ng-content></ng-content>
</footer>
