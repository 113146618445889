import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DadosRelatorioVariaveis } from '@home/submodulos/dados-meteorologicos/interfaces/response';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { formatISO } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import moment from 'moment';

import { Select } from '@layout/interfaces/select';
import { isNotNuloOuUndefined } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';

@Component({
	selector: 'seira-variaveis-meteorologicas-tabela',
	templateUrl: './variaveis-meteorologicas-tabela.component.html',
	styleUrls: ['./variaveis-meteorologicas-tabela.component.scss'],
})
export class VariaveisMeteorologicasTabelaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como variáveis meteorológicas os parâmetros atmosféricos, como temperatura, umidade, e velocidade do vento, que influenciam as condições climáticas de um local ou região.';

	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaVariaveis?: DadosRelatorioVariaveis[];
	carregandoRelatorio = false;
	historicolength = 0;
	todas_estacoes = false;
	private subscription = new Subscription();
	microrregioes: Select[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV(this.dadosTabelaVariaveis!);
			},
		},
	];
	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		setTimeout(() => {
			this.setValidators();
			this.submitFirstSearch();
		});
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
	}
	ngOnDestroy() {
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}
	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	gerarRelatorio(): void {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		const idPosto = this.getFormItemValue('estacao');

		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		if (this.getFormItemValue('estacao') === 0) {
			this.relatoriosService
				.buscarRelatorioVariaveisTodosMunicipios({
					tipo: TipoEstacao.PCD,
					diaInicio: formatISO(new Date(dataInicio)),
					diaFim: formatISO(new Date(dataFim)),
				})
				.subscribe({
					next: resp => {
						this.dadosTabelaVariaveis = resp;
						this.historicolength = resp.length;
						this.carregandoRelatorio = false;
						this.todas_estacoes = true;
						this.inputs.setLoading(false);
					},
					error: err => {
						this.carregandoRelatorio = false;
						this.todas_estacoes = false;
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
					},
				});
		} else {
			this.relatoriosService
				.buscarRelatorioVariaveis(
					idPosto,
					formatISO(new Date(dataInicio)),
					formatISO(new Date(dataFim))
				)
				.subscribe({
					next: variaveis => {
						this.dadosTabelaVariaveis = [variaveis];
						this.historicolength = variaveis.historico.length;
						this.carregandoRelatorio = false;
						this.inputs.setLoading(false);
						this.todas_estacoes = false;
					},
					error: err => {
						this.carregandoRelatorio = false;

						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.todas_estacoes = false;
					},
				});
		}
	}

	submitFirstSearch() {
		this.form.patchValue({
			estacao: this.estacoes.filter(e => e.tipoEstacao == 'PCD')[0].id,
			periodo: moment(),
		});
	}

	getPeriodo() {
		return this.form.get(FormularioRelatorio.DATA_FIM)?.value.year() || '';
	}

	exportarCSV(dadosTabelaVariaveisMeteorologicas: DadosRelatorioVariaveis[]) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Municipio/Posto',
			'Data' || '-',
			'Precipitação' || '-',
			'Temperatura' || '-',
			'Umidade' || '-',
			'Pressão' || '-',
			'Direção do vento 1m' || '-',
			'Velocidade do vento 1m' || '-',
			'Radiação' || '-',
			'Velocidade do vento 2m' || '-',
			'Direção do vento 2m' || '-',
			'Temperatura do solo' || '-',
			'Umidade do solo' || '-',
			'Evapotranspiração' || '-',
			'Corrente recarga' || '-',
			'Sensor porta aberta' || '-',
			'Umidade interna' || '-',
			'Temperatura interna' || '-',
			'Tensão da bateria' || '-',
			'Conteúdo água solo 10 cm' || '-',
			'Conteúdo água solo 20 cm' || '-',
			'Conteúdo água solo 30 cm' || '-',
			'Conteúdo água solo 40 cm' || '-',
			'Conteúdo água solo 50 cm' || '-',
			'Conteúdo água solo 60 cm' || '-',
			'Temperatura solo 10 cm' || '-',
			'Temperatura solo 20 cm' || '-',
			'Temperatura solo 30 cm' || '-',
			'Temperatura solo 40 cm' || '-',
			'Temperatura solo 50 cm' || '-',
			'Temperatura solo 60 cm' || '-',
			'Vento direção 1 desvio Padrão ' || '-',
			'Vento direção 2 desvio Padrão' || '-',
			'Vento velocidade 1 máxima' || '-',
			'Vento velocidade 2 máxima' || '-',
		]);
		dadosTabelaVariaveisMeteorologicas.forEach(hi => {
			hi.historico.forEach(dado => {
				tableData.push([
					(hi.municipio || '-') + ' / ' + (hi.nomeposto || '-'),
					DateTimeUtils.formatarData(
						dado.dataColeta.toString(),
						'dd/MM/yyyy HH:mm'
					) || '',
					isNotNuloOuUndefined(dado.precipitacao)
						? dado.precipitacao.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperatura)
						? dado.temperatura.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.umidade) ? dado.umidade.toFixed(2) : '-',
					isNotNuloOuUndefined(dado.pressao) ? dado.pressao.toFixed(2) : '-',
					isNotNuloOuUndefined(dado.ventoDirecao)
						? dado.ventoDirecao.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.ventoVelocidade)
						? dado.ventoVelocidade.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.radiacao) ? dado.radiacao.toFixed(2) : '-',
					isNotNuloOuUndefined(dado.vento2m) ? dado.vento2m.toFixed(2) : '-',
					isNotNuloOuUndefined(dado.ventoDirecao2)
						? dado.ventoDirecao2.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo)
						? dado.temperaturaSolo.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.umidadeSolo)
						? dado.umidadeSolo.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.evapotranspiracaoPotencial)
						? dado.evapotranspiracaoPotencial.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.correnteRecarga)
						? dado.correnteRecarga.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.sensorPortaAberta)
						? dado.sensorPortaAberta.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.umidadeInterna)
						? dado.umidadeInterna.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaInterna)
						? dado.temperaturaInterna.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.tensaoBateria) ? dado.tensaoBateria : '-',
					isNotNuloOuUndefined(dado.conteudoAguaSolo10cm)
						? dado.conteudoAguaSolo10cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.conteudoAguaSolo20cm)
						? dado.conteudoAguaSolo20cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.conteudoAguaSolo30cm)
						? dado.conteudoAguaSolo30cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.conteudoAguaSolo40cm)
						? dado.conteudoAguaSolo40cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.conteudoAguaSolo50cm)
						? dado.conteudoAguaSolo50cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.conteudoAguaSolo60cm)
						? dado.conteudoAguaSolo60cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo10cm)
						? dado.conteudoAguaSolo10cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo20cm)
						? dado.temperaturaSolo20cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo30cm)
						? dado.temperaturaSolo30cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo40cm)
						? dado.temperaturaSolo40cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo50cm)
						? dado.temperaturaSolo50cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.temperaturaSolo60cm)
						? dado.temperaturaSolo60cm.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.ventoDirecao1DesvioPadrao)
						? dado.ventoDirecao1DesvioPadrao.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.ventoDirecao2DesvioPadrao)
						? dado.ventoDirecao2DesvioPadrao.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.ventoVelocidade1Maxima)
						? dado.ventoVelocidade1Maxima.toFixed(2)
						: '-',
					isNotNuloOuUndefined(dado.ventoVelocidade2Maxima)
						? dado.ventoVelocidade2Maxima.toFixed(2)
						: '-',
				]);
			});
		});
		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-variaveis-meteorologicas-${this.getPeriodo()}`
		);
	}
}
