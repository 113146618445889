import { getLimites } from '@home/submodulos/dados-hidrologicos/pages/reservatorios/limitesClassificacoesReservatorios';

export enum CoresPanorama {
	SITUACAO_CRITICA = '#D3472C',
	ATENCAO = '#FF9D0B',
	EM_OBSERVACAO = '#e7e719',
	EM_NORMALIDADE = '#20A924',
	VERTENDO = '#0992F8',
	FAVORAVEL = '#5DDCF5',
}
export enum CoresPanoramaOpacas {
	SITUACAO_CRITICA = '#D3472C33',
	ATENCAO = '#FF9D0B33',
	EM_OBSERVACAO = '#FFFF0020',
	EM_NORMALIDADE = '#20A92433',
	VERTENDO = '#7bc3f7',
	FAVORAVEL = '#d4ecff',
}

type FuntionCor = (cor: CoresPanorama | CoresPanoramaOpacas) => void;

export const vertendo = (value: number) => value > getLimites().vertendo;
export const favoravel = (value: number) =>
	value > getLimites().favoravel && value <= getLimites().vertendo;
export const normalidade = (value: number) =>
	value > getLimites().normalidade && value <= getLimites().favoravel;
export const observacao = (value: number) =>
	value > getLimites().observacao && value <= getLimites().normalidade;
export const atencao = (value: number) =>
	value > getLimites().atencao && value <= getLimites().observacao;
export const critica = (value: number) => value <= getLimites().atencao;
const funcaoPadrao = () => {};
export const corPorVolume = (
	volume: number,
	tipoCor: 'opaco' | 'solido' = 'solido',
	customFunction: FuntionCor[] = [
		funcaoPadrao,
		funcaoPadrao,
		funcaoPadrao,
		funcaoPadrao,
		funcaoPadrao,
		funcaoPadrao,
	]
) => {
	const panorama = tipoCor === 'opaco' ? CoresPanoramaOpacas : CoresPanorama;
	if (vertendo(volume)) {
		customFunction[0](panorama.VERTENDO);
		return panorama.VERTENDO;
	} else if (normalidade(volume)) {
		customFunction[1](panorama.EM_NORMALIDADE);
		return panorama.EM_NORMALIDADE;
	} else if (favoravel(volume)) {
		customFunction[2](panorama.FAVORAVEL);
		return panorama.FAVORAVEL;
	} else if (observacao(volume)) {
		customFunction[3](panorama.EM_OBSERVACAO);
		return panorama.EM_OBSERVACAO;
	} else if (atencao(volume)) {
		customFunction[4](panorama.ATENCAO);
		return panorama.ATENCAO;
	} else {
		customFunction[5](panorama.SITUACAO_CRITICA);
		return panorama.SITUACAO_CRITICA;
	}
};
