<label class="text-center text-tertiary fw-semibold w-100">
	Climatologia - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />

<div
	*ngIf="loading"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<div *ngIf="gerou && !loading && resultado">
	<div class="mt-3" id="grafico-climatologia">
		<seira-highchart
			classChart="highcharts-light h-auto"
			styleChart="width: 100%; height: 600px; display: block"
			(chartInstance)="onChartCreated($event)"
			[options]="chartOptions" />
	</div>
</div>
<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>
