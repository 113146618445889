<div>
	<form [formGroup]="formEstacao" class="form-group">
		<div class="d-flex">
			<div class="d-flex row col-12 align-items-end p-0 m-0 mt-3">
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Informe o nome aqui"
						label="Município/Posto"
						optionLabel="nome"
						optionValue="id"
						[options]="estacoes"
						notFoundText="Nenhuma estação encontrada"
						formControlName="nomePosto">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6 mt-1"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						optionLabel="label"
						optionValue="value"
						placeholder="Selecione..."
						label="Tipo de estação"
						[options]="tiposEstacao"
						notFoundText="Nenhum tipo de estação encontrado"
						formControlName="tipoEstacao">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6 mt-1"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Selecione..."
						label="Mesorregião"
						[options]="mesorregioes"
						optionLabel="nome"
						optionValue="id"
						notFoundText="Nenhuma mesorregião encontrada"
						formControlName="mesorregiao">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6 mt-1"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Selecione..."
						label="Município"
						[options]="municipios"
						optionLabel="nome"
						optionValue="id"
						notFoundText="Nenhum município encontrado"
						formControlName="municipio">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Selecione..."
						label="Região Pluviométrica"
						[options]="regioes"
						optionLabel="nome"
						optionValue="id"
						notFoundText="Nenhuma região encontrada"
						formControlName="regiao">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Selecione..."
						label="Bacia/Sub-bacia"
						[options]="bacias"
						optionLabel="nome"
						optionValue="id"
						notFoundText="Nenhuma bacia encontrada"
						formControlName="bacia">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Selecione..."
						label="Microrregião"
						[options]="microrregioes"
						optionLabel="nome"
						optionValue="id"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao">
					</seira-public-select>
				</div>

				<div
					class="col-lg-3 col-md-4 mt-2 col-sm-6 mt-1"
					*ngIf="(isDesktop && !homePage) || mostrarCampos">
					<seira-public-select
						placeholder="Selecione..."
						label="Status do posto"
						optionLabel="label"
						optionValue="value"
						[options]="status"
						notFoundText="Nenhum Status encontrado"
						formControlName="statusEstacao">
					</seira-public-select>
				</div>

				<div
					class="d-flex align-items-center flex-grow-1 justify-content-between">
					<div class="d-flex align-items-center">
						<div>
							<seira-public-radio-group
								idRadio="modoVisualizacaoEstacoes"
								[options]="OpcoesTipoVisualizacao"
								label="Modo de visualização"
								controlName="tipoVisualizacao"></seira-public-radio-group>
						</div>
					</div>
					<div class="d-flex align-items-center">
						<div *ngIf="!isDesktop || homePage">
							<div
								class="mb-1 me-1 align-items-center d-flex justify-content-end"
								*ngIf="filtroModalAtivo; else exibeFiltro">
								<ng-container *ngIf="filtroModalAtivo.trim().length > 0">
									<span class="me-1 fw-semibold">Filtrando por:</span>
									{{ filtroModalAtivo }}
								</ng-container>
								<div>
									<seira-public-a
										href
										(click)="
											homePage
												? abrirModalFiltros($event)
												: mostrarCamposAdicionais()
										"
										>Editar...</seira-public-a
									>
								</div>
							</div>

							<ng-template #exibeFiltro>
								<seira-public-a
									class="d-flex justify-content-end mt-1"
									href
									(click)="
										homePage
											? abrirModalFiltros($event)
											: mostrarCamposAdicionais()
									">
									{{
										!mostrarCampos || homePage
											? 'Adicionar filtros...'
											: 'Exibir menos filtros...'
									}}</seira-public-a
								>
							</ng-template>
						</div>

						<div class="d-flex justify-content-end">
							<seira-public-button
								[loading]="loadingFiltrando"
								(clicked)="setEstacoesMonitoradas(false)"
								>Filtrar</seira-public-button
							>
							<seira-public-a
								class="mt-1"
								(click)="limparFiltros()"
								[disabled]="!possuiFiltrosAtivos"
								>Limpar</seira-public-a
							>
						</div>
						<div class="d-flex flex-colum justify-content-end">
							<seira-public-button-group
								[disabledButtons]="loadingFiltrando"
								[buttons]="botoesDeExportacao"></seira-public-button-group>
						</div>
					</div>
				</div>
				<div
					class="row mt-4 p-0 mb-0 ms-0 me-0"
					[class.hidden]="getFormItemValue('tipoVisualizacao') !== 'mapa'">
					<div class="col-lg-3" *ngIf="!homePage">
						<div *seiraIfViewportSize="'desktop'">
							<seira-box-select-estacoes
								[loading]="loadingFiltrando"
								[estacoesMonitoradas]="
									estacoesMonitoradasBox
								"></seira-box-select-estacoes>
						</div>
					</div>
					<div [class]="class">
						<div class="col-12 vh-100 pe-lg-0 pe-1" style="max-height: 600px">
							<seira-mapa-paraiba-leaflet
								(mapaEmitter)="setupMap($event)"
								[loading]="loadingFiltrando"
								[monitoramentoMapa]="map"
								[markersLayer]="markersLayer" />
						</div>
						<div
							class="col-12 mt-3 font-size-16"
							*seiraIfViewportSize="'desktop'">
							<seira-legenda-estacoes />
						</div>
					</div>
				</div>
				<div [class.hidden]="getFormItemValue('tipoVisualizacao') !== 'tabela'">
					<div
						class="row mt-4 mb-3"
						id="tabelaPostos"
						style="position: relative">
						<seira-public-table
							*ngIf="tableColumns.length"
							#tabelaMonitoramento
							id="tabelaDados"
							[searching]="false"
							[info]="true"
							[paging]="true"
							[hasFooter]="true"
							[hasHeader]="false"
							[order]="[2, 'desc']"
							[data]="markersEstacoes"
							[columns]="tableColumns">
						</seira-public-table>
						<seira-loading
							[show]="loadingFiltrando"
							customStyle="
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							"></seira-loading>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
