import { Component, Input } from '@angular/core';
import { Relatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	capitalizeFirstLetter,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';

interface Meses {
	jan?: number | null;
	fev?: number | null;
	mar?: number | null;
	abr?: number | null;
	mai?: number | null;
	jun?: number | null;
	jul?: number | null;
	ago?: number | null;
	set?: number | null;
	out?: number | null;
	nov?: number | null;
	dez?: number | null;
}

export interface DadoPrecipitacaoAnual extends Meses {
	ano: number;
	total: number;
}

export interface DadoEstatisticaAnual extends Meses {
	estatistica: string;
}

@Component({
	selector: 'seira-tabela-relatorio-precipitacao-anual',
	templateUrl: './tabela-relatorio-precipitacao-anual.component.html',
	styleUrls: ['./tabela-relatorio-precipitacao-anual.component.scss'],
})
export class TabelaRelatorioPrecipitacaoAnualComponent {
	@Input() precipitacaoAnual: Relatorios<DadoPrecipitacaoAnual>[] = [];
	@Input() estatisticas: Relatorios<DadoEstatisticaAnual>[] = [];

	colunasPrecipitacaoAnual: ADTColumns[] = [];
	colunasEstatisticas: ADTColumns[] = [];

	constructor() {
		this.definirColunasTabelaPrecipitacaoAnual();
		this.definirColunasTabelaEstatisticas();
	}

	definirColunasTabelaPrecipitacaoAnual() {
		const colunas = [
			'jan',
			'fev',
			'mar',
			'abr',
			'mai',
			'jun',
			'jul',
			'ago',
			'set',
			'out',
			'nov',
			'dez',
			'total',
		];
		this.colunasPrecipitacaoAnual.push({
			data: 'ano',
			title: 'Ano',
			type: 'number',
			className: 'text-center',
		});
		colunas.forEach(coluna => {
			this.colunasPrecipitacaoAnual.push({
				data: coluna,
				title: capitalizeFirstLetter(coluna),
				type: 'number',
				className: 'text-center',
				render: valor => this.formatarNumero(valor),
			});
		});
	}

	definirColunasTabelaEstatisticas() {
		const colunas = [
			'jan',
			'fev',
			'mar',
			'abr',
			'mai',
			'jun',
			'jul',
			'ago',
			'set',
			'out',
			'nov',
			'dez',
		];
		this.colunasEstatisticas.push({
			data: 'estatistica',
			title: 'Estatística',
			type: 'string',
			className: 'text-center',
		});
		colunas.forEach(coluna => {
			this.colunasEstatisticas.push({
				data: coluna,
				title: capitalizeFirstLetter(coluna),
				type: 'number',
				className: 'text-center',
				render: valor => this.formatarNumero(valor),
			});
		});
		this.colunasEstatisticas.push({
			data: null,
			title: '',
			type: 'string',
			render: () => '<div style="padding-left: 2.7rem"></div>',
		});
	}

	formatarNumero(numero: number | null) {
		return isNotNuloOuUndefined(numero) ? numberToBrNumber(numero, 1) : '-';
	}
}
