import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { DadosPluviometriaRelatorioSudene } from '@home/submodulos/dados-meteorologicos/interfaces/response';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../services/relatorios.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DateTimeUtils } from '@utils/datetime-util';

export enum TabelaRelatorioSudenePeriodos {
	DIARIO = 31,
	MENSAL = 12,
	ANUAL = 10,
}

type Localizacao = {
	latitude: number;
	longitude: number;
	altitude: number;
};

type RelatorioSudenePosto = {
	posto: string;
	dados: Omit<DadosPluviometriaRelatorioSudene, 'posto'>[];
	total: () => number;
	localizacao: Localizacao;
};

@Component({
	selector: 'seira-tabela-relatorio-variaveis-meteorologicas',
	templateUrl: './tabela-relatorio-sudene.component.html',
	styleUrls: ['./tabela-relatorio-sudene.component.scss'],
})
export class TabelaRelatorioSudeneComponent
	implements InstanciaRelatorio, OnInit, AfterViewChecked, OnDestroy
{
	inputs = inject(INPUTS_RELATORIOS);
	@Input() dadosSudene?: DadosPluviometriaRelatorioSudene[];
	@Input() dadosSudeneSeparadosPorPosto?: RelatorioSudenePosto[];
	@ViewChild('tabelaRelatorioSudene', { static: false })
	tabela?: PublicTableComponent;
	taCarregando = true;
	private subscription = new Subscription();
	dataInicioRelatorioGerado?: Date;
	dataFimRelatorioGerado?: Date;
	stringData: string = this.getStringData();
	tipoPeriodoRelatorioGerado?: string;

	readonly colunas: ADTColumns[] = [
		{
			data: 'data',
			title: 'Data',
			type: 'date',
			className: 'text-center',
			render(data, tipo) {
				const [somenteData] = data.split('T');
				const displayData = somenteData.split('-').reverse().join('/');
				return tipo === 'display' || tipo === 'filter' ? displayData : data;
			},
		},
		{
			data: 'valor',
			title: 'Precipitação (mm)',
			type: 'num-fmt',
			className: 'text-center',
			render: texto => (texto as number).toFixed(1).replaceAll('.', ','),
		},
	];

	descricaoRelatorio =
		'O histórico da SUDENE refere-se ao conjunto de dados pluviométricos diários e mensais pertencentes a todo o banco de dados da SUDENE para o estado da Paraíba.';

	constructor(
		private readonly relatorioService: RelatoriosService,
		private readonly toastr: ToastrService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}

	set dataInicio(dataInicio: Date) {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.setValue(dataInicio);
	}

	set dataFim(dataFim: Date) {
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.setValue(dataFim);
	}

	get dataFim(): Date {
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		return dataFim as Date;
	}

	get dataInicio(): Date {
		const data = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		return data as Date;
	}

	ngOnInit(): void {
		this.relatorioService.buscarUltimaDataSudene().subscribe({
			next: ultimaDataSudene => {
				if (!ultimaDataSudene) return;
				this.dataInicio = DateTimeUtils.subtrairMes(ultimaDataSudene, 1);
				this.dataFim = ultimaDataSudene;
			},
		});
		this.setValidators();
	}

	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	private get tipoPeriodo(): string {
		return this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
	}

	getStringData(): string {
		return DateTimeUtils.formatarDataPeriodo(
			this.dataInicio,
			this.dataFim,
			this.tipoPeriodo
		);
	}

	setValidators(): void {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.POSTO_SUDENE)
			?.setValidators(Validators.required);
	}

	private limparValidador(campoForm: FormularioRelatorio): void {
		this.inputs.form.get(campoForm)?.clearValidators();
		this.inputs.form.get(campoForm)?.setErrors(null);
	}

	ngOnDestroy(): void {
		this.limparValidador(FormularioRelatorio.DATA_INICIO);
		this.limparValidador(FormularioRelatorio.DATA_FIM);
		this.limparValidador(FormularioRelatorio.PERIODO_BUSCA);
		this.limparValidador(FormularioRelatorio.POSTO_SUDENE);
		this.subscription.unsubscribe();
	}

	gerarRelatorio(): void {
		this.inputs.setLoading(true);
		this.taCarregando = true;
		this.tipoPeriodoRelatorioGerado = this.tipoPeriodo;
		this.dataInicioRelatorioGerado = this.dataInicio;
		this.dataFimRelatorioGerado = this.dataFim;
		this.relatorioService
			.buscarRelatorioSudene(
				this.inputs.form.get(FormularioRelatorio.POSTO_SUDENE)?.value,
				this.dataInicio,
				this.dataFim
			)
			.subscribe({
				next: resp => {
					this.dadosSudene = (resp as DadosPluviometriaRelatorioSudene[]).map(
						pluv => ({
							...pluv,
							data: pluv.data,
						})
					);
					const postos = new Set(this.dadosSudene.map(ds => ds.posto));
					const result: RelatorioSudenePosto[] = [];
					postos.forEach(e => {
						const dados = this.dadosSudene?.filter(ds => ds.posto === e)!;
						result.push({
							posto: e,
							dados: dados,
							total: () => dados.reduce((sum, item) => sum + item.valor, 0),
							localizacao: {
								latitude: dados[0].latitude,
								longitude: dados[0].longitude,
								altitude: dados[0].altitude,
							},
						});
					});
					this.dadosSudeneSeparadosPorPosto = result;
					this.dadosSudeneSeparadosPorPosto.sort((a, b) => {
						a.dados.sort((a, b) =>
							DateTimeUtils.ehAntes(new Date(b.data), new Date(a.data)) ? 1 : -1
						);
						return a.posto > b.posto ? 1 : -1;
					});
					this.stringData = this.getStringData();
					this.inputs.setLoading(false);
					this.taCarregando = false;
				},
				error: error => {
					this.toastr.error('Ocorreu um erro ao gerar o relatório', error);
					this.inputs.setLoading(false);
					this.taCarregando = false;
				},
			});
	}
}
