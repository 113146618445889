import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	NG_VALUE_ACCESSOR,
	Validators,
} from '@angular/forms';

const MULTI_SELECT: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MultiselectComponent),
	multi: true,
};

@Component({
	selector: 'seira-multiselect',
	templateUrl: './multiselect.component.html',
	styleUrls: ['./multiselect.component.scss'],
	providers: [MULTI_SELECT],
})
export class MultiselectComponent<K> implements OnInit {
	@Input() options: K[] = [];
	@Input() isReadOnly = false;
	@Input() id!: string;
	@Input() label!: string;
	@Input() required = false;
	@Input() placeholder!: string;
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() controlName!: string;
	@Input() optionsValue = '';
	@Input() optionsLabel = 'nome';
	control = new FormControl();
	resultadoPesquisa: K[] | null = null;

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}
	pesquisa(e: { term: string; items: K[] }) {
		this.resultadoPesquisa = e.items;
	}
	selectAll() {
		if (this.resultadoPesquisa) {
			this.control.setValue(this.resultadoPesquisa);
		} else {
			this.control.setValue(this.options);
		}
	}

	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}
	get seiraError() {
		if (this.control.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				if (erros[0] === 'required') {
					if (this.control.touched) return 'Campo obrigatório.';
				} else {
					return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
