import { HttpClient } from '@angular/common/http';
import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	ChuvaEstado,
	DadosTabela,
} from '@home/submodulos/dados-meteorologicos/interfaces/chuva-estado';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { MapaPluviometriaInterpoladaService } from '@home/submodulos/dados-meteorologicos/services/mapa-pluviometria-interpolada.service';
import { Select } from '@layout/interfaces/select';
import { GeoJSON } from '@shared/interfaces/geometry';
import { capitalizeFirstLetter, numberToBrNumber, verificaSePDF } from '@utils';
import { DocumentExporter } from '@utils/document-exporter';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	ValorPorMesorregiao,
} from '@utils/interpolacao/interpolacao';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import { FeatureCollection, Polygon } from 'geojson';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { RelatoriosService } from '../../../services';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';
import Position = GeoJSON.Position;

@Component({
	selector: 'seira-mapa-pluviometria-interpolada',
	templateUrl: './mapa-pluviometria-interpolada.component.html',
	styleUrls: ['./mapa-pluviometria-interpolada.component.scss'],
})
export class MapaPluviometriaInterpoladaComponent implements OnInit, OnDestroy {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	interpolacao?: InterpolacaoCanvasBounds;
	carregando = true;
	valores: ChuvaEstado[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	medicoes: ChuvaEstado[] = [];
	dados: DadosTabela[] = [];
	loading = false;
	regiao?: Position[][];
	descricaoRelatorio =
		'Define-se como pluviometria interpolada a quantidade total de chuva registrada em um local ou região específica, interpolada ao longo de um período.';
	periodoTitulo = '';
	relatorioService = inject(RelatoriosService);

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV();
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT();
			},
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	constructor(
		private MapaPluviometriaInterpoladaService: MapaPluviometriaInterpoladaService,
		private httpClient: HttpClient,
		private fb: FormBuilder
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.initialValues();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	calcularMedia(valores: number[]): number {
		const soma = valores.reduce((total, valor) => total + valor, 0);
		return soma / valores.length;
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		const dataInicio: Date | null = this.inputs.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Date | null = this.inputs.form.get(
			FormularioRelatorio.DATA_FIM
		)?.value;

		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			'mensal'
		);

		this.MapaPluviometriaInterpoladaService.buscarMapaChuvaEstado(
			formatISO(new Date(dataInicio)),
			formatISO(new Date(dataFim))
		).subscribe({
			next: (resp: ChuvaEstado[]) => {
				this.relatorioService.verificaExistenciaDados(resp);

				if (this.dados.length === 0) {
					const valoresAgrupados: { [mesorregiao: string]: ChuvaEstado[] } = {};
					resp.forEach(valor => {
						if (!valoresAgrupados[valor.mesorregiao]) {
							valoresAgrupados[valor.mesorregiao] = [];
						}
						valoresAgrupados[valor.mesorregiao].push(valor);
					});

					for (const mesorregiao in valoresAgrupados) {
						if (mesorregiao in valoresAgrupados) {
							const valores = valoresAgrupados[mesorregiao];
							const mediaAcumulado = this.calcularMedia(
								valores.map(v => v.acumulado)
							);
							const mediaMedia = this.calcularMedia(valores.map(v => v.media));
							const mediaDesvio =
								((mediaAcumulado - mediaMedia) / mediaMedia) * 100;

							this.dados.push({
								mesorregiao: mesorregiao,
								acumulado: mediaAcumulado,
								media: mediaMedia,
								desvio: mediaDesvio,
							});
						}
					}
				}
				this.medicoes = resp;

				this.interpolarValores();
				this.inputs.setLoading(false);
			},
			error: erro => {
				this.inputs.setLoading(false);
				this.carregando = false;
				console.error('erro', erro);
			},
		});
	}

	interpolarValores() {
		this.carregando = true;
		if (this.medicoes.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}

	criarImagemInterpolacao() {
		const diaInicio = this.inputs.form.get('dataInicio')?.value;
		const diaFim = this.inputs.form.get('dataFim')?.value;
		let variavel: VariavelMeteorologica;

		const diffMeses = DateTimeUtils.getDiferencaMesesUTC(
			new Date(diaFim),
			new Date(diaInicio)
		);

		if (diffMeses >= 2) {
			variavel = 'precipitacao2MesesAAnual';
		} else {
			variavel = 'precipitacao1Mes';
		}

		const valores: ValorPorMesorregiao[] = this.medicoes.map(value => {
			return {
				lat: value.latitude,
				lng: value.longitude,
				value: value.acumulado,
				mesorregiao: value.mesorregiao,
				nomePosto: value.nomePosto,
				isPrecipitacao: true,
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				variavel,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	getDadosTabelaParaExportacao(dados: ChuvaEstado[], isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas = [
			{ text: 'Nome do posto', fillColor: '#DCDCDC' },
			{ text: 'Chuva observada (mm)', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: ChuvaEstado) => {
			const rowData = [
				capitalizeFirstLetter(item.nomePosto.toLowerCase()),
				numberToBrNumber(item.acumulado, 1),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	retornarPluviometriaInterpoladaParaXLSX(dados: ChuvaEstado[]) {
		const tableData: any[] = [];

		dados.forEach(dado => {
			tableData.push({
				nomePosto: dado.nomePosto,
				acumulado: numberToBrNumber(dado.acumulado, 1),
			});
		});

		return tableData;
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Pluviometria interpolada - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 500,
				height: 400,
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, true);

		documentDefinition.content.push({
			table: {
				widths: ['50%', '50%'],
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},

			marginLeft: 15,
			marginRight: 15,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Pluviometria interpolada - ${this.periodoTitulo}`
		);
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);
		DocumentExporter.gerarCSV(
			tableData,
			`Pluviometria interpolada - ${this.periodoTitulo}`
		);
	}

	async exportarXLSX() {
		const nomeArquivo = `Pluviometria interpolada - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{ label: 'nomePosto', text: 'Nome do posto', size: 24 },
			{ label: 'acumulado', text: 'Chuva observada (mm)', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPluviometriaInterpoladaParaXLSX(this.medicoes),
					columns: [
						{ key: 'nomePosto' },
						{
							key: 'latitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'longitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'acumulado',
							style: { numFmt: '0.0' },
						},
						{
							key: 'desvio',
							style: { numFmt: '0' },
						},
						{
							key: 'estacaoId',
							style: { numFmt: '0' },
						},
						{
							key: 'media',
							style: { numFmt: '0' },
						},
						{
							key: 'mesorregião',
							style: { numFmt: '0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
