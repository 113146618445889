import { PublicSelectComponent } from '@componentes/public-select/public-select.component';
import {
	Component,
	forwardRef,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MeteogramaService } from '@home/submodulos/previsao-tempo/services/meteograma.service';
import { MunicipioMeteograma } from '@home/submodulos/previsao-tempo/interfaces/meteograma';
import { UsuarioService } from '@services/usuario/usuario.service';

@Component({
	selector: 'seira-public-select-municipio-execucao-meteograma',
	templateUrl: './public-select.component.html',
	styleUrls: ['./public-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(
				() => PublicSelectMunicipioExecucaoMeteogramaComponent
			),
		},
	],
})
export class PublicSelectMunicipioExecucaoMeteogramaComponent
	extends PublicSelectComponent<MunicipioMeteograma, number>
	implements OnChanges
{
	override optionValue = 'id';
	override optionLabel = 'municipio';
	override carregando = true;
	override carregandoLabel = 'Carregando os municípios';
	override label = 'Município';
	override placeholder = 'Informe o município';

	@Input()
	execucao?: number;

	@Input() municipioGeocodigo?: number;

	constructor(
		private meteogramaService: MeteogramaService,
		private usuarioService: UsuarioService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['execucao']) {
			const execucao = changes['execucao'].currentValue;

			if (execucao !== null && execucao !== undefined) {
				this.buscarOptions(changes['execucao'].currentValue);
			}
		}
	}

	buscarOptions(idExecucao: number): void {
		this.carregando = true;
		this.carregandoChange.emit(this.carregando);
		this.meteogramaService.listMunicipios(idExecucao).subscribe(municipios => {
			const oldOptions = this.options;
			this.options = municipios;

			if (this.value === undefined || this.value === null) {
				if (this.municipioGeocodigo) {
					const municipio = municipios.find(
						municipio => municipio.geocodigo === this.municipioGeocodigo
					);
					if (municipio) {
						this.value = municipio.id;
						this.onValueChange(this.value);
					}

					this.desativarCarregamento();
				} else {
					this.definirMunicipioBaseadoNaLocalizacao();
				}
			} else {
				this.definirMunicipio(oldOptions, this.options);
			}
		});
	}

	private definirMunicipio(
		oldOptions: MunicipioMeteograma[],
		currentOptions: MunicipioMeteograma[]
	) {
		const currentOption = oldOptions.find(option => option.id === this.value);

		if (currentOption !== undefined) {
			const foundMunicipio = currentOptions.find(
				({ municipio }) => currentOption.municipio === municipio
			);

			if (foundMunicipio !== undefined) {
				this.value = foundMunicipio.id;
				this.onValueChange(this.value);
			} else {
				this.value = this.options[0].id;
				this.onValueChange(this.value);
			}
		}

		this.desativarCarregamento();
	}

	private definirMunicipioBaseadoNaLocalizacao() {
		this.usuarioService
			.getUserLocationInfo()
			.then(location => {
				const municipioDoUsuario = location.municipio;
				const municipio = this.options.find(
					option => option.municipio === municipioDoUsuario?.nome
				);

				if (!municipio) throw new Error('Município não encontrado');

				this.value = municipio.id;
				this.onValueChange(this.value);

				this.desativarCarregamento();
			})
			.catch(() => {
				const capital = this.options.find(
					option => option.capital || option.municipio === 'João Pessoa'
				);
				this.value = capital ? capital.id : this.options[0]?.id;
				this.onValueChange(this.value);

				this.desativarCarregamento();
			});
	}

	private desativarCarregamento() {
		this.carregando = false;
		this.carregandoChange.emit(this.carregando);
	}
}
