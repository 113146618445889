import { HttpClient } from '@angular/common/http';
import {
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	createComponent,
	EnvironmentInjector,
	inject,
	Input,
	OnChanges,
	OnDestroy,
} from '@angular/core';
import { CORES, legendaNDCInfo } from '@componentes/mapa-paraiba-svg/legenda';
import Leaflet from 'leaflet';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { PopupNdcComponent } from '../../popup-ndc/popup-ndc.component';
import { ColorUtils } from '@utils/color-utils';
import {
	AgrupamentoGeojson,
	agrupamentoGeojsonMap,
} from '../../../enum/Agrupamento';
import { FormularioRelatorio, INPUTS_RELATORIOS } from '../../../interfaces';
import { GeoJSONUtils } from '../../../utils/geojson-utils';

@Component({
	selector: 'seira-mapa-ndc-chuva',
	templateUrl: './mapa-ndc.component.html',
	styleUrls: ['./mapa-ndc.component.scss'],
})
export class MapaNdcComponent implements OnDestroy, OnChanges {
	@Input() dados?: RelatorioNDCResponse[];
	map?: Leaflet.Map;
	geoJsonLayer?: Leaflet.GeoJSON;
	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;
	legenda = legendaNDCInfo;
	private inputs = inject(INPUTS_RELATORIOS);
	@Input() isLoading = false;

	constructor(
		private httpClient: HttpClient,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private envInjector: EnvironmentInjector
	) {}

	ngOnDestroy(): void {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}
		this.map = undefined;
	}

	ngOnChanges(): void {
		this.cdr.detectChanges();
		if (!this.isLoading) {
			this.limparMapa();
			setTimeout(() => {
				this.loadMapLayers(this.map!, false);
			}, 500);
		}
	}

	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
	}

	get agrupamento(): string {
		return this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
	}

	get agrupamentoGeoJSON(): AgrupamentoGeojson {
		return (
			agrupamentoGeojsonMap[this.agrupamento] ?? AgrupamentoGeojson.MUNICIPIO
		);
	}

	private limparMapa(): void {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}
	}

	loadMapLayers(map: Leaflet.Map, deveLimparMapa = true) {
		if (deveLimparMapa) {
			this.limparMapa();
		}

		this.httpClient
			.get<GeoJSON.GeoJsonObject>(this.agrupamentoGeoJSON)
			.subscribe({
				next: value => {
					this.geoJsonLayer = Leaflet.geoJSON(value, {
						style: feature => this.handleGetStyleByFeature(feature),
						onEachFeature: (feature, layer) => {
							layer.on({
								click: e => {
									const chaveProperty =
										GeoJSONUtils.getChavePropriedadeFeatureGeoJSON(
											this.agrupamento
										);
									const ndc = this.handleFindNDCByPropertyName(
										feature!.properties![chaveProperty]
									);
									const markerPopup = this.handleAttachPopup(
										ndc
											? this.handleCreatePopupByNDC(
													ndc,
													feature.properties[chaveProperty]
											  )
											: this.handleCreatePopup(
													feature.properties[chaveProperty]
											  )
									);
									layer.bindPopup(markerPopup).openPopup(e.latlng);
								},
							});
						},
					}).addTo(map);
				},
			});
	}

	private handleCreatePopup(titulo: string): ComponentRef<PopupNdcComponent> {
		const componentRef = createComponent(PopupNdcComponent, {
			environmentInjector: this.envInjector,
		});
		const color = CORES.CINZA;
		componentRef.instance.nome = titulo;
		componentRef.instance.headerColor = color;
		componentRef.instance.agrupamento = this.agrupamento;
		componentRef.instance.titleColor = ColorUtils.ehCorClara(color)
			? 'gray'
			: CORES.BRANCO_NEVE;
		return componentRef;
	}

	private handleCreatePopupByNDC(
		ndc: RelatorioNDCResponse,
		titulo: string
	): ComponentRef<PopupNdcComponent> {
		const componentRef = createComponent(PopupNdcComponent, {
			environmentInjector: this.envInjector,
		});
		componentRef.instance.ndc = ndc;
		componentRef.instance.nome = titulo;
		componentRef.instance.agrupamento = this.agrupamento;
		const color = this.getColorByDiasChuva(ndc.diasComChuva);
		componentRef.instance.headerColor = color;

		componentRef.instance.titleColor = ColorUtils.ehCorClara(color)
			? 'gray'
			: CORES.BRANCO_NEVE;
		return componentRef;
	}

	private handleAttachPopup(compRef: ComponentRef<PopupNdcComponent>) {
		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}

	private handleGetStyleByFeature(feature?: GeoJSON.Feature<GeoJSON.Geometry>) {
		const ndc = this.handleFindNDCByPropertyName(
			feature!.properties![
				GeoJSONUtils.getChavePropriedadeFeatureGeoJSON(this.agrupamento)
			]
		);
		return {
			fillColor: ndc ? this.getColorByDiasChuva(ndc.diasComChuva) : CORES.CINZA,
			weight: 2,
			opacity: 1,
			color: '#607d8b',
			fillOpacity: 0.7,
		};
	}

	private get chavePropriedadeFeature(): keyof RelatorioNDCResponse {
		if (this.agrupamento === 'MUNICIPIO_POSTO') {
			return 'municipio';
		}
		return 'nome';
	}

	private handleFindNDCByPropertyName(name: string) {
		return this.dados?.find(
			dado => dado[this.chavePropriedadeFeature] === name
		);
	}

	private getColorByDiasChuva(valor: number): string {
		const arr = Object.keys(legendaNDCInfo)
			.map(Number)
			.sort((a, b) => b - a);

		for (const key of arr) {
			if (valor >= key) {
				return legendaNDCInfo[key];
			}
		}
		return CORES.CINZA;
	}
}
