import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
	@Input() label!: string;
	@Input() idElemento!: string;
	@Input() icon?: string;
	@Input() ariaExpanded = true;
	@Input() ocultarAfter = false;

	toggleAriaExpanded() {
		this.ariaExpanded = !this.ariaExpanded;
	}
}
