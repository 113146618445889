import { Pipe, PipeTransform } from '@angular/core';
import { numberToBrNumber } from '@utils';

@Pipe({ name: 'formatDecimal' })
export class FormatDecimalPipe implements PipeTransform {
	transform(value?: number | string | null, decimalPlaces?: number) {
		return numberToBrNumber(value, decimalPlaces);
	}
}

