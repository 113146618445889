import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EstacoesComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes/estacoes.component';
import { RedeMonitoramentoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/rede-monitoramento/rede-monitoramento.component';
import { EstacoesAutomaticasComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes-automaticas/estacoes-automaticas.component';
import { QuantisComponent } from './pages/quantis/quantis.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/dados-meteorologicos/monitoramento/pluviometria',
		pathMatch: 'full',
	},
	{
		path: '',
		title: 'Monitoramento - SEIRA',
		children: [
			{ path: 'rede-monitoramento', component: RedeMonitoramentoComponent },
			{ path: 'pluviometria', component: EstacoesComponent },
			{ path: 'quantis', component: QuantisComponent },
			{ path: 'estacoes-automaticas', component: EstacoesAutomaticasComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MonitoramentoRoutingModule {}
