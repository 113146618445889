<div class="d-flex flex-column">
	<label class="text-center text-tertiary fw-semibold w-100">
		{{ tituloTabela }} -
		{{ periodo }}
	</label>

	<div style="max-width: 1300px">
		<div
			class="d-flex my-3 flex-row justify-content-between align-items-end gap-2">
			<seira-card-info
				[texto]="texto"
				[aditionalClasses]="'w-100'"
				style="flex: 1" />
			<seira-select-list-menu
				[opcoes]="opcoesColunas"
				[opcoesSelecionadas]="colunasSelecionadas"
				[disabled]="disabledSelectList || carregando"
				[tituloBotao]="'Personalizar colunas'"
				(atualizarOpcoesSelecionadas)="
					setColunasSelecionadas($event)
				"></seira-select-list-menu>
		</div>

		<div
			*ngIf="carregando; else tabela"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>

		<ng-template #tabela>
			<div style="max-width: 1300px" *ngIf="resultado; else semDados">
				<div class="row mb-5">
					<seira-public-table
						#tabelaDesvioClimatologiaObservado
						id="tabelaDesvioClimatologiaObservado"
						[loading]="carregando"
						[searching]="false"
						[info]="false"
						[hasHeader]="false"
						[scrollX]="true"
						[columns]="colunas"
						[data]="resultado">
					</seira-public-table>
				</div>
			</div>
		</ng-template>
	</div>
	<ng-template #semDados>
		<seira-sem-dados />
	</ng-template>
</div>
