import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
	OpcaoPagina,
	PublicPageOptionsComponent,
} from '@componentes/public-page-options/public-page-options.component';
import { Mensagens } from '@core/enums/mensagens';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'seira-rede-monitoramento',
	templateUrl: './rede-monitoramento.component.html',
	styleUrls: ['./rede-monitoramento.component.scss'],
})
export class RedeMonitoramentoComponent {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;

	opcoesDaPagina: OpcaoPagina<any>[] = [];
	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que é uma estação?',
				text: 'Uma estação é um local onde são instalados sensores e equipamentos que tem a capacidade de medir, gravar e recolher dados sobre o tempo. Eles medem vários parâmetros metereológicos, como por exemplo: temperatura, pressão atmosférica, radiação, chuva, pressão atmosférica, direção e velocidade do vento.',
			},
			{
				title: 'O que você vai encontrar nesta página?',
				text: 'Aqui você pode visualizar mapas, gráficos e tabelas com dados oriundos de pluviômetros pertencentes à rede de monitoramento da AESA. É possível consultar tantos dados de PCD (Plataforma de Coleta de Dados automáticas) quanto de PCO (consiste em Pluviômetro tipo <i class="fst-italic">Ville de Paris</i> com medição manual para medições de 24 horas).',
			},
		],
	};

	constructor(private readonly modalService: BsModalService) {}

	ngAfterViewInit() {
		this.adicionarOpcoesDaPagina();
	}
	adicionarOpcoesDaPagina() {
		this.opcoesDaPagina = [
			{
				label: 'Legenda',
				template: this.legendaMapaMobile,
			},
		];
	}
	abrirModalOpcoesDaPagina(event: Event) {
		event.preventDefault();
		this.modalService.show(PublicPageOptionsComponent, {
			class: 'modal-dialog-centered',
			initialState: {
				opcoes: this.opcoesDaPagina,
			},
		});
	}
}
