import { Component, Input } from '@angular/core';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	DadosTabelaPluviometriaObservada,
	Relatorios,
} from '../../../interfaces';

@Component({
	selector: 'seira-tabela-relatorio-pluviometria-observada',
	templateUrl: './tabela-relatorio-pluviometria-observada.component.html',
	styleUrls: ['./tabela-relatorio-pluviometria-observada.component.scss'],
})
export class TabelaRelatorioPluviometriaObservadaComponent {
	@Input() relatorios: Relatorios<DadosTabelaPluviometriaObservada>[] = [];
	colunas: ADTColumns[] = [
		{
			data: 'mesorregiao',
			title: 'Mesorregião',
			type: 'string',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'posto',
			title: 'Posto',
			type: 'string',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'latitude',
			title: 'Latitude',
			type: 'number',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'longitude',
			title: 'Longitude',
			type: 'number',
			className: 'text-start',
			orderable: false,
		},
	];
}
