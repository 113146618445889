export abstract class GeoJSONUtils {
	static getChavePropriedadeFeatureGeoJSON(agrupamento: string): string {
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
			case 'MUNICIPIO':
			case 'REGIAO_PLUVIOMETRICA':
				return 'name';
			case 'MICRORREGIAO':
				return 'nome';
			case 'MESORREGIAO':
				return 'NM_MESO';
			case 'BACIA':
			case 'SUBBACIA':
				return 'Nome';
			default:
				return 'nome';
		}
	}
}
