<div
	#mapaParaiba
	style="height: 500px; width: 100%"
	class="mapa-paraiba rounded shadow-lg h-100"
	[id]="mapaId"
	[leafletOptions]="options"
	leaflet
	(leafletMapReady)="loadMap($event)">
	<seira-loading *ngIf="loading"></seira-loading>
	<ng-content />
</div>
