import { Component, Input } from '@angular/core';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { LegendaItem } from '@home/submodulos/dados-meteorologicos/interfaces/legenda-de-cores';
import * as pdfseira from '@utils/pdf-seira';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { ModalLegendaMapaRelatoriosComponent } from '../../modal-legenda-mapa-relatorios/modal-legenda-mapa-relatorios.component';

@Component({
	selector: 'seira-legenda-relatorio',
	templateUrl: './legenda-relatorio.component.html',
	styleUrls: ['./legenda-relatorio.component.scss'],
})
export class LegendaRelatorioComponent {
	@Input() legenda: LegendaItem[] = [];
	@Input() tipo = '';
	@Input() titulo = '';
	@Input() periodo = '';
	@Input() isDesktop = false;
	@Input() exibirBotaoExportar = false;
	close = false;

	constructor(private modalService: BsModalService) {}

	openModal() {
		const closeModal = () => {
			this.modalService.hide();
		};
		this.modalService.show(ModalLegendaMapaRelatoriosComponent, {
			backdrop: 'static',
			class: 'modal-xl modal-dialog-centered bg-transparent',
			initialState: {
				close: closeModal,
				legenda: this.legenda,
			},
		});
	}

	getMapaElemento() {
		const svgContainer = document.getElementById('map-container');
		const svgContent = svgContainer?.querySelector('svg')!;
		return svgContent.outerHTML;
	}

	async exportarPDF() {
		const svg = this.getMapaElemento();
		const dataFormatada = format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
		const legendaContent: any[] = this.legenda.map(item => {
			return {
				columns: [
					{
						canvas: [
							{
								type: 'rect',
								x: 0,
								y: 0,
								w: 10,
								h: 10,
								r: 5,
								color: item.color,
							},
						],
						width: 20,
						margin: [100, 5, 0, 0],
					},
					{
						text: item.label,
						fontSize: 10,
						margin: [105, 5, 0, 0],
					},
					{
						text: `${item.index} município(s)`,
						fontSize: 8,
						color: 'gray',
						margin: [100, 5, 0, 0],
					},
				],
			};
		});

		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push([
			{
				text: `Relatório - ${this.tipo} - ${this.periodo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				text: `Relatório exportado em: ${dataFormatada}`,
				alignment: 'center',
				margin: [0, 0, 0, 0],
				fontSize: 10,
			},
			{
				svg: svg,
				width: 600,
				height: 400,
				alignment: 'center',
			},
			{
				stack: [
					{
						text: 'Legenda',
						fontSize: 12,
						fontWeight: 'bold',
						alignment: 'center',
					},
					...legendaContent,
				],
			},
		]);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'big',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
	];
}
