import { FormularioRelatorio } from './../../../interfaces/tipos-relatorios';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { format, formatISO } from 'date-fns';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DadosValoresExtremosResponse } from '../../../interfaces/valores-extremos';
import { DateTimeUtils } from '@utils/datetime-util';
import { formataValorPrecipitacao } from '@utils';

@Component({
	selector: 'seira-valores-extremos-tabela',
	templateUrl: './valores-extremos-tabela.component.html',
	styleUrls: ['./valores-extremos-tabela.component.scss'],
})
export class ValoresExtremosTabelaComponent
	implements OnDestroy, InstanciaRelatorio, OnInit
{
	form!: FormGroup;

	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaValoresExtremos: any;
	carregandoRelatorio = false;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.dadosTabelaValoresExtremos);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV(this.dadosTabelaValoresExtremos);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT(this.dadosTabelaValoresExtremos);
			},
		},
	];
	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.form = this.inputs.form;
		this.estacoes = this.inputs.estacoes;
	}

	ngOnInit() {
		setTimeout(() => {
			this.setValidators();
			this.definirPeriodoPadrao();
			this.gerarRelatorio();
		});
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	private definirPeriodoPadrao() {
		const hoje = moment();
		const semanaAnterior = moment(hoje).subtract(1, 'week');

		this.form.get('dataInicio')?.setValue(semanaAnterior);
		this.form.get('dataFim')?.setValue(hoje);
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}
	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	public gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		const posto = this.getFormItemValue(FormularioRelatorio.ESTACAO);
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		const tipoPeriodoValoresExtremos = this.getFormItemValue(
			FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
		);

		this.relatoriosService
			.buscarRelatorioValoresExtremos({
				diaInicio: formatISO(new Date(dataInicio)),
				diaFim: formatISO(new Date(dataFim)),
				agrupamento,
				tipoPeriodoValoresExtremos,
				posto,
			})
			.subscribe({
				next: ValoresExtremos => {
					this.dadosTabelaValoresExtremos = ValoresExtremos;
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
			});
	}

	getPeriodo() {
		const tipoPeriodoValoresExtremos = this.form.get(
			FormularioRelatorio.TIPO_PERIODO_VALORES_EXTREMOS
		)?.value;
		let dataInicio;
		let dataFim;

		switch (tipoPeriodoValoresExtremos) {
			case 'MENSAL_COMPLETO':
				dataInicio = this.form
					.get(FormularioRelatorio.DATA_INICIO)
					?.value.format('MM yyyy');
				return 'Mês ' + dataInicio;
			case 'ANUAL_COMPLETO':
				dataInicio = this.form
					.get(FormularioRelatorio.DATA_INICIO)
					?.value.format('yyyy');
				return dataInicio;
			case 'MENSAL_PARCIAL':
				dataInicio = this.form
					.get(FormularioRelatorio.DATA_INICIO)
					?.value.format('DD/MM/yyyy');
				dataFim = this.form
					.get(FormularioRelatorio.DATA_FIM)
					?.value.format('DD/MM/yyyy');
				break;
			case 'ANUAL_PARCIAL':
				dataInicio = this.form
					.get(FormularioRelatorio.DATA_INICIO)
					?.value.format('DD/MM/yyyy');
				dataFim = this.form
					.get(FormularioRelatorio.DATA_FIM)
					?.value.format('DD/MM/yyyy');
				break;
			default:
				break;
		}
		return dataInicio + ' a ' + dataFim;
	}

	formatarDatas(data: string) {
		return DateTimeUtils.formatarData(data, 'dd/MM/yyyy') || '-';
	}

	formatarNumeros(numero: number) {
		return formataValorPrecipitacao(numero) || '-';
	}

	getAgrupamento() {
		const agrupamento = this.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				return 'Município/Posto';
			case 'MICRORREGIAO':
				return 'Microrregião';
			case 'MUNICIPIO':
				return 'Município';
			case 'MESORREGIAO':
				return 'Mesorregião';
			case 'REGIAO_PLUVIOMETRICA':
				return 'Região Pluviométrica';
			case 'BACIA':
				return 'Bacia';
			case 'SUB_BACIA':
				return 'Sub bacia';
			default:
				return '-';
		}
	}

	async exportarPDF(
		dadosTabelaValoresExtremos: DadosValoresExtremosResponse[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		documentDefinition.pageOrientation = 'landscape';
		documentDefinition.pageSize = 'C2';
		documentDefinition.pageMargins = [20, 90, 20, 40];

		documentDefinition.content.push({
			text: `Relatório - Valores Extremos - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaValoresExtremos.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		const tableData = [
			[
				{
					text: this.getAgrupamento(),
					style: 'tableHeader',
					rowSpan: 2,
					alignment: 'center',
				},
				{
					text: 'Precipitação (mm)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Temperatura máxima do ar (oC)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Umidade relativa do ar (%)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Pressão Atmosférica (hPa)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Radiação solar (W/m2)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Evapotranspiração (mm)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Velocidade do vento a 2 metros (m/s)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Velocidade do vento a 10 metros (m/s)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
			],
			[
				'',
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
			],
		];

		dadosTabelaValoresExtremos.forEach(dados => {
			tableData.push([
				dados.nome,
				this.formatarDatas(dados.dataColetaMaxPrecipitacao),
				this.formatarNumeros(dados.maxPrecipitacao),
				this.formatarDatas(dados.dataColetaMinPrecipitacao),
				this.formatarNumeros(dados.minPrecipitacao),
				this.formatarDatas(dados.dataColetaMaxTemperaturaAr),
				this.formatarNumeros(dados.maxTemperaturaAr),
				this.formatarDatas(dados.dataColetaMinTemperaturaAr),
				this.formatarNumeros(dados.minTemperaturaAr),
				this.formatarDatas(dados.dataColetaMaxUmidadeRelativa),
				this.formatarNumeros(dados.maxUmidadeRelativa),
				this.formatarDatas(dados.dataColetaMinUmidadeRelativa),
				this.formatarNumeros(dados.minUmidadeRelativa),
				this.formatarDatas(dados.dataColetaMaxPressaoAtmosferica),
				this.formatarNumeros(dados.maxPressaoAtmosferica),
				this.formatarDatas(dados.dataColetaMinPressaoAtmosferica),
				this.formatarNumeros(dados.minPressaoAtmosferica),
				this.formatarDatas(dados.dataColetaMaxRadiacao),
				this.formatarNumeros(dados.maxRadiacao),
				this.formatarDatas(dados.dataColetaMinRadiacao),
				this.formatarNumeros(dados.minRadiacao),
				this.formatarDatas(dados.dataColetaMaxEvapotranspiracao),
				this.formatarNumeros(dados.maxEvapotranspiracao),
				this.formatarDatas(dados.dataColetaMinEvapotranspiracao),
				this.formatarNumeros(dados.minEvapotranspiracao),
				this.formatarDatas(dados.dataColetaMaxVelocidadeVento2m),
				this.formatarNumeros(dados.maxVelocidadeVento2m),
				this.formatarDatas(dados.dataColetaMinVelocidadeVento2m),
				this.formatarNumeros(dados.minVelocidadeVento2m),
				this.formatarDatas(dados.dataColetaMaxVelocidadeVento10m),
				this.formatarNumeros(dados.maxVelocidadeVento10m),
				this.formatarDatas(dados.dataColetaMinVelocidadeVento10m),
				this.formatarNumeros(dados.minVelocidadeVento10m),
			]);
		});

		documentDefinition.content.push({
			table: {
				headerRows: 2,
				body: tableData,
				layout: 'lightHorizontalLines',
				fontSize: 8,
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(dadosTabelaValoresExtremos: DadosValoresExtremosResponse[]) {
		const tableData = [];

		tableData.push([
			'',
			'Precipitação (mm)',
			'',
			'',
			'',
			'Temperatura máxima do ar (oC)',
			'',
			'',
			'',
			'Umidade relativa do ar (%)',
			'',
			'',
			'',
			'Pressão Atmosférica (hPa)',
			'',
			'',
			'',
			'Radiação solar (W/m2)',
			'',
			'',
			'',
			'Evapotranspiração (mm)',
			'',
			'',
			'',
			'Velocidade do vento a 2 metros (m/s)',
			'',
			'',
			'',
			'Velocidade do vento a 10 metros (m/s)',
			'',
			'',
			'',
		]);

		tableData.push([
			this.getAgrupamento(),
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
		]);

		dadosTabelaValoresExtremos.forEach(dados => {
			tableData.push([
				dados.nome,
				this.formatarDatas(dados.dataColetaMaxPrecipitacao),
				this.formatarNumeros(dados.maxPrecipitacao),
				this.formatarDatas(dados.dataColetaMinPrecipitacao),
				this.formatarNumeros(dados.minPrecipitacao),
				this.formatarDatas(dados.dataColetaMaxTemperaturaAr),
				this.formatarNumeros(dados.maxTemperaturaAr),
				this.formatarDatas(dados.dataColetaMinTemperaturaAr),
				this.formatarNumeros(dados.minTemperaturaAr),
				this.formatarDatas(dados.dataColetaMaxUmidadeRelativa),
				this.formatarNumeros(dados.maxUmidadeRelativa),
				this.formatarDatas(dados.dataColetaMinUmidadeRelativa),
				this.formatarNumeros(dados.minUmidadeRelativa),
				this.formatarDatas(dados.dataColetaMaxPressaoAtmosferica),
				this.formatarNumeros(dados.maxPressaoAtmosferica),
				this.formatarDatas(dados.dataColetaMinPressaoAtmosferica),
				this.formatarNumeros(dados.minPressaoAtmosferica),
				this.formatarDatas(dados.dataColetaMaxRadiacao),
				this.formatarNumeros(dados.maxRadiacao),
				this.formatarDatas(dados.dataColetaMinRadiacao),
				this.formatarNumeros(dados.minRadiacao),
				this.formatarDatas(dados.dataColetaMaxEvapotranspiracao),
				this.formatarNumeros(dados.maxEvapotranspiracao),
				this.formatarDatas(dados.dataColetaMinEvapotranspiracao),
				this.formatarNumeros(dados.minEvapotranspiracao),
				this.formatarDatas(dados.dataColetaMaxVelocidadeVento2m),
				this.formatarNumeros(dados.maxVelocidadeVento2m),
				this.formatarDatas(dados.dataColetaMinVelocidadeVento2m),
				this.formatarNumeros(dados.minVelocidadeVento2m),
				this.formatarDatas(dados.dataColetaMaxVelocidadeVento10m),
				this.formatarNumeros(dados.maxVelocidadeVento10m),
				this.formatarDatas(dados.dataColetaMinVelocidadeVento10m),
				this.formatarNumeros(dados.minVelocidadeVento10m),
			]);
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-valores-extremos-${this.getPeriodo()}`
		);
	}

	exportarTXT(dadosTabelaValoresExtremos: DadosValoresExtremosResponse[]) {
		let txtData = '';

		dadosTabelaValoresExtremos.forEach(dados => {
			txtData +=
				`${this.getAgrupamento()}: ${dados.nome}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxPrecipitacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima precipitação (mm): ${this.formatarNumeros(
					dados.maxPrecipitacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinPrecipitacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				` Mínima precipitação (mm): ${this.formatarNumeros(
					dados.minPrecipitacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxTemperaturaAr),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima Temperatura do ar (oC): ${this.formatarNumeros(
					dados.maxTemperaturaAr
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinTemperaturaAr),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima Temperatura do ar (oC): ${this.formatarNumeros(
					dados.minTemperaturaAr
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxUmidadeRelativa),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima umidade relativa do ar (%): ${this.formatarNumeros(
					dados.maxUmidadeRelativa
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinUmidadeRelativa),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima umidade relativa do ar (%): ${this.formatarNumeros(
					dados.minUmidadeRelativa
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxPressaoAtmosferica),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima pressão atmosférica (hPa): ${this.formatarNumeros(
					dados.maxPressaoAtmosferica
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinPressaoAtmosferica),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima pressão atmosférica (hPa): ${this.formatarNumeros(
					dados.minPressaoAtmosferica
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxRadiacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima radiação solar (W/m2): ${this.formatarNumeros(
					dados.maxRadiacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinRadiacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima radiação solar (W/m2): ${this.formatarNumeros(
					dados.minRadiacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxEvapotranspiracao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima evapotranspiração (mm): ${this.formatarNumeros(
					dados.maxEvapotranspiracao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinEvapotranspiracao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima evapotranspiração (mm): ${this.formatarNumeros(
					dados.minEvapotranspiracao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxVelocidadeVento2m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima velocidade do vento a 2 metros (m/s): ${this.formatarNumeros(
					dados.maxVelocidadeVento2m
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinVelocidadeVento2m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima velocidade do vento a 2 metros (m/s): ${this.formatarNumeros(
					dados.minVelocidadeVento2m
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxVelocidadeVento10m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima velocidade do vento a 10 metros (m/s): ${this.formatarNumeros(
					dados.maxVelocidadeVento10m
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinVelocidadeVento10m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima velocidade do vento a 10 metros (m/s): ${this.formatarNumeros(
					dados.minVelocidadeVento10m
				)}\n\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-valores-extremos-${this.getPeriodo()}`
		);
	}
}
