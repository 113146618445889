<label class="text-tertiary fw-semibold mt-3 text-center w-100">
	Histórico da SUDENE - {{ stringData }}
</label>
<div class="row">
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />
	<div
		*ngIf="taCarregando"
		style="height: 400px; width: 100%"
		class="d-flex align-items-center justify-content-center">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<div class="mb-2">
		<div
			*ngIf="
				(!dadosSudeneSeparadosPorPosto && !taCarregando) ||
				(dadosSudeneSeparadosPorPosto?.length === 0 && !taCarregando)
			">
			<seira-sem-dados />
		</div>
		<div *ngIf="!taCarregando">
			<div *ngFor="let relatorioPosto of dadosSudeneSeparadosPorPosto">
				<div class="justify-between">
					<div>
						<label
							[for]="
								'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
							"
							class="text-tertiary mb-2 fw-semibold">
							{{ relatorioPosto.posto }}
						</label>
						<label
							[for]="
								'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
							"
							class="fw-normal legenda-total">
							&nbsp;(Lat:
							{{
								relatorioPosto.localizacao.latitude
									? relatorioPosto.localizacao.latitude
											.toString()
											.replaceAll('.', ',')
									: '--'
							}}; Lon:
							{{
								relatorioPosto.localizacao.longitude
									? relatorioPosto.localizacao.longitude
											.toString()
											.replaceAll('.', ',')
									: '--'
							}}; Alt:
							{{
								relatorioPosto.localizacao.altitude
									? relatorioPosto.localizacao.altitude
											.toString()
											.replaceAll('.', ',')
									: '--'
							}})
						</label>
					</div>
					<div>
						<label
							class="mb-2 fw-normal legenda-total"
							[for]="
								'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
							">
							Total de chuvas:
							{{
								relatorioPosto
									.total()
									.toFixed(1)
									.toString()
									.replaceAll('.', ',')
							}}
							mm
						</label>
					</div>
				</div>
				<div class="mb-4">
					<seira-public-table
						[id]="
							'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
						"
						[hasFooter]="true"
						[hasHeader]="false"
						[loading]="taCarregando"
						[info]="true"
						[paging]="true"
						[searching]="false"
						[scrollX]="true"
						[spaceReduceTh]="true"
						[columns]="colunas"
						[order]="[0, 'asc']"
						#tabelaRelatorioSudene
						[data]="relatorioPosto.dados || []"></seira-public-table>
				</div>
			</div>
		</div>
	</div>
</div>
