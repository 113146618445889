import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@core/interfaces/user';
import { UserService } from '@core/services/user/user.service';

@Component({
	selector: 'seira-form-redefinir-senha',
	templateUrl: './form-redefinir-senha.component.html',
	styleUrls: ['./form-redefinir-senha.component.scss'],
})
export class FormRedefinirSenhaComponent implements OnInit {
	redefinirSenhaForm!: FormGroup;
	carregando = false;
	user: User | null;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private toastr: ToastrService,
		private router: Router,
		private route: ActivatedRoute,
		private userService: UserService
	) {
		this.user = null;
	}

	ngOnInit(): void {
		this.userService.getUser().subscribe((user: User | null) => {
			this.user = user;
		});

		this.redefinirSenhaForm = this.formBuilder.group({
			novaSenha: ['', [Validators.required]],
			confirmarSenha: ['', [Validators.required]],
		});
	}

	redefinirSenha() {
		const token = this.route.snapshot.paramMap.get('token');
		const novaSenha = this.redefinirSenhaForm.get('novaSenha')?.value;
		const confirmarSenha = this.redefinirSenhaForm.get('confirmarSenha')?.value;

		if (novaSenha !== confirmarSenha) {
			this.toastr.error(
				'A nova senha e a confirmação de senha não correspondem.',
				'Erro'
			);
			return;
		}

		if (token == null) {
			this.toastr.error('Token é necessario.', 'Erro');
			return;
		}

		this.carregando = true;
		this.authService.resetPassword(token, novaSenha).subscribe({
			next: () => {
				this.toastr.success('Senha alterada com sucesso.');
				this.router.navigate(['home']).then();
				this.carregando = false;
			},
			error: err => {
				const erroDetalhes: string | undefined = err.error?.mensagem;
				if (erroDetalhes === undefined) {
					this.toastr.error('Falha ao redefinir senha.', 'Erro');
				} else {
					this.toastr.error(`${erroDetalhes}`, 'Falha ao redefinir senha.');
				}
				this.carregando = false;
			},
		});
	}

	conditionShowTextRequired(field: string) {
		return (
			this.redefinirSenhaForm.get(field)?.errors?.['required'] &&
			this.redefinirSenhaForm.get(field)?.touched
		);
	}
}
