import * as Highcharts from 'highcharts/highstock';

export const optionsChartIapm = (): Highcharts.Options => ({
	chart: {
		type: 'column',
		backgroundColor: 'transparent',
	},
	title: {
		text: 'Índice de anomalia de precipitação modificado (IAPM)',
		align: 'center',
	},
	subtitle: {
		text: '',
		align: 'left',
	},
	xAxis: {
		title: {
			text: 'Período',
		},
		type: 'category',
	},
	yAxis: {
		title: {
			text: 'Índice de anomalia de precipitação modificado (IAPM)',
		},
	},
	tooltip: {
		valueSuffix: ' mm',
	},
	plotOptions: {
		column: {
			borderWidth: 0,
		},
	},
	series: [],
});
