import { HttpClient } from '@angular/common/http';
import {
	AfterViewInit,
	ApplicationRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	InterpolacaoCanvasBounds,
	ValoresInterpolacao,
} from '@utils/interpolacao/interpolacao';
import { predict } from '@utils/interpolacao/kriging';
import { medicaoIcon } from '@utils/leaflet-icons';
import { FeatureCollection } from 'geojson';
import * as Leaflet from 'leaflet';
import L, {
	latLng,
	latLngBounds,
	LatLngExpression,
	LayerGroup,
	LeafletMouseEvent,
} from 'leaflet';

import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { PopupPadraoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/popup-padrao/popup-padrao.component';
import {
	MAPAS_ESTACOES_AUTOMATICAS,
	mapEstacoeslayers,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/mapa-interpolacao/utils';
import { PopupInformacoesChuvaEstadoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/popup-informacoes-chuva-estado/popup-informacoes-chuva-estado.component';
import { PopupInformacoesIapmComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/popup-informacoes-iapm/popup-informacoes-iapm.component';
import { PopupInformacoesNdcComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/popup-informacoes-ndc/popup-informacoes-ndc.component';
import { PopupInformacoesPcdComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/popup-informacoes-pcd/popup-informacoes-pcd.component';
import { EstacaoUltimasMedicoes } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/estacao-ultimas-medicoes';
import { DataMarker } from '@utils/leaflet';
import { catchError, Subscription } from 'rxjs';
import { PopupMapaDesvioComponent } from '../popup-mapa-desvio/popup-mapa-desvio.component';

@Component({
	selector: 'seira-mapa-interpolacao',
	templateUrl: './mapa-interpolacao.component.html',
	styleUrls: ['./mapa-interpolacao.component.scss'],
})
export class MapaInterpolacaoComponent
	implements AfterViewInit, OnChanges, OnDestroy
{
	static readonly COORDENADAS_PADRAO: LatLngExpression = [-7.2605416, -36.25];
	static readonly ZOOM_PADRAO: number = 8;

	mapa!: L.Map;
	imageOverlay!: L.ImageOverlay;
	@Input()
	interpolacao!: InterpolacaoCanvasBounds;
	@Input()
	imageOpacity = 0;
	marker!: L.Marker;
	@Input()
	mapaId = 'mapa-interpolacao';
	@Input()
	mapaNome = 'Interpolação';
	@ViewChild('mapaDiv')
	mapaDiv!: ElementRef;

	@Input()
	isLegendaOculta = false;

	@Input() fillOpacity = 0.2;
	@Input() colorJsonInterpolacao = '#414141';
	@Input() weight = 1;

	estacoes: L.Marker[] = [];

	@Input()
	medicoes: EstacaoUltimasMedicoes[] = [];
	@Input()
	valores: any;
	@Input()
	carregando = false;
	@Input()
	exportButtons: GroupButton[] = [];
	@ViewChild('actionsTemplate', { static: true, read: TemplateRef })
	actionsTemplate!: TemplateRef<any>;
	@Input()
	unidade: string | undefined = '';
	@Output() mapaEmitter = new EventEmitter<Leaflet.Map>();
	mouseEmCimaDoMapa = false;
	geoJsonLayerSatelite: L.GeoJSON<any> | null = null;
	geoJsonLayerInterpolacao: L.GeoJSON<any> | null = null;
	subscriptions = new Subscription();
	markersLayer: LayerGroup;
	coordenadas: [number, number] = [-7.2605416, -36.7290255];

	constructor(
		private httpClient: HttpClient,
		private resolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {
		this.markersLayer = Leaflet.layerGroup();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	loadSvg(url: string): Promise<string> {
		// @ts-ignore
		return this.httpClient.get(url, { responseType: 'text' }).toPromise();
	}

	loadMap(map: Leaflet.Map) {
		this.mapa = map;
		this.addResetViewControl(map);
	}

	resetMapView() {
		const defaultZoom = 8;
		this.mapa.setView(this.coordenadas, defaultZoom);
	}

	addResetViewControl(map: Leaflet.Map) {
		const resetViewControl = L.Control.extend({
			options: {
				position: 'topleft',
				title: 'Reset view',
			},
			onAdd: (map: Leaflet.Map) => {
				const container = L.DomUtil.create('div', 'leaflet-control-reset');
				container.style.backgroundColor = '#0D6A92';
				container.style.width = '30px';
				container.style.height = '30px';
				container.style.display = 'flex';
				container.style.alignItems = 'center';
				container.style.justifyContent = 'center';
				container.style.cursor = 'pointer';
				container.style.borderBottom = '2px solid transparent';

				this.loadSvg('assets/images/reset_icon.svg').then(svgContent => {
					const svgContainer = L.DomUtil.create('div', '');
					svgContainer.innerHTML = svgContent;
					svgContainer.style.display = 'flex';
					svgContainer.style.alignItems = 'center';
					svgContainer.style.justifyContent = 'center';
					container.innerHTML = '';
					container.appendChild(svgContainer);
				});

				container.onclick = () => {
					this.resetMapView();
				};

				container.onmouseover = () => {
					container.style.backgroundColor = '#3e9536';
				};

				container.onmouseout = () => {
					container.style.backgroundColor = '#0D6A92';
				};

				const existingZoomControls = document.querySelectorAll(
					'.leaflet-control-container .leaflet-top .leaflet-control-zoom a'
				);
				if (existingZoomControls.length === 2) {
					existingZoomControls[1].parentNode!.insertBefore(
						container,
						existingZoomControls[1].nextSibling
					);
				} else {
					const fallbackContainer = L.DomUtil.create(
						'div',
						'leaflet-bar leaflet-control leaflet-control-custom'
					);
					fallbackContainer.appendChild(container);
					return fallbackContainer;
				}

				return L.DomUtil.create('div');
			},
		});

		map.addControl(new resetViewControl());
	}

	setupMap() {
		this.mapa = Leaflet.map(this.mapaId, {
			zoom: 8,
			preferCanvas: true,
			minZoom: 6,
			maxZoom: 19,
			center: latLng(this.coordenadas[0], this.coordenadas[1]),
			maxBounds: latLngBounds(latLng(-4.5, -39.2), latLng(-9.5, -34.2)),
			layers: [mapEstacoeslayers[MAPAS_ESTACOES_AUTOMATICAS.INTERPOLACAO]],
		});
		this.mapa.createPane('labels');
		this.addResetViewControl(this.mapa);
		this.mapaEmitter.emit(this.mapa);
		this.modifyMapa();
	}

	modifyMapa() {
		const pane = L.tileLayer(
			'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
			{
				maxZoom: 19,
				pane: 'labels',
			}
		);
		this.mapa.on('zoom', () => {
			if (this.mapa.getZoom() > 8 && !pane.getContainer()) {
				pane.addTo(this.mapa);
			} else {
				pane.remove();
			}
		});
	}

	ngAfterViewInit(): void {
		this.markersLayer.clearLayers();
		this.setupMap();

		this.mapa.createPane('labels');
		const labelsPane = this.mapa?.getPane('labels');
		if (labelsPane) {
			labelsPane.style.pointerEvents = 'none';
		}
		this.mapa.createPane('markers');
		this.mapa.getPane('markers')!.style.zIndex = '1000';
		this.marker = L.marker([0, 0], {
			icon: medicaoIcon,
			pane: 'markers',
		}).addTo(this.markersLayer);

		this.marker.bindTooltip(L.tooltip({ pane: 'markers' }));

		this.marker.setTooltipContent('-');

		if (this.interpolacao && this.interpolacao.canvas) {
			this.imageOverlay = L.imageOverlay(
				this.interpolacao.canvas.toDataURL(),
				this.interpolacao.latLngBounds,
				{
					opacity: this.imageOpacity,
				}
			);
		}
		this.carregarDadosInterpolacao();
	}

	updateMarkers(): void {
		if (this.interpolacao) {
			this.interpolacao.valores.forEach(markerPoint => {
				const marker = new DataMarker<ValoresInterpolacao>(
					[markerPoint.lat, markerPoint.lng],
					{
						icon: this.setIcon(markerPoint),
						data: markerPoint,
					}
				);

				let markerPopup;
				if ('mesoregiao' in markerPoint) {
					markerPopup = this.compilePopup(c => {
						c.instance.markerData = markerPoint;
					}, PopupInformacoesChuvaEstadoComponent);
				} else if (
					'municipio' in markerPoint &&
					!('tipoValor' in markerPoint)
				) {
					markerPopup = this.compilePopup(c => {
						c.instance.markerData = markerPoint;
						c.instance.unidade = this.unidade;
					}, PopupInformacoesPcdComponent);
				} else if ('desvio' in markerPoint) {
					markerPopup = this.compilePopup(c => {
						c.instance.markerData = markerPoint;
					}, PopupMapaDesvioComponent);
				} else if ('tipoValor' in markerPoint) {
					markerPopup = this.compilePopup(c => {
						c.instance.markerData = markerPoint;
					}, PopupPadraoComponent);
				} else {
					if (this.mapaId == 'ndc') {
						markerPopup = this.compilePopup(c => {
							c.instance.markerData = markerPoint;
							c.instance.unidade = this.unidade;
							c.instance.chuvas = this.valores;
						}, PopupInformacoesNdcComponent);
					} else {
						markerPopup = this.compilePopup(c => {
							c.instance.markerData = markerPoint;
						}, PopupInformacoesIapmComponent);
					}
				}

				marker.bindPopup(markerPopup, {
					closeButton: false,
				});
				marker.addTo(this.markersLayer);
			});
		} else {
			const valores: ValoresInterpolacao[] = this.medicoes.map(value => {
				const valorTipoPCD = value.tipoPCD;
				return {
					lat: value.latitude,
					lng: value.longitude,
					value: value.valor,
					municipio: value.municipio,
					valorMax: value.valorMax,
					valorMin: value.valorMin,
					nomePosto: value.nomePosto,
					tipoPCD: this.labelTipoPcd(valorTipoPCD),
				};
			});
			valores.forEach(medicao => {
				const marker = new DataMarker<ValoresInterpolacao>(
					[medicao.lat, medicao.lng],
					{
						icon: this.setIcon(medicao),
						data: medicao,
					}
				);

				let markerPopup;

				markerPopup = this.compilePopup(c => {
					c.instance.markerData = medicao;
					c.instance.unidade = this.unidade;
				}, PopupInformacoesPcdComponent);

				marker.bindPopup(markerPopup, {
					closeButton: false,
				});

				marker.addTo(this.markersLayer);
			});
		}

		this.mapa.addLayer(this.markersLayer);
	}

	labelTipoPcd(tipo: string | null) {
		if (tipo === 'METEOROLOGICA') {
			return 'Meteorológica';
		} else if (tipo === 'AGROMETEOROLOGICA') {
			return 'Agrometeorológica';
		}
		return null;
	}

	setIcon(estacao: ValoresInterpolacao) {
		return Leaflet.icon({
			iconUrl: `assets/images/leaflet-markers/${this.setIconName(
				'tipoPCD' in estacao ? estacao.tipoPCD : null
			)}.png`,
			iconSize: [8, 8],
		});
	}

	setIconName(tipoPCD?: string | null) {
		if (tipoPCD === 'Agrometeorológica') {
			return 'agrometeorologica';
		} else if (tipoPCD === 'Meteorológica') {
			return 'meteorologica';
		}
		return 'circle';
	}

	ngOnChanges(): void {
		this.carregarDadosInterpolacao();
	}

	loadGeoJson = () => {
		return this.httpClient
			.get<FeatureCollection>('assets/geoJson/pb-municipios-geo.json')
			.pipe(
				catchError(error => {
					console.error('Error loading GeoJSON:', error);
					throw error;
				})
			);
	};

	carregarJsonInterpolacao() {
		this.subscriptions.add(
			this.loadGeoJson().subscribe(data => {
				const color = this.colorJsonInterpolacao;
				const weight = this.weight;
				this.geoJsonLayerInterpolacao = L.geoJSON(data, {
					style() {
						return {
							color: color,
							opacity: 0.2,
							weight: weight,
							fillOpacity: 0,
						};
					},
				})
					.addTo(this.mapa)
					.on('click', (event: LeafletMouseEvent) => {
						this.marker.setLatLng(event.latlng);
						if (this.interpolacao) {
							const valor = predict(
								event.latlng.lng,
								event.latlng.lat,
								this.interpolacao.variograma
							);

							if (!isNaN(valor)) {
								this.marker.setTooltipContent(
									' Valor : ' +
										(valor < this.interpolacao.legenda[0].valorMin
											? this.interpolacao.legenda[0].valorMin
											: valor
										).toFixed(1) +
										' ' +
										this.interpolacao.legenda[0].unidade +
										''
								);
							}
						}
					});
			})
		);
	}

	carregarDadosInterpolacao() {
		if (this.mapa && this.interpolacao && this.interpolacao.canvas) {
			this.geoJsonLayerSatelite?.remove();
			this.geoJsonLayerSatelite = null;

			if (!this.geoJsonLayerInterpolacao) {
				this.carregarJsonInterpolacao();
			}

			if (!this.imageOverlay) {
				this.imageOverlay = L.imageOverlay(
					this.interpolacao.canvas.toDataURL(),
					this.interpolacao.latLngBounds,
					{
						opacity: this.imageOpacity,
					}
				);
			}
			this.imageOverlay.setOpacity(this.imageOpacity);
			this.imageOverlay.setBounds(this.interpolacao.latLngBounds);
			this.imageOverlay.setUrl(this.interpolacao.canvas.toDataURL());
			this.imageOverlay.addTo(this.mapa);
			this.updateMarkers();
		} else {
			if (this.imageOverlay) {
				this.imageOverlay.removeFrom(this.mapa);
				this.geoJsonLayerInterpolacao?.remove();
				this.geoJsonLayerInterpolacao = null;

				if (!this.geoJsonLayerSatelite) {
					this.loadGeoJson().subscribe(data => {
						this.geoJsonLayerSatelite = L.geoJSON(data, {
							style() {
								return {
									color: '#FFF',
									opacity: 0.6,
									weight: 1,
									fillOpacity: 0,
								};
							},
						}).addTo(this.mapa);
					});
				}
				this.updateMarkers();
			}
		}
	}

	compilePopup(onAttach: (el: ComponentRef<any>) => void, component: any) {
		const compFactory = this.resolver.resolveComponentFactory(component);
		const compRef: ComponentRef<any> = compFactory.create(this.injector);

		if (onAttach) onAttach(compRef);

		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');

		div.appendChild(compRef.location.nativeElement);
		return div;
	}
}
