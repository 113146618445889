import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import {
	QuantisRegiaoAnualTableValores,
	QuantisRegiaoMensalTableValores,
	Relatorios,
} from '../../interfaces';
import {
	ADTColumns,
	ADTSettings,
} from 'angular-datatables/src/models/settings';
import { Meses } from '../../enum';
import { isNotNuloOuUndefined } from '@utils';
import { CondicaoMap } from '../../utils';

@Component({
	selector: 'seira-tabela-relatorios-quantis',
	templateUrl: './tabela-relatorios-quantis.component.html',
	styleUrls: ['./tabela-relatorios-quantis.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TabelaRelatoriosQuantisComponent implements OnInit, OnChanges {
	@Input() relatorios: Relatorios<
		QuantisRegiaoMensalTableValores | QuantisRegiaoAnualTableValores
	> = {
		data: [],
		municipio: '',
	};
	@Input() isAnual = false;
	dtOptions: ADTSettings = {};
	dados: any;

	colunas: ADTColumns[] = [];

	ngOnInit(): void {
		this.dados = this.gerarDados(this.isAnual);
		this.colunas = this.gerarColunas(this.isAnual);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.dados = this.gerarDados(changes['isAnual'].currentValue);
		this.dados = this.colunas = this.gerarColunas(
			changes['isAnual'].currentValue
		);
	}

	private gerarDados(isAnual: boolean) {
		const observado = 'observado';
		if (isAnual) {
			return (
				this.relatorios as Relatorios<QuantisRegiaoAnualTableValores>
			).data.map(d => {
				let valor: string;
				// Coloco flags para observado para na hora da formatação formatar
				// de um jeito diferente
				if (d.tipo === observado) {
					valor = `flagObservado${d.valor}`;
				} else {
					valor = d.valor.toString();
				}
				return {
					tipo: d.tipo,
					valor,
				};
			});
		}
		return (
			this.relatorios as Relatorios<QuantisRegiaoMensalTableValores>
		).data.map(d => ({
			tipo: d.tipo,
			...(d.tipo === observado
				? d.valores.map(v => `flagObservado${v}`)
				: d.valores),
		}));
	}

	private gerarColunas(isAnual: boolean): ADTColumns[] {
		if (isAnual) {
			return [
				{
					data: 'tipo',
					title: 'Tipo',
					type: 'string',
					className: 'text-center',
					render: condicao => CondicaoMap.get(condicao),
				},
				{
					data: 'valor',
					title: `${this.relatorios.ano} (mm)`,
					type: 'number',
					className: 'text-center',
					orderable: false,
					render: (precipitacao: string | number | null) =>
						isNotNuloOuUndefined(precipitacao)
							? this.handleFormatNumber(precipitacao)
							: '-',
				},
			];
		}
		return [
			{
				data: 'tipo',
				title: 'Tipo',
				type: 'string',
				className: 'text-center',
				render: condicao => CondicaoMap.get(condicao),
			},
			...Array.from({ length: 12 }, (_, i) => ({
				data: i,
				title: `${Meses[i]} (mm)`,
				type: 'number',
				className: 'text-center',
				orderable: false,
				render: (precipitacao: string | number | null) =>
					isNotNuloOuUndefined(precipitacao)
						? this.handleFormatNumber(precipitacao)
						: '-',
			})),
		];
	}

	private handleFormatNumber(value: string | number | null): string {
		if (typeof value === 'string' && value.startsWith('flagObservado')) {
			const str = value.replace('flagObservado', '');
			if (str === 'null') {
				return '-';
			}
			return Number(str).toFixed(1).replace('.', ',');
		}
		const retorno = '≤ ' + Number(value).toFixed(1).replace('.', ',');
		return retorno === '≤ 0,0' ? '0,0' : retorno;
	}
}
