import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';

export interface VariaveisMeteorologicasPorPeriodo {
	data: Date;
	historico: Historico[];
}
export interface VariaveisMeteorologicasPorPeriodoETipoVariavel {
	tipoVariavel: ValuesVariaveis;
	data: Date;
	historico: {
		periodo: keyof typeof TiposPeriodosVariaveisMeteorologicas;
		geocodigo: number;
		valor: number;
		nomeEstacao: string;
		tipoEstacao: keyof typeof TipoEstacao;
		nomeMunicipio: string;
	}[];
}
export enum LabelsHistoricoVariaveisMeteorologicas {
	PRECIPITACAO = 'Precipitação acumulada (mm)',
}
export enum TiposPeriodosVariaveisMeteorologicas {
	DIARIO = 'DIARIO',
	ANUAL = 'ANUAL',
	MENSAL = 'MENSAL',
}

export enum TiposMapasVariaveisMeteorologicas {
	HISTORICO = 'Histórico',
	ISOLINHA = 'Isolinha',
}
interface Historico {
	id: number;
	geocodigo: number;
	periodo: string;
	precipitacaoAcumulada: number;
	temperaturaMedia: number;
	nomeEstacao: string;
	tipoEstacao: keyof typeof TipoEstacao;
	nomeMunicipio: string;
}
export enum CorLegendaVariaveisMeteorologicasPrecipitacao {
	VALOR_MAIS_BAIXO = '#FE1919',
	MUITO_ABAIXO_DA_MEDIA = '#FE6565',
	ABAIXO_DA_MEDIA = '#FECBCB',
	MEDIA = '#E5CBE5',
	ACIMA_DA_MEDIA = '#CBCBFE',
	MUITO_ACIMA_DA_MEDIA = '#6565FE',
	VALOR_MAIS_ALTO = '#0000FE',
}
