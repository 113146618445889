import { obterFabricaSerie } from '@componentes/charts/fabrica-serie-variavel';
import { DadosPrecipitacao } from '@componentes/charts/series/serie-precipitacao';
import { DadosPressao } from '@componentes/charts/series/serie-pressao';
import { DadosTemperatura } from '@componentes/charts/series/serie-temperatura';
import { DadosUmidade } from '@componentes/charts/series/serie-umidade';
import { DadosVento } from '@componentes/charts/series/serie-vento';
import { DadosRadiacaoGlobal } from '@componentes/charts/series/radiacao-global';
import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
} from '@componentes/charts/series/serie-variavel-meteorologica';
import { RespostaGraficoVariaveis } from '../../../interfaces/variaveis-meteologicas';

function roundData(dados: number[]) {
	return dados.map(value => Number(value.toFixed(2)));
}

/**
 * Transforma uma resposta com dados de historico das variável para uma serie de uma variável especifica
 *
 * @param {string} variavel a variável desejada
 * @param {number} indexEixo o indice do eixo na qual a série será inserida no gráfico
 * @param {RespostaGraficoVariaveis} dados os dados de historico
 * @returns {DadosSerieVariavelMeteorologica} a serie a ser inserida no gráfico
 */
export function obterDadosSeriePorResultado(
	variavel: string,
	indexEixo: number,
	dados: RespostaGraficoVariaveis
): DadosSerieVariavelMeteorologica | null {
	const fabricaSerie = obterFabricaSerie(variavel);
	const dadosGraficos = transformarDados(variavel, dados);
	if (fabricaSerie && dadosGraficos.datas.length > 0) {
		return fabricaSerie.obterDadosSerie(dadosGraficos, indexEixo);
	}
	return null;
}

function transformarDados(
	variavel: string,
	dados: RespostaGraficoVariaveis
): DadosGraficoVariaveis {
	switch (variavel) {
		case 'PRECIPITACAO':
			if (possuiDados(dados.precipitacao)) {
				return {
					datas: dados.data,
					precipitacao: roundData(dados.precipitacao),
				} as DadosPrecipitacao;
			}
			break;

		case 'PRESSAO_ATMOSFERICA':
			if (possuiDados(dados.pressaoAtmosferica)) {
				return {
					datas: dados.data,
					pressao: dados.pressaoAtmosferica,
				} as DadosPressao;
			}
			break;

		case 'PRESSAO_INSTANTANEA':
			if (possuiDados(dados.pressaoInstantanea)) {
				return {
					datas: dados.data,
					pressao: dados.pressaoInstantanea,
				} as DadosPressao;
			}
			break;

		case 'PRESSAO_MAXIMA':
			if (possuiDados(dados.pressaoMaxima)) {
				return {
					datas: dados.data,
					pressao: dados.pressaoMaxima,
				} as DadosPressao;
			}
			break;

		case 'PRESSAO_MINIMA':
			if (possuiDados(dados.pressaoMinima)) {
				return {
					datas: dados.data,
					pressao: dados.pressaoMinima,
				} as DadosPressao;
			}
			break;

		case 'TEMPERATURA_AR':
			if (possuiDados(dados.temperaturaAr)) {
				return {
					datas: dados.data,
					temperatura: dados.temperaturaAr,
				} as DadosTemperatura;
			}
			break;

		case 'TEMPERATURA_SOLO':
			if (possuiDados(dados.temperaturaSolo)) {
				return {
					datas: dados.data,
					temperatura: dados.temperaturaSolo,
				} as DadosTemperatura;
			}
			break;

		case 'TEMPERATURA_INSTANTANEA':
			if (possuiDados(dados.temperaturaInstantanea)) {
				return {
					datas: dados.data,
					temperatura: dados.temperaturaInstantanea,
				} as DadosTemperatura;
			}
			break;

		case 'TEMPERATURA_MAXIMA':
			if (possuiDados(dados.temperaturaMaxima)) {
				return {
					datas: dados.data,
					temperatura: dados.temperaturaMaxima,
				} as DadosTemperatura;
			}
			break;

		case 'TEMPERATURA_MINIMA':
			if (possuiDados(dados.temperaturaMinima)) {
				return {
					datas: dados.data,
					temperatura: dados.temperaturaMinima,
				} as DadosTemperatura;
			}
			break;

		case 'UMIDADE_RELATIVA':
			if (possuiDados(dados.umidadeRelativa)) {
				return {
					datas: dados.data,
					umidade: dados.umidadeRelativa,
				} as DadosUmidade;
			}
			break;

		case 'UMIDADE_INSTANTANEA':
			if (possuiDados(dados.umidadeInstantanea)) {
				return {
					datas: dados.data,
					umidade: dados.umidadeInstantanea,
				} as DadosUmidade;
			}
			break;

		case 'UMIDADE_MAXIMA':
			if (possuiDados(dados.umidadeMaxima)) {
				return {
					datas: dados.data,
					umidade: dados.umidadeMaxima,
				} as DadosUmidade;
			}
			break;

		case 'UMIDADE_MINIMA':
			if (possuiDados(dados.umidadeMinima)) {
				return {
					datas: dados.data,
					umidade: dados.umidadeMinima,
				} as DadosUmidade;
			}
			break;
		case 'VENTO':
			if (
				possuiDados(dados.ventoVelocidade) &&
				possuiDados(dados.ventoDirecao)
			) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.ventoVelocidade),
					direcaoVento: roundData(dados.ventoDirecao),
				} as DadosVento;
			}
			break;

		case 'RADIACAO_GLOBAL':
			{
				if (possuiDados(dados.radiacaoGlobal)) {
					return {
						datas: dados.data,
						radiacaoGlobal: dados.radiacaoGlobal,
					} as DadosRadiacaoGlobal;
				}
			}
			break;
	}
	return { datas: [] };
}

function possuiDados(listaDados: number[]): boolean {
	return listaDados.filter(value => value != null).length > 0;
}
