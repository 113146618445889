<label *ngIf="label" class="form-label text-tertiary-light" [for]="id"
	>{{ label }}<span class="text-danger" *ngIf="required">*</span></label
>
<div class="input-container">
	<input
		[type]="type"
		[formControlName]="controlName"
		[placeholder]="placeholder"
		class="bg-primary-light-1 form-control"
		[id]="id"
		[ngClass]="{
			'text-primary': isPrimaryText,
			'is-invalid': showsTextRequired
		}" />
	<i *ngIf="type === 'search'" class="ph-magnifying-glass text-primary"></i>
</div>
<div class="invalid-feedback" *ngIf="required && showsTextRequired">
	{{ textRequired }}
</div>
