import { NgModule } from '@angular/core';
import { CapitalizePipe } from '@utils/pipes/capitalize.pipe';
import { SafePipe } from '@utils/pipes/safe.pipe';
import { FormatDecimalPipe } from './format-decimal.pipe';

@NgModule({
	declarations: [CapitalizePipe, SafePipe, FormatDecimalPipe],
	imports: [],
	exports: [CapitalizePipe, SafePipe, FormatDecimalPipe],
})
export class PipesModule {}
