import { Component, Input } from '@angular/core';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { LatLngValue } from '@utils/interpolacao/interpolacao';
import { Unidade } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/variaveis';

@Component({
	selector: 'seira-popup-informacoes-pcd',
	templateUrl: './popup-informacoes-pcd.component.html',
	styleUrls: ['./popup-informacoes-pcd.component.scss'],
})
export class PopupInformacoesPcdComponent {
	@Input()
	markerData!: LatLngValue;
	@Input()
	unidade?: string;
	@Input()
	chuvas: any;

	formatarNumero(numero: number): string {
		if (isNotNuloOuUndefined(numero)) {
			return (
				numberToBrNumber(numero, 2) + (this.unidade ? ' ' + this.unidade : ' ')
			);
		} else {
			return ' ' + this.unidade;
		}
	}
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly Unidade = Unidade;
}
