import { getBase64ImageFromURL } from '@utils/index';
import * as pdfMake from 'pdfmake/build/pdfmake';
import {
	Content,
	PageOrientation,
	PageSize,
	StyleDictionary,
	TDocumentDefinitions,
} from 'pdfmake/interfaces';

export const generatePdf = (documentDefinition: TDocumentDefinitions) => {
	const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
	return pdfDocGenerator.open();
};

export const tituloTabela = 'tituloTabela';

const styles: StyleDictionary = {
	[tituloTabela]: {
		fontSize: 13.5,
		marginBottom: 5,
		marginTop: 15,
	},
};

function gerarTamanhoDoHeader(
	pageOrientation: PageOrientation,
	pageSize: PageSize
) {
	if (pageSize === 'A4') {
		if (pageOrientation === 'portrait') {
			return 555;
		}
		return 795;
	}
	if (pageSize === 'A3') {
		if (pageOrientation === 'portrait') {
			return 900;
		}
		return 1140;
	}
	return 300;
}

export const documentDefinitions = async (
	pageOrientation: PageOrientation = 'portrait',
	pageSize: PageSize = 'A4',
	content: Content = []
) => {
	const images = await preloadImages();

	const header: Content = [
		{
			columns: [
				{
					image: images.logo1,
					width: 80,
					height: 30,
					margin: [40, 10, 0, 0],
				},
				{
					text: 'Agência Executiva de Gestão das Águas do Estado da \nParaíba',
					alignment: 'center',
					margin: [0, 20, 0, 0],
				},
				{
					image: images.logo2,
					width: 90,
					height: 40,
					margin: [-40, 10, 0, 0],
				},
			],
		},
		{
			canvas: [
				{
					type: 'line',
					x1: 40,
					y1: 10,
					x2: gerarTamanhoDoHeader(pageOrientation, pageSize),
					y2: 10,
					lineWidth: 1,
					lineColor: 'black',
				},
			],
		},
	];

	return {
		pageMargins: [40, 90, 40, 40],
		pageSize,
		fontSize: 8,
		font: 'Roboto',
		layout: 'autowidth',
		pageOrientation,
		header,
		footer: function (currentPage: number, pageCount: number) {
			return [
				{
					canvas: [
						{
							type: 'line',
							x1: 40,
							y1: 0,
							x2: gerarTamanhoDoHeader(pageOrientation, pageSize),
							y2: 0,
							lineWidth: 1,
							lineColor: 'black',
						},
					],
				},
				{
					text:
						'AGÊNCIA EXECUTIVA DE GESTÃO DAS ÁGUAS DO ESTADO DA PARAÍBA - AESA\n' +
						'AV. Duarte da Silveira, S/N - Anexo ao DER, Torre - João Pessoa/PB, CEP:58013-280 - Contato: (83) 3225-5508',
					alignment: 'start',
					margin: [40, 10, 0, 0],
					fontSize: 8,
				},
				{
					text: `Pág. ${currentPage.toString()} de ${pageCount}`,
					alignment: 'right',
					margin: [0, 0, 30, 0],
					fontSize: 8,
				},
			];
		},
		content,
		styles,
	} as TDocumentDefinitions;
};

async function preloadImages() {
	const logo1 = await getBase64ImageFromURL('assets/images/logo_1_1.svg');
	const logo2 = await getBase64ImageFromURL('assets/images/logoParaiba.png');

	return { logo1, logo2 };
}
