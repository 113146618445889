<div class="row my-3" *ngIf="!loading && resultado">
	<div style="margin: 0; padding: 0">
		<div class="table-responsive">
			<seira-public-table
				#tabelaValoresExtremos
				[searching]="false"
				[info]="true"
				[paging]="true"
				[hasFooter]="true"
				[hasHeader]="false"
				[data]="data"
				[header]="colunas"
				[columns]="subColunas"
				[hasComplexHeader]="true"
				[headerColSpan]="4"
				[scrollX]="true"></seira-public-table>
		</div>
	</div>
</div>
