<div class="mt-10">
	<label
		for="legenda"
		class="text-muted fw-semibold my-1"
		*ngIf="valores && valores.length > 0">
		{{ mapaNome + (valores[0].unidade ? ' (' + valores[0].unidade + ')' : '') }}
	</label>
	<div
		class="bg-white rounded shadow-lg d-flex flex-column card"
		id="legenda"
		*ngIf="valores && valores.length > 0">
		<div class="card-body">
			<div class="legenda-horizontal">
				<div
					*ngIf="temInfoAdicional; else cores"
					class="d-flex justify-content-start w-100 mt-1"
					[ngClass]="{
						'wrap-itens': muitosItensLegenda,
						'no-wrap': !muitosItensLegenda
					}">
					<div *ngFor="let valor of valores; index as i" [style.width]="width">
						<seira-icon-with-label>
							<i
								icon
								[style.color]="valor.cor"
								[ngClass]="'shadow-item'"
								class="ph ph-circle-fill me-2"></i>
							<label label class="text-tertiary">{{
								valor.infoAdicional
							}}</label>
						</seira-icon-with-label>
					</div>
				</div>
				<ng-template #cores>
					<div
						*ngFor="let valor of valores; index as i"
						class="legenda-item shadow"
						[style]="borderRadius(i)"
						[style.backgroundColor]="valor.cor"
						[style.width]="width"></div
				></ng-template>
			</div>

			<div *ngIf="!temInfoAdicional">
				<div class="legenda-horizontal">
					<div
						*ngFor="let valor of valores; index as i"
						[class]="'legenda-item ' + (i === 0 ? 'legenda-centro' : '')"
						[ngClass]="temInfoAdicional ? 'ms-2' : ''"
						[style.width]="width">
						<div *ngIf="i === 0 && valor.unidade === '%'">
							≤ {{ valor.valorMin | formatDecimal: 1 }}
						</div>
						<div *ngIf="i === 0 && valor.unidade !== '%'">
							≤ {{ valor.valorMax | formatDecimal: 1 }}
						</div>
						<div *ngIf="i > 0 && i < valores.length - 1">
							{{ valor.valorMin | formatDecimal: 1 }}
						</div>
						<div *ngIf="i === valores.length - 1">
							≥ {{ valor.valorMin | formatDecimal: 1 }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
