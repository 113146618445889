import { Component } from '@angular/core';
import { legendaNDCInfo } from '@componentes/mapa-paraiba-svg/legenda';
import { enumAsSelectOptions } from '@utils';

@Component({
	selector: 'seira-legenda-ndc',
	templateUrl: './legenda-ndc.component.html',
	styleUrls: ['./legenda-ndc.component.scss'],
})
export class LegendaNdcComponent {
	legendaInfo = enumAsSelectOptions(legendaNDCInfo);

	constructor() {}

	getKeys() {
		return Object.keys(this.legendaInfo).map(Number);
	}

	getLegendas = () => {
		const legendas = [];
		for (const key in legendaNDCInfo) {
			if (legendaNDCInfo.hasOwnProperty(key)) {
				legendas.push({ valor: key, cor: legendaNDCInfo[key] });
			}
		}
		return legendas;
	};

	borderRadius(index: number) {
		const isFirst = index === 0;
		const isLast = index === this.getLegendas().length - 1;

		const topLeft = isFirst ? '10px' : '0';
		const topRight = isFirst ? '10px' : '0';
		const bottomRight = isLast ? '10px' : '0';
		const bottomLeft = isLast ? '10px' : '0';

		return `border-radius: ${topRight} ${bottomRight} ${bottomLeft} ${topLeft} ;`;
	}
}
