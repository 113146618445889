import { Component, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { TiposDatePicker } from '@shared/interfaces/date-picker';

@Component({
	selector: 'seira-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class DatePickerComponent implements OnInit {
	@Input() id!: string;
	@Input() datePickerType: TiposDatePicker = 'day';
	startView: 'month' | 'year' | 'multi-year' = 'month';
	@Input() label!: string;
	@Input() required = false;
	@Input() placeholder!: string;
	@Input() controlName!: string;
	@Input() removeLabel = false;
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() minDate: null | Date = null;
	@Input() maxDate: Date | null | undefined = new Date();
	control = new FormControl();
	parseDate = 'DD/MM/YYYY';

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		if (this.maxDate === undefined) {
			this.maxDate = new Date();
		}
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
		switch (this.datePickerType) {
			case 'year':
				this.startView = 'multi-year';
				this.parseDate = 'YYYY';
				break;
			case 'month':
				this.startView = 'year';
				break;
		}
	}
	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}

	get seiraError() {
		if (this.control.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				if (erros[0] === 'required') {
					if (this.control.touched) return 'Campo obrigatório.';
				} else {
					return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
	setYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		if (this.datePickerType === 'year') {
			const ctrlValue = this.control.value || moment();
			ctrlValue.year(normalizedMonthAndYear.year());
			this.control.setValue(ctrlValue);
			datepicker.close();
		}
	}

	setMonthAndYear(
		normalizedMonthAndYear: Moment,
		datepicker: MatDatepicker<Moment>
	) {
		if (this.datePickerType === 'month') {
			const ctrlValue = this.control.value || moment();
			ctrlValue.month(normalizedMonthAndYear.month());
			ctrlValue.year(normalizedMonthAndYear.year());
			this.control.setValue(ctrlValue);
			datepicker.close();
		}
	}
}
