export interface RespostaGraficoVariaveis {
	data: number[];
	precipitacao: number[];
	pressaoAtmosferica: number[];
	fluxoCalorSolo: number[];
	temperaturaAr: number[];
	temperaturaSolo: number[];
	temperaturaInstantanea: number[];
	temperaturaMaxima: number[];
	temperaturaMinima: number[];
	umidadeRelativa: number[];
	umidadeInstantanea: number[];
	umidadeMaxima: number[];
	umidadeMinima: number[];
	pontoDeOrvalhoInstantaneo: number[];
	pontoDeOrvalhoMaximo: number[];
	pontoDeOrvalhoMinimo: number[];
	pressaoInstantanea: number[];
	pressaoMaxima: number[];
	pressaoMinima: number[];
	ventoVelocidade: number[];
	ventoDirecao: number[];
	ventoRajada: number[];
	radiacao: number[];
	radiacaoGlobal: number[];
}
export class ConfiguracaoGrafico {
	constructor(
		readonly render: string,
		readonly titulo: string,
		readonly subTitulo: string,
		readonly descritivoDados: string,
		readonly configuracoesExtras: any
	) {}
}

export enum Variavel {
	PRECIPITACAO = 'Precipitação',
	UMIDADE = 'Umidade',
	TEMPERATURA = 'Temperatura',
	NEBULOSIDADE = 'Nebulosidade',
	PRESSAO = 'Pressão',
	VENTO = 'Vento',
	PRESSAO_ATMOSFERICA = 'Pressão Atmosférica',
	FLUXO_CALOR_SOLO = 'Fluxo de calor do solo',
	TEMPERATURA_AR = 'Temperatura do ar',
	TEMPERATURA_SOLO = 'Temperatura do solo',
	TEMPERATURA_INSTANTANEA = 'Temperatura instantânea',
	TEMPERATURA_MAXIMA = 'Temperatura Máxima',
	TEMPERATURA_MINIMA = 'Temperatura Mínima',
	UMIDADE_RELATIVA = 'Umidade relativa',
	UMIDADE_INSTANTANEA = 'Umidade instantânea',
	UMIDADE_MAXIMA = 'Umidade máxima',
	UMIDADE_MINIMA = 'Umidade mínima',
	PONTO_ORVALHO_INSTANTANEO = 'Ponto de orvalho instantâneo',
	PONTO_ORVALHO_MAXIMO = 'Ponto de orvalho máximo',
	PONTO_ORVALHO_MINIMO = 'Ponto de orvalho mínimo',
	PRESSAO_INSTANTANEA = 'Pressão instantânea',
	PRESSAO_MAXIMA = 'Pressão máxima',
	PRESSAO_MINIMA = 'Pressão mínima',
	RAJADA_VENTO = 'Rajada de vento',
	RADIACAO = 'Radiação',
	RADIACAO_GLOBAL = 'Radiação global',
}

export enum VariavelUnidade {
	PRECIPITACAO = '(mm)',
	UMIDADE = '(%)',
	TEMPERATURA = '(ºC)',
	NEBULOSIDADE = '(%)',
	PRESSAO = '(hPa)',
	VENTO = '(m/s)',
	PRESSAO_ATMOSFERICA = '(hPa)',
	FLUXO_CALOR_SOLO = '(mV)',
	TEMPERATURA_AR = '(ºC)',
	TEMPERATURA_SOLO = '(ºC)',
	TEMPERATURA_INSTANTANEA = '(ºC)',
	TEMPERATURA_MAXIMA = '(ºC)',
	TEMPERATURA_MINIMA = '(ºC)',
	UMIDADE_RELATIVA = '(%)',
	UMIDADE_INSTANTANEA = '(%)',
	UMIDADE_MAXIMA = '(%)',
	UMIDADE_MINIMA = '(%)',
	PONTO_ORVALHO_INSTANTANEO = '(ºC)',
	PONTO_ORVALHO_MAXIMO = '(ºC)',
	PONTO_ORVALHO_MINIMO = '(ºC)',
	PRESSAO_INSTANTANEA = '(hPa)',
	PRESSAO_MAXIMA = '(hPa)',
	PRESSAO_MINIMA = '(hPa)',
	RAJADA_VENTO = '(m/s)',
	RADIACAO = '(W/m²)',
	RADIACAO_GLOBAL = '(W/m²)',
}

export const VARIAVEIS_PREVISAO = [
	Variavel.PRECIPITACAO,
	Variavel.UMIDADE,
	Variavel.TEMPERATURA,
	Variavel.NEBULOSIDADE,
	Variavel.PRESSAO,
	Variavel.VENTO,
];

export const VARIAVEIS_HISTORICO = [
	// Variavel.FLUXO_CALOR_SOLO,
	Variavel.PRECIPITACAO,
	Variavel.PRESSAO_ATMOSFERICA,
	// Variavel.PONTO_ORVALHO_INSTANTANEO,
	// Variavel.PONTO_ORVALHO_MAXIMO,
	// Variavel.PONTO_ORVALHO_MINIMO,
	// Variavel.PRESSAO_INSTANTANEA,
	// Variavel.PRESSAO_MAXIMA,
	// Variavel.PRESSAO_MINIMA,
	// Variavel.RADIACAO,
	Variavel.RADIACAO_GLOBAL,
	// Variavel.RAJADA_VENTO,
	Variavel.TEMPERATURA_AR,
	// Variavel.TEMPERATURA_INSTANTANEA,
	Variavel.TEMPERATURA_MAXIMA,
	Variavel.TEMPERATURA_MINIMA,
	Variavel.TEMPERATURA_SOLO,
	Variavel.UMIDADE_INSTANTANEA,
	Variavel.UMIDADE_MAXIMA,
	Variavel.UMIDADE_MINIMA,
	// Variavel.UMIDADE_RELATIVA,
	Variavel.VENTO,
];

export enum TiposPeriodosValoresExtremos {
	MENSAL_COMPLETO = 'Mensal Completo',
	MENSAL_PARCIAL = 'Mensal Parcial',
	ANUAL_COMPLETO = 'Anual Completo',
	ANUAL_PARCIAL = 'Anual Parcial',
}
