<div class="row mt-3 mb-5" id="div-tabela-quantis">
	<seira-public-table
		id="tabela-quantis"
		[hasFooter]="false"
		[hasHeader]="false"
		[info]="false"
		[paging]="false"
		[searching]="false"
		[columns]="colunas!"
		[scrollX]="true"
		[data]="dados"
		[order]="[]"
		[dtOptions]="dtOptions" />
</div>
