import { Component, Input, OnInit } from '@angular/core';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { v4 as uuidv4 } from 'uuid';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';

@Component({
	selector: 'seira-modo-exibicao',
	templateUrl: './modo-exibicao.component.html',
	styleUrls: ['./modo-exibicao.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class ModoExibicaoComponent<T> implements OnInit {
	@Input() options: OptionRadio<T>[] = [];
	@Input() label = '';
	@Input() controlName!: string;
	@Input() idRadio = `radioGroup-${uuidv4()}`;
	@Input() labelPlacement: 'start' | 'top' = 'top';
	@Input() classRadio = 'flex-row d-flex gap-3 align-items-center';

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}
}
