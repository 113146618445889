import { Component, Input } from '@angular/core';
import {
	DadosRelatorioPluviometricoDiario,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	ADTSettings,
	ADTColumns,
} from 'angular-datatables/src/models/settings';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-relatorios-diarios',
	templateUrl: './tabela-relatorios-diarios.component.html',
	styleUrls: ['./tabela-relatorios-diarios.component.scss'],
})
export class TabelaRelatoriosDiariosComponent {
	@Input() relatorios: Relatorios<DadosRelatorioPluviometricoDiario>[] = [];
	dtOptions: ADTSettings = {};

	colunas: ADTColumns[] = [
		{ data: 'posto', title: 'Posto', type: 'string', className: 'text-center' },
		{
			data: 'mesAno',
			title: 'Mês/Ano',
			type: 'string',
			className: 'text-center',
		},
		{
			data: 'tipo',
			title: 'Tipo posto',
			type: 'string',
			className: 'text-center',
			render: (tipo: string) =>
				tipo === 'PLUVIOMETRO_CONVENCIONAL' ? 'convencional' : tipo,
		},
		...Array.from({ length: 31 }, (_, i) => ({
			data: `d${i + 1}`,
			title: `${String(i + 1).padStart(2, '0')}`,
			type: 'number',
			className: 'text-center',
			orderable: false,
			render: (precipitacao: number | null | undefined) =>
				isNotNuloOuUndefined(precipitacao)
					? numberToBrNumber(precipitacao, 1)
					: '-',
		})),
		{
			data: 'total',
			title: 'Total',
			type: 'number',
			className: 'text-center',
			orderable: false,
			render: (precipitacaoAcumulada: number | null | undefined) =>
				isNotNuloOuUndefined(precipitacaoAcumulada)
					? numberToBrNumber(precipitacaoAcumulada, 1)
					: '-',
		},
	];
}
