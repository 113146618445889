import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ValorPorGeocodigo } from '@componentes/mapa-paraiba-svg/mapa.interfaces';
import { Select } from '@layout/interfaces/select';
import {
	capitalizeFirstLetter,
	numberToBrNumber,
	obter_erro_request,
	verificaSePDF,
} from '@utils';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Estacao } from '../../../interfaces/filtros-opcoes';
import { LegendaItem } from '../../../interfaces/legenda-de-cores';
import { DadosMapaQuantis } from '../../../interfaces/mapa-quantis-mensal';
import {
	ChuvasPorMicrorregiao,
	PrecipitacaoPorMunicipio,
} from '../../../interfaces/quantis-anual';
import { PostosRelatorios } from '../../../interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS_QUANTIS,
	InstanciaRelatorio,
} from '../../../interfaces/tipos-relatorios';
import { GraficosPrecipitacaoService } from '@home/submodulos/dados-meteorologicos/services';
import { MapasQuantisAnualService } from '../../../services/mapas-quantis-anual.service';
import { RelatoriosService } from '../../../services';
import html2canvas from 'html2canvas';
import * as pdfseira from '@utils/pdf-seira';
import pdfMake from 'pdfmake/build/pdfmake';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';
import { municipioSvgPaths } from '@componentes/mapa-paraiba-svg/svg-paths';

@Component({
	selector: 'seira-mapa-quantis',
	templateUrl: './mapa-quantis.component.html',
	styleUrls: ['./mapa-quantis.component.scss'],
})
export class MapaQuantisComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	inputs = inject(INPUTS_RELATORIOS_QUANTIS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	chuvaPorMunicipio: ValorPorGeocodigo[] = [];
	legendaItems: LegendaItem[] = [];
	tituloQuantis = '';
	dadosMapaQuantis: DadosMapaQuantis[] = [];
	chuvasPorMicrorregiao: ChuvasPorMicrorregiao[] = [];
	fraseAnaliseChuvas = '';
	mapa = false;
	microrregiaoNome = '';
	loading = true;
	isDesktop = window.innerWidth > 1200;
	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como quantis mensais, os valores que dividem uma amostra de dados mensais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	descricaoRelatorioAnual =
		'Define-se como quantis anuais, os valores que dividem uma amostra de dados anuais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	relatorioService = inject(RelatoriosService);

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPdf();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	private subscription = new Subscription();

	constructor(
		private mapasQuantisAnualService: MapasQuantisAnualService,
		private graficosPrecipitacaoService: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		const tipo = this.inputs.form.get('tipo')?.value;

		this.descricaoRelatorio =
			tipo === 'QUANTIS_MENSAL'
				? this.descricaoRelatorioMensal
				: this.descricaoRelatorioAnual;
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}
		const periodo = this.inputs.form.get('periodo')?.value;
		const tipo = this.inputs.form.get('tipo')?.value;
		const microrregiao = Number(this.inputs.form.get('microrregiao')?.value);
		const microrregiaoSelect = this.microrregioes.find(
			value => Number(value.value) === microrregiao
		);
		this.microrregiaoNome = microrregiaoSelect ? microrregiaoSelect.name : '';
		let regioesIds: number[] = [];

		if (microrregiao === 0) {
			regioesIds = this.microrregioes
				.filter(micro => micro.value !== 'Estado completo')
				.map(micro => Number(micro.value));
		} else {
			regioesIds.push(Number(microrregiao));
		}

		this.tituloQuantis = `${this.getTipo()} - ${capitalizeFirstLetter(
			this.microrregiaoNome
		)} - ${DateTimeUtils.formatarDataPeriodo(
			periodo,
			'',
			tipo === 'QUANTIS_MENSAL' ? 'mensal' : 'anual',
			true
		)}`;

		this.loading = true;
		this.inputs.form.get(FormularioRelatorio.LOADING_GERAR)?.setValue(true);
		if (tipo === 'QUANTIS_MENSAL') {
			this.graficosPrecipitacaoService
				.buscarMapaQuantisMensal(regioesIds, null, periodo)
				.subscribe({
					next: resp => {
						this.relatorioService.verificaExistenciaDados(resp);
						this.dadosMapaQuantis = resp;
						this.chuvaPorMunicipio = [];
						if (Array.isArray(resp) && resp.length > 0) {
							resp.forEach((resp: DadosMapaQuantis) => {
								if (resp && resp.precipitacoesMunicipios) {
									resp.precipitacoesMunicipios.forEach(
										(precipitacaoPorMunicipio: PrecipitacaoPorMunicipio) => {
											this.chuvaPorMunicipio.push({
												valor: precipitacaoPorMunicipio.precipitacaoAcumulada,
												geocodigo: precipitacaoPorMunicipio.geocodigo,
											});
										}
									);
								}
							});
						}
						this.mapa = true;
						this.loading = false;
						this.inputs.form
							.get(FormularioRelatorio.LOADING_GERAR)
							?.setValue(false);
					},
					error: erro => {
						this.loading = false;
						this.inputs.form
							.get(FormularioRelatorio.LOADING_GERAR)
							?.setValue(false);
						const msg_erro = obter_erro_request(erro);
						this.toastr.error(
							msg_erro,
							'Erro ao obter informações do posto selecionado'
						);
					},
				});
		} else {
			this.mapasQuantisAnualService
				.buscarMapaQuantisAnual({
					regioesIds: regioesIds,
					diaInicio: periodo,
					diaFim: periodo,
				})
				.subscribe({
					next: resp => {
						this.relatorioService.verificaExistenciaDados(resp);
						this.chuvasPorMicrorregiao = resp;
						this.chuvaPorMunicipio = [];
						if (Array.isArray(resp) && resp.length > 0) {
							resp.forEach((resp: ChuvasPorMicrorregiao) => {
								if (resp && resp.precipitacoesMunicipios) {
									resp.precipitacoesMunicipios.forEach(
										(micro: PrecipitacaoPorMunicipio) => {
											this.chuvaPorMunicipio.push({
												valor: micro.precipitacaoAcumulada,
												geocodigo: micro.geocodigo,
											});
										}
									);
								}
							});
						}
						this.mapa = true;
						this.loading = false;
						this.inputs.form
							.get(FormularioRelatorio.LOADING_GERAR)
							?.setValue(false);
					},
					error: erro => {
						this.loading = false;
						this.inputs.form
							.get(FormularioRelatorio.LOADING_GERAR)
							?.setValue(false);
						const msg_erro = obter_erro_request(erro);
						this.toastr.error(
							msg_erro,
							'Erro ao obter informações do posto selecionado'
						);
					},
				});
		}
	}

	getTipo(): string {
		return this.inputs.form.get('tipo')?.value === 'QUANTIS_MENSAL'
			? 'Quantis mensal'
			: 'Quantis anual';
	}

	handleLegendaItems(legendaItems: LegendaItem[]) {
		this.legendaItems = legendaItems;
	}

	precipitacaoAcumuladaPorGeocodigo(geocodigo: string) {
		const precipitacao = this.chuvaPorMunicipio.find(
			municipio => municipio.geocodigo === +geocodigo
		)?.valor;

		return numberToBrNumber(precipitacao, 1);
	}

	getTituloLegenda() {
		const tipo = this.inputs.form.get('tipo')!.value;
		const unidadeMap = new Map([
			['QUANTIS_MENSAL', 'Quantis mensal'],
			['QUANTIS_ANUAL', 'Quantis anual'],
		]);
		return unidadeMap.get(tipo);
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];

		if (this.getTipo() === 'Quantis mensal') {
			const colunas = [
				{ text: 'Município', fillColor: '#DCDCDC' },
				{ text: 'Geocódigo', fillColor: '#DCDCDC' },
				{ text: 'Precipitação acumulada', fillColor: '#DCDCDC' },
				{ text: 'Mês', fillColor: '#DCDCDC' },
			];

			verificaSePDF(tableData, colunas, isPdf);

			this.dadosMapaQuantis.forEach((item: DadosMapaQuantis) => {
				item.precipitacoesMunicipios.forEach(precipitacaoMunicipio => {
					//TODO: refatorar para trazer do backend o nome do município
					const nomeMunicipio = municipioSvgPaths
						.find(
							el => el.geocodigo === precipitacaoMunicipio.geocodigo.toString()
						)
						?.name.replace(' - PB', '');
					const rowData = [
						nomeMunicipio,
						precipitacaoMunicipio.geocodigo,
						numberToBrNumber(precipitacaoMunicipio.precipitacaoAcumulada, 1),
						DateTimeUtils.getMesNumericoEAno(
							new Date(precipitacaoMunicipio.periodo)
						),
					];

					tableData.push(rowData);
				});
			});
		} else {
			const colunas = [
				{ text: 'Município', fillColor: '#DCDCDC' },
				{ text: 'Geocódigo', fillColor: '#DCDCDC' },
				{ text: 'Precipitação acumulada', fillColor: '#DCDCDC' },
				{ text: 'Ano', fillColor: '#DCDCDC' },
			];

			verificaSePDF(tableData, colunas, isPdf);

			this.chuvasPorMicrorregiao.forEach(chuvaPorMicrorregiao => {
				chuvaPorMicrorregiao.precipitacoesMunicipios.forEach(
					precipitacaoMunicipio => {
						//TODO: refatorar para trazer do backend o nome do município
						const nomeMunicipio = municipioSvgPaths
							.find(
								el =>
									el.geocodigo === precipitacaoMunicipio.geocodigo.toString()
							)
							?.name.replace(' - PB', '');
						const rowData = [
							nomeMunicipio,
							precipitacaoMunicipio.geocodigo,
							numberToBrNumber(precipitacaoMunicipio.precipitacaoAcumulada, 1),
							new Date(precipitacaoMunicipio.periodo).getFullYear(),
						];

						tableData.push(rowData);
					}
				);
			});
		}

		return tableData;
	}

	retornarQuantisParaXLSX() {
		const tableData: any[] = [];

		this.getTipo() === 'Quantis mensal'
			? this.dadosMapaQuantis.map(quantisMensal => {
					quantisMensal.precipitacoesMunicipios.forEach(
						precipitacoesMunicipios => {
							//TODO: refatorar para trazer do backend o nome do município
							const nomeMunicipio = municipioSvgPaths
								.find(
									el =>
										el.geocodigo ===
										precipitacoesMunicipios.geocodigo.toString()
								)
								?.name.replace(' - PB', '');
							tableData.push({
								municipio: nomeMunicipio,
								geocodigo: precipitacoesMunicipios.geocodigo,
								precipitacaoAcumulada: numberToBrNumber(
									precipitacoesMunicipios.precipitacaoAcumulada,
									1
								),
								periodo: DateTimeUtils.getMesNumericoEAno(
									new Date(precipitacoesMunicipios.periodo)
								),
							});
						}
					);
			  })
			: this.chuvasPorMicrorregiao.map(quantisAnual => {
					quantisAnual.precipitacoesMunicipios.forEach(
						precipitacoesMunicipios => {
							//TODO: refatorar para trazer do backend o nome do município
							const nomeMunicipio = municipioSvgPaths
								.find(
									el =>
										el.geocodigo ===
										precipitacoesMunicipios.geocodigo.toString()
								)
								?.name.replace(' - PB', '');
							tableData.push({
								municipio: nomeMunicipio,
								precipitacaoAcumulada: numberToBrNumber(
									precipitacoesMunicipios.precipitacaoAcumulada,
									1
								),
								geocodigo: precipitacoesMunicipios.geocodigo,
								periodo: DateTimeUtils.getAno(precipitacoesMunicipios.periodo),
							});
						}
					);
			  });

		return tableData;
	}

	async exportarPdf() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'portrait'
		);
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: this.tituloQuantis,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 400,
				height: 350,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(true);

		documentDefinition.content.push({
			table: {
				widths: ['25%', '25%', '25%', '25%'],
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(false);

		DocumentExporter.gerarCSV(tableData, this.tituloQuantis);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(txtData, this.tituloQuantis);
	}

	async exportarXLSX() {
		const nomeArquivo = this.tituloQuantis;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		let headers = [];
		let columns = [];

		if (this.getTipo() === 'Quantis mensal') {
			headers = [
				{
					label: 'municipio',
					text: 'Município',
					size: 24,
				},
				{
					label: 'geocodigo',
					text: 'Geocódigo',
					size: 24,
				},
				{
					label: 'precipitacaoAcumulada',
					text: 'Precipitação acumulada',
					size: 24,
				},
				{
					label: 'periodo',
					text: 'Mês',
					size: 24,
				},
			];

			columns = [
				{
					key: 'municipio',
					style: { numFmt: '0.0' },
				},
				{
					key: 'geocodigo',
					style: { numFmt: '0.0' },
				},
				{
					key: 'precipitacaoAcumulada',
					style: { numFmt: '0.0' },
				},
				{
					key: 'periodo',
				},
			];
		} else {
			headers = [
				{
					label: 'municipio',
					text: 'Município',
					size: 24,
				},
				{
					label: 'geocodigo',
					text: 'Geocódigo',
					size: 24,
				},
				{
					label: 'precipitacaoAcumulada',
					text: 'Precipitação acumulada',
					size: 24,
				},
				{
					label: 'periodo',
					text: 'Ano',
					size: 24,
				},
			];

			columns = [
				{
					key: 'municipio',
					style: { numFmt: '0.0' },
				},
				{
					key: 'geocodigo',
					style: { numFmt: '0.0' },
				},
				{
					key: 'precipitacaoAcumulada',
					style: { numFmt: '0.0' },
				},
				{
					key: 'periodo',
				},
			];
		}

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarQuantisParaXLSX(),
					columns: columns,
					title: {
						consommeRow: 3,
						consommeCol: 4,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		await ExcelTable.generateExcel(dataExcel);
	}
}
