export enum Formato {
	TABELA = 'Tabela',
	GRAFICO = 'Gráfico',
	MAPA = 'Mapa',
}

export enum FormatoVariaveisMeteorologicas {
	TABELA = 'Tabela',
	GRAFICO = 'Gráfico',
}

export enum TipoRelatorio {
	EVAPOTRANSPIRACAO = 'Evapotranspiração',
	VARIAVEL_METEOROLOGICA = 'Variável meteorológica',
	VALORES_EXTREMOS = 'Valores extremos',
}

export enum TipoGraficos {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	NUMERO_DIAS_COM_CHUVA = 'Número de dias com chuva (NDC)',
	CLIMATOLOGIA = 'Climatologia',
	DESVIO_MILIMETRO = 'Desvio (mm)',
	DESVIO_PORCENTAGEM = 'Desvio (%)',
	IAPM = 'IAPM',
	SPI = 'SPI',
	PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA = 'Observado/Desvio (%)/Climatologia',
	PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA = 'Observado/Desvio (mm)/Climatologia',
}

export enum TiposMapas {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	PLUVIOMETRIA_INTERPOLADA = 'Pluviometria interpolada',
	MOSAICO_CHUVAS = 'Mosaico de chuvas',
	NUMERO_DIAS_COM_CHUVA = 'Número de dias com chuva (NDC)',
	CLIMATOLOGIA = 'Climatologia',
	DESVIO_MILIMETRO = 'Desvio (mm)',
	DESVIO_PORCENTAGEM = 'Desvio (%)',
	IAPM = 'IAPM',
}

export enum TipoVariaveisMeteorologicas {
	PRECIPITACAO = 'Precipitação',
	PRESSAO_ATMOSFERICA = 'Pressão atmosférica',
	TEMPERATURA_DO_AR = 'Temperatura do ar',
	TEMPERATURA_DO_SOLO = 'Temperatura do solo',
	UMIDADE = 'Umidade',
	RADIACAO_Solar = 'Radiação solar',
	EVAPOTRANSPIRACAO = 'Evapotranspiração',
	TENSAOBATERIA = 'Tensão de bateria',
	TEMPERATURA_SOLO_10CM = 'Temperatura do solo a 10 cm',
	TEMPERATURA_SOLO_20CM = 'Temperatura do solo a 20 cm',
	TEMPERATURA_SOLO_30CM = 'Temperatura do solo a 30 cm',
	TEMPERATURA_SOLO_40CM = 'Temperatura do solo a 40 cm',
	TEMPERATURA_SOLO_50CM = 'Temperatura do solo a 50 cm',
	TEMPERATURA_SOLO_60CM = 'Temperatura do solo a 60 cm',
	CONTEUDO_AGUA_SOLO_10CM = 'Conteúdo de água no solo 10cm',
	CONTEUDO_AGUA_SOLO_20CM = 'Conteúdo de água no solo 20cm',
	CONTEUDO_AGUA_SOLO_30CM = 'Conteúdo de água no solo 30cm',
	CONTEUDO_AGUA_SOLO_40CM = 'Conteúdo de água no solo 40cm',
	CONTEUDO_AGUA_SOLO_50CM = 'Conteúdo de água no solo 50cm',
	CONTEUDO_AGUA_SOLO_60CM = 'Conteúdo de água no solo 60cm',
	UMIDADE_INTERNA = 'Umidade interna',
	UMIDADE_SOLO = 'Umidade do Solo',
	DIRECAO_VENTO = 'Direção do vento',
	VELOCIDADE_VENTO = 'Velocidade do Vento',
	TEMPERATURA_INTERNA = 'Temperatura interna',
	SENSOR_PORTA_ABERTA = 'Sensor de porta aberta',
	CORRENTE_RECARGA = 'Corrente de recarga',
	VENTO_2M = 'Vento 2m',
	DIRECAO_DO_VENTO = 'Direção do vento 2m',
	VENTO_VELOCIDADE_2_MAXIMA = 'Vento velocidade 2 máxima',
	VENTO_DIRECAO_2_DESVIO_PADRAO = 'Vento direção 2 desvio Padrão',
	VENTO_VELOCIDADE_1_MAXIMA = 'Vento velocidade 1 máxima',
	VENTO_DIRECAO_1_DESVIO_PADRAO = 'Vento direção 1 desvio Padrão ',
}

export enum TipoTabelas {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	NUMERO_DIAS_COM_CHUVA = 'Número de dias com chuva (NDC)',
	CLIMATOLOGIA = 'Climatologia',
	DESVIO = 'Desvios',
	IAPM = 'IAPM',
	SPI = 'SPI',
	HISTORICO_DA_SUDENE = 'Histórico da SUDENE',
	PRECIPITACAO_DIARIA = 'Precipitação diária',
	PRECIPITACAO_ANUAL = 'Precipitação anual',
	PRECIPITACAO_ACUMULADA = 'Precipitação acumulada',
	PLUVIOMETRICO_POR_POSTO = 'Pluviométrico por posto',
	PLUVIOMETRICO_DO_ESTADO = 'Pluviométrico do estado',
	VALORES_EXTREMOS_PLUVIOMETRIA = 'Valores Extremos de Chuva',
	CLIMATOLOGIA_DESVIO_PLUVIOMETRIA_OBSERVADA = 'Observado/Desvios/Climatologia',
}

export enum TipoTabelaMapaQuantis {
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
}

export enum TipoGraficoQuantis {
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	QUANTIS_PLUVIOMETRICOS_ANUAL = 'Quantis por região pluviométrica (anual)',
	QUANTIS_PLUVIOMETRICOS_REGIAO = 'Quantis por região pluviométrica (período chuvoso)',
}
