<div class="tooltip-borders mb-0 ms-0 me-0 border shadow-lg">
	<div
		class="d-flex align-items-center tooltip-header bg-primary text-white fw-semibold font-size-16 px-3 py-2">
		<i class="ph ph-map-pin fs-5 me-2"></i>
		{{ capitalizeFirstLetter(markerData.nomePosto.toLowerCase()) }}
	</div>
	<div class="tooltip-footer px-3 py-1 text-tertiary font-size-15 bg-white">
		<div class="d-flex col align-items-center">
			<i class="ph ph-broadcast fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Mesorregião:</span>
			</div>
			{{ capitalizeFirstLetter(markerData.mesorregiao.toLowerCase()) }}
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-push-pin fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">{{
					markerData.isPrecipitacao ? 'Precipitação:' : 'Desvio'
				}}</span>
				{{
					(markerData.value | formatDecimal: 1) +
						(markerData.isPrecipitacao ? ' mm' : ' %')
				}}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Latitude:</span>
				{{ markerData.lat.toFixed(7) }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Longitude:</span>
				{{ markerData.lng.toFixed(7) }}
			</div>
		</div>
	</div>
</div>
