import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { DesvioService } from '@services/desvio/desvio.service';
import {
	capitalizeFirstLetter,
	formataValorPrecipitacao,
	filterSelectOptions,
	obter_erro_request,
	numberToBrNumber,
} from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import {
	DadosDesvioRelatorio,
	IBaseRelatorioDesvio,
	RelatorioDesvio,
	RelatorioDesvioMunicipioDTO,
} from '../../interfaces/response';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../services/relatorios.service';
import { corrigeDuplicacaoNome, gerarFilenameGrafico } from '../../utils';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { Subject, takeUntil } from 'rxjs';
import { Agrupamento } from '../../submodulos/monitoramento/interfaces/estacao-monitorada';

interface OpcaoColuna {
	name: string;
	isFixed: boolean;
}

@Component({
	selector: 'seira-tabela-relatorio-desvio',
	templateUrl: './tabela-relatorio-desvio.component.html',
	styleUrls: ['./tabela-relatorio-desvio.component.scss'],
})
export class TabelaRelatorioDesvioComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como desvio (mm), a diferença entre a quantidade de chuva registrada e a sua climatologia para um mesmo período, em um determinado local ou região. Já o desvio (%) representa a variação da precipitação em relação à climatologia expresso em porcentagem, indicando se o acumulado de chuva foi superior ou inferior à climatologia.';
	relatoriosDTO: RelatorioDesvioMunicipioDTO[] = [];
	relatorios: RelatorioDesvio[] = [];
	taCarregando = true;
	private readonly inputs = inject(INPUTS_RELATORIOS);

	jaGerouAoMenosUmaVez = false;
	agrupamento: Select<string>[];

	isMunicipioPosto = false;
	opcoesColunas: OpcaoColuna[] = [
		{ name: 'Desvio (mm)', isFixed: true },
		{ name: 'Desvio (%)', isFixed: true },
		{ name: 'Data', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub Bacia', isFixed: false },
	];
	disabledSelectList = false;
	colunasSelecionadas: string[] = [];
	colunasDesvios: ADTColumns[];
	periodoTitulo = '';

	tituloTabela = '';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarSePossivel(() => this.exportarPDF()),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarSePossivel(() => this.exportarCSV()),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarSePossivel(() => this.exportarTXT()),
		},
	];

	private agrupamentoSelecionado: string;
	_destroyed = new Subject();

	constructor(
		private toast: ToastrService,
		private readonly relatorioService: RelatoriosService
	) {
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.BACIA,
			Agrupamento.MESORREGIAO,
			Agrupamento.MICRORREGIAO,
			Agrupamento.MUNICIPIO,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.clearValidators();
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	setColunasSelecionadas(colunasSelecionadas: string[]): void {
		this.colunasSelecionadas = colunasSelecionadas;
		this.gerarRelatorio();
	}

	exportarSePossivel(funcaoExportacao: () => void) {
		if (!this.jaGerouAoMenosUmaVez) {
			this.toast.info(
				`Não é possível gerar o arquivo para um relatório sem dados`,
				'Relatório sem dados'
			);
			return;
		}
		funcaoExportacao();
	}

	getTituloTabela(relatorio: IBaseRelatorioDesvio) {
		if (this.isMunicipioPosto || this.agrupamentoSelecionado === 'municipio') {
			return corrigeDuplicacaoNome(
				`${relatorio.municipio}/${relatorio.estacao}`
			);
		}
		return relatorio[
			this.agrupamentoSelecionado as keyof IBaseRelatorioDesvio
		] as string;
	}

	gerarRelatorio(): void {
		this.jaGerouAoMenosUmaVez = true;
		if (this.inputs.form.invalid) {
			return;
		}
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		const chave: keyof typeof FormularioRelatorio =
			agrupamento === 'MUNICIPIO_POSTO' ? 'ESTACAO' : agrupamento;

		if (!agrupamento || !dataInicio || !dataFim || !periodo || !chave) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodo
		);
		this.taCarregando = true;
		this.inputs.setLoading(true);

		this.agrupamentoSelecionado =
			agrupamento === 'REGIAO_PLUVIOMETRICA'
				? 'regiaoPluviometrica'
				: agrupamento.toLowerCase();

		this.isMunicipioPosto = agrupamento === 'MUNICIPIO_POSTO';

		const isSearchWithId =
			this.isMunicipioPosto ||
			agrupamento === 'MUNICIPIO' ||
			agrupamento === 'MICRORREGIAO';

		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento: agrupamento,
				idEntidade:
					chave && isSearchWithId
						? this.inputs.form.get(FormularioRelatorio[chave])?.value
						: null,
				dataFim: dataFim,
				dataInicio: dataInicio,
				periodo: periodo.toUpperCase(),
				incluirDetalhes: this.isMunicipioPosto,
			})
			.subscribe({
				next: relatorios => {
					if (relatorios.length === 0) {
						this.toast.info(
							'Não há desvios para os parametros selecionados',
							'Sem dados'
						);
					}
					relatorios.forEach(relatorio =>
						relatorio.desvios.forEach((desvio: DadosDesvioRelatorio) => {
							desvio.desvioMilimetro = formataValorPrecipitacao(
								desvio.desvioMilimetro
							);
							desvio.desvioPorcentagem = formataValorPrecipitacao(
								desvio.desvioPorcentagem
							);
						})
					);
					this.relatorios =
						this.tipoPeriodo === 'anual'
							? this.getRelatorioDesviosPorAno(relatorios)
							: relatorios;

					if (this.isMunicipioPosto) {
						this.relatoriosDTO =
							this.relatorios.flatMap<RelatorioDesvioMunicipioDTO>(relatorio =>
								relatorio.desvios.map(
									desvio => new RelatorioDesvioMunicipioDTO(relatorio, desvio)
								)
							);
					}

					this.atualizarColunasTabelaDesvios();
				},
				error: err => {
					this.inputs.setLoading(false);
					this.taCarregando = false;
					const msgErro = obter_erro_request(err);
					this.toast.error(
						msgErro,
						'Erro ao obter informações de desvio no servidor'
					);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.taCarregando = false;
				},
			});
	}

	async exportarPDF(): Promise<void> {
		let documentDefinition: TDocumentDefinitions;

		const content: Content[] = [
			{
				text: `Relatório - Desvios - ${this.periodoTitulo}`,
				fontSize: 12,
				alignment: 'center',
				margin: [0, 0],
			},
		];

		if (
			(this.relatoriosDTO.length === 0 && this.isMunicipioPosto) ||
			this.relatorios.length === 0
		) {
			content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 0],
			});
		}

		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			const tableData: (string | number)[][] = [];
			tableData.push([...this.colunasSelecionadas]);

			const dynamicColumns = [
				...new Set(
					this.relatoriosDTO.flatMap(dado =>
						Object.keys(dado).filter(key =>
							this.colunasDesvios.some(column => column.data === key)
						)
					)
				),
			];
			this.relatoriosDTO.forEach(dadoRelatorio => {
				const dynamicValues = Object.entries(dadoRelatorio)
					.map(([key, value]) => {
						if (dynamicColumns.includes(key)) {
							if (value === null || value === '') {
								return '-';
							}
							if (key !== 'desvioMilimetro' && key !== 'desvioPorcentagem') {
								if (!isNaN(value) && !(value instanceof Date)) {
									if (
										key !== 'latitude' &&
										key !== 'longitude' &&
										key !== 'altitude'
									) {
										return value.toFixed(1).replace('.', ',');
									}
									return Number(value).toString().replace('.', ',');
								}
								try {
									return DateTimeUtils.formatarDataPeriodoSemDia(
										value,
										'',
										periodo,
										true
									);
								} catch (error) {
									/* empty */
								}
							}
							return value;
						}
					})
					.filter(dados => dados !== undefined);

				tableData.push(dynamicValues);
			});
			documentDefinition = await pdfseira.documentDefinitions(
				'landscape',
				this.colunasSelecionadas.length > 8 ? 'A3' : 'A4'
			);

			content.push({
				table: {
					body: tableData,
				},
			});
		} else {
			this.relatorios.forEach(relatorio => {
				const tableData: (string | number)[][] = [];
				content.push({
					text: this.getTituloTabela(relatorio),
					style: [pdfseira.tituloTabela],
				});

				tableData.push(['Desvio (mm)', 'Desvio (%)', 'Data']);
				relatorio.desvios.forEach(desvio => {
					tableData.push([
						desvio.desvioMilimetro,
						desvio.desvioPorcentagem,
						DateTimeUtils.formatarDataPeriodoSemDia(
							desvio.data,
							'',
							periodo,
							true
						),
					]);
				});

				content.push({
					table: {
						body: tableData,
					},
				});
			});
			documentDefinition = await pdfseira.documentDefinitions('portrait');
		}

		documentDefinition.content = content;

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(): void {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			const tableData: (string | number)[][] = [];
			tableData.push([...this.colunasSelecionadas]);

			const dynamicColumns = [
				...new Set(
					this.relatoriosDTO.flatMap(dado =>
						Object.keys(dado).filter(key =>
							this.colunasDesvios.some(column => column.data === key)
						)
					)
				),
			];
			this.relatoriosDTO.forEach(dadoRelatorio => {
				const dynamicValues = Object.entries(dadoRelatorio)
					.map(([key, value]) => {
						if (dynamicColumns.includes(key)) {
							if (value === null || value === '') {
								return '-';
							}
							if (key !== 'desvioMilimetro' && key !== 'desvioPorcentagem') {
								if (!isNaN(value) && !(value instanceof Date)) {
									if (
										key !== 'latitude' &&
										key !== 'longitude' &&
										key !== 'altitude'
									) {
										return value.toFixed(1).replace('.', ',');
									}
									return Number(value).toString().replace('.', ',');
								}
								try {
									return DateTimeUtils.formatarDataPeriodoSemDia(
										value,
										'',
										periodo,
										true
									);
								} catch (error) {
									/* empty */
								}
							}
							return value;
						}
					})
					.filter(dados => dados !== undefined);

				tableData.push(dynamicValues);
			});
			DocumentExporter.gerarCSV(
				tableData,
				gerarFilenameGrafico(
					`desvio_${agrupamento.toLocaleLowerCase()}_${periodo.toLocaleLowerCase()}`
				)
			);
		} else {
			const opcaoAgrupamento = this.inputs.form.get(
				FormularioRelatorio.AGRUPAMENTO
			)?.value;

			const agrupamentoAsTitle =
				opcaoAgrupamento === 'REGIAO_PLUVIOMETRICA'
					? 'Região Pluviometrica'
					: capitalizeFirstLetter(opcaoAgrupamento.toLowerCase());

			const tableData: (string | number)[][] = [];

			tableData.push(['Desvio (mm)', 'Desvio (%)', 'Data', agrupamentoAsTitle]);
			this.relatorios.forEach(relatorio => {
				relatorio.desvios.forEach(desvio => {
					tableData.push([
						desvio.desvioMilimetro,
						desvio.desvioPorcentagem,
						DateTimeUtils.formatarDataPeriodoSemDia(
							desvio.data,
							'',
							periodo,
							true
						),
						relatorio[
							this.agrupamentoSelecionado as keyof RelatorioDesvio
						] as string,
					]);
				});
			});

			DocumentExporter.gerarCSV(
				tableData,
				gerarFilenameGrafico(
					`desvio_${agrupamento.toLocaleLowerCase()}_${periodo.toLocaleLowerCase()}`
				)
			);
		}
	}

	exportarTXT(): void {
		let txtData = '';

		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.relatoriosDTO.forEach(relatorio => {
				txtData += this.colunasDesvios
					.flatMap(coluna => {
						const foundedData = Object.entries(relatorio)
							.map(([key, value]) => {
								if (key === coluna.data) {
									if (value === null || value === '') {
										return `${coluna.title}: - \n`;
									}
									if (
										key !== 'desvioMilimetro' &&
										key !== 'desvioPorcentagem'
									) {
										if (!isNaN(value) && !(value instanceof Date)) {
											if (
												key !== 'latitude' &&
												key !== 'longitude' &&
												key !== 'altitude'
											) {
												return `${coluna.title}: ${value
													.toFixed(1)
													.replace('.', ',')} \n`;
											}
											return `${coluna.title}: ${Number(value)
												.toString()
												.replace('.', ',')} \n`;
										}
										try {
											return `${
												coluna.title
											}: ${DateTimeUtils.formatarDataPeriodoSemDia(
												value,
												'',
												periodo,
												true
											)} \n`;
										} catch (error) {
											/* empty */
										}
									}

									return `${coluna.title}: ${value === '' ? '-' : value} \n`;
								}
								return '';
							})
							.filter(value => value !== undefined && value !== '');
						return foundedData;
					})
					.reduce((prev, curr) => prev + curr);
				txtData += '\n\n';
			});
		} else {
			const opcaoAgrupamento = this.inputs.form.get(
				FormularioRelatorio.AGRUPAMENTO
			)?.value;

			const agrupamentoAsTitle =
				opcaoAgrupamento === 'REGIAO_PLUVIOMETRICA'
					? 'Região Pluviometrica'
					: capitalizeFirstLetter(opcaoAgrupamento);

			this.relatorios.forEach(relatorio => {
				relatorio.desvios.forEach(desvio => {
					txtData +=
						`Desvio (mm): ${desvio.desvioMilimetro}\n` +
						`Desvio (%): ${desvio.desvioPorcentagem}\n` +
						`Data: ${DateTimeUtils.formatarDataPeriodoSemDia(
							desvio.data,
							'',
							periodo,
							true
						)}\n` +
						`${agrupamentoAsTitle}: ${
							relatorio[
								this.agrupamentoSelecionado as keyof RelatorioDesvio
							] as string
						}\n\n`;
				});
				txtData += '\n\n';
			});
		}
		DocumentExporter.gerarTXT(
			txtData,
			gerarFilenameGrafico(
				`desvio_${agrupamento.toLocaleLowerCase()}_${periodo.toLocaleLowerCase()}`
			)
		);
	}

	private getRelatorioDesviosPorAno(
		relatorios: RelatorioDesvio[]
	): RelatorioDesvio[] {
		const retorno = relatorios.map(r => {
			const anos = new Set(r.desvios.map(d => DateTimeUtils.getAnoUTC(d.data)));
			const desvios: DadosDesvioRelatorio[] = [];
			anos.forEach(ano => {
				const desviosDoAno = r.desvios.filter(
					d => DateTimeUtils.getAno(d.data) === ano
				);
				const objChuvaEClimaSomados = desviosDoAno.reduce<DadosDesvioRelatorio>(
					(a, b) => ({
						desvioMilimetro: 0,
						chuva: a.chuva + b.chuva,
						desvioPorcentagem: 0,
						climatologia: a.climatologia + b.climatologia,
						data: new Date(DateTimeUtils.getAno(b.data), 0, 1),
					}),
					{
						desvioMilimetro: 0,
						chuva: 0,
						desvioPorcentagem: 0,
						climatologia: 0,
						data: new Date(),
					}
				);
				const { length } = desviosDoAno;
				const { chuva, climatologia, data } = objChuvaEClimaSomados;
				const chuvaMedia = chuva / length;
				const climatologiaMedia = climatologia / length;
				const desvioMedioAno = {
					chuva: chuva / length,
					climatologia: chuva / length,
					data: data,
					desvioMilimetro: DesvioService.calcularDesvioMilimetro(
						chuvaMedia,
						climatologiaMedia
					),
					desvioPorcentagem: DesvioService.calcularDesvioPorcentagem(
						chuvaMedia,
						climatologiaMedia
					),
				};
				desvios.push(desvioMedioAno);
			});
			const relatorio: RelatorioDesvio = {
				...r,
				desvios,
			};
			return relatorio;
		});
		return retorno;
	}

	private get tipoPeriodo(): string {
		return this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
	}

	private atualizarColunasTabelaDesvios() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		this.colunasDesvios = [
			{
				data: 'desvioMilimetro',
				title: 'Desvio (mm)',
				type: 'number',
				className: 'text-center',
				render: (value: any) => value,
			},
			{
				data: 'desvioPorcentagem',
				title: 'Desvio (%)',
				type: 'number',
				className: 'text-center',
				render: (value: any) => value,
			},
			{
				data: 'data',
				title: 'Data',
				type: 'number',
				className: 'text-center',
				render: (value: string) =>
					DateTimeUtils.formatarDataPeriodoSemDia(value, '', periodo, true) ??
					'-',
			},
		];

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;

			const todasColunasDisponiveis = [
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},
				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},
				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (data: any) => (data ? numberToBrNumber(data, 1) : '-'),
				},
				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'regiaoPluviometrica',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (regiao: string) => (regiao !== '' ? regiao : '-'),
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (bacia: string) => (bacia !== '' ? bacia : '-'),
				},
				{
					data: 'subBacia',
					title: 'Sub Bacia',
					type: 'string',
					className: 'text-center',
					render: (bacia: string) => (bacia !== null ? bacia : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunasDesvios.push(...colunasParaExibir);
		} else {
			this.disabledSelectList = true;
		}
	}

	private clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}
}
