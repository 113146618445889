import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';

import { ExecucaoModeloClima } from '@home/submodulos/previsao-climatica/interfaces/previsaoClimatica';

@Injectable({
	providedIn: 'root',
})
export class ClimaService {
	constructor(private http: HttpClientProtectedService) {}

	listExecucoes(): Observable<ExecucaoModeloClima[]> {
		return this.http.get<ExecucaoModeloClima[]>(Rotas.CLIMA + '/execucoes');
	}

	listPrevisaoClimatica(idExecucao: number): Observable<any> {
		return this.http.get<any>(Rotas.CLIMA + '/previsao', {
			idExecucao,
		});
	}
}
