import { Component, EventEmitter, inject, OnDestroy } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import * as Highcharts from 'highcharts/highstock';

import { Validators } from '@angular/forms';
import {
	optionsChartClimatologia,
	relatorioClimatologia,
} from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-climatologia/chart-options';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import {
	Agrupamento,
	Meses,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { corrigeDuplicacaoNome, gerarFilenameGrafico } from '../../../utils';
import {
	capitalizeFirstLetter,
	compararStrings,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import { Chart } from 'highcharts';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as pdfseira from '@utils/pdf-seira';
import pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import moment from 'moment/moment';
import html2canvas from 'html2canvas';

@Component({
	selector: 'seira-climatologia-grafico',
	templateUrl: './grafico-climatologia.component.html',
	styleUrls: ['./grafico-climatologia.component.scss'],
})
export class GraficoClimatologiaComponent
	implements OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	chart?: Highcharts.Chart;
	resultado: ClimatologiaResponseDTO[] = [];
	loading = true;
	gerou = false;
	private subscription = new Subscription();
	_destroyed = new Subject();
	tipoAgrupamento: Agrupamento = Agrupamento.MUNICIPIO_POSTO;
	descricaoRelatorio =
		'Define-se como climatologia pluviométrica, a média histórica de pluviometria para períodos longos, preferencialmente de 30 anos.';

	height = '190px';
	chartInstance = new EventEmitter<Chart>();
	chartOptions = optionsChartClimatologia();

	series: Array<Partial<Highcharts.SeriesOptionsType> & Record<string, any>> =
		relatorioClimatologia;

	periodoTitulo = '';

	tituloAgrupamento = '';
	agrpamentoSelecionado = '';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPdf();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	mesesDoAno: Record<string, number> = {
		janeiro: 1,
		fevereiro: 2,
		marco: 3,
		abril: 4,
		maio: 5,
		junho: 6,
		julho: 7,
		agosto: 8,
		setembro: 9,
		outubro: 10,
		novembro: 11,
		dezembro: 12,
	};

	agrupamentoSelecionado = '';

	constructor(private climatologiaService: RelatoriosService) {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.MES_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MES_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();

		this.subscription.unsubscribe();
		this._destroyed.complete();
		this.chartInstance.complete();
		this.resetarGrafico();
	}

	resetarGrafico() {
		if (this.chart) {
			while (this.chart.series?.length) {
				this.chart.series[0].remove(false);
			}
			this.chart = undefined;
		}
		this.series = [...relatorioClimatologia];
		this.chartOptions = optionsChartClimatologia();
	}

	retornarMesInicioMesFinalFormatado() {
		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return;
		}

		const mesInicioFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesInicio)
		);
		const mesFimFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesFim)
		);
		this.periodoTitulo = ` ${mesInicioFormatado} a ${mesFimFormatado}`;
	}

	gerarRelatorio(): void {
		if (this.inputs.form.invalid) {
			return;
		}

		this.resetarGrafico();

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);
		const idMunicipio = this.inputs.form.get('municipio')?.value;
		const idMicrorregiao = this.inputs.form.get('microrregiao')?.value;
		const idPosto = this.inputs.form.get('estacao')?.value;

		this.retornarMesInicioMesFinalFormatado();

		this.tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;

		this.climatologiaService
			.buscarClimatologiaPorMunicipio(
				mesInicio.toLowerCase(),
				mesFim.toLowerCase(),
				this.tipoAgrupamento,
				idMunicipio,
				idPosto,
				idMicrorregiao
			)
			.subscribe({
				next: climatologias => {
					this.climatologiaService.verificaExistenciaDados(climatologias);
					this.resultado = climatologias;

					this.resultado = this.ordenarPorMes(this.resultado);

					this.chartOptions = {
						...this.chartOptions,
						exporting: {
							enabled: true,
							buttons: {
								contextButton: {
									menuItems: [
										'viewFullscreen',
										'separator',
										'downloadPNG',
										'downloadJPEG',
									],
								},
							},
							filename: gerarFilenameGrafico(
								`grafico_climatologia_${this.inputs.form
									.get(FormularioRelatorio.AGRUPAMENTO)
									?.value.toLocaleLowerCase()}`
							),
						},
					};
				},
				error: () => {
					this.inputs.setLoading(false);
					this.loading = false;
					this.gerou = false;
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.loading = false;
					this.gerou = true;
				},
			});
	}

	onChartCreated(chart: Chart) {
		if (this.resultado) {
			this.loading = false;
			this.chart = chart;
			this.chartInstance.emit(chart);

			this.setSeries();
		}
	}

	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.ESTACAO);

		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.lidarComAgrupamento();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: () => {
					this.lidarComAgrupamento();
				},
			});
		this.inputs.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setSeries() {
		this.chart?.series.forEach(el => el.remove());
		this.series?.map(serie => {
			serie.data = [];
			return serie;
		});

		this.resultado = this.ordenarPorMes(this.resultado);
		const meses: string[] = this.extrairMeses();
		const agrupamentoUnicoGrafico = [
			'MUNICIPIO',
			'MUNICIPIO_POSTO',
			'MICRORREGIAO',
		];

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		const mesInicioFormatado = this.verificaMes(mesInicio).toLowerCase();
		const mesFimFormatado = this.verificaMes(mesFim).toLowerCase();

		const periodoExibido =
			mesInicioFormatado === mesFimFormatado
				? capitalizeFirstLetter(mesInicioFormatado)
				: `${capitalizeFirstLetter(
						mesInicioFormatado
				  )} a ${capitalizeFirstLetter(mesFimFormatado)}`;

		if (agrupamentoUnicoGrafico.includes(<Agrupamento>this.tipoAgrupamento)) {
			const nomeAgrupamento = this.resultado[0]?.nome
				? this.resultado[0]?.nome
				: '-';
			const nomeMunicipio =
				this.tipoAgrupamento === agrupamentoUnicoGrafico[1] && this.resultado[0]
					? this.resultado[0]?.dadosPosto?.municipio
					: '';

			const nomeFinal =
				this.tipoAgrupamento.toString() === 'MUNICIPIO_POSTO'
					? corrigeDuplicacaoNome(`${nomeMunicipio}/${nomeAgrupamento}`)
					: nomeAgrupamento;

			this.chart?.setTitle({
				text: `Climatologia de ${nomeFinal} - ${periodoExibido}`,
				align: 'center',
			});

			this.resultado.forEach(value => {
				this.series[0].data.push({
					y: Number.parseFloat(value?.climatologia.toFixed(1)),
					name: capitalizeFirstLetter(this.verificaMes(value.mes)),
				});
			});

			this.chart!.xAxis[0].setCategories(meses);
			this.series.forEach(el => {
				this.chart?.addSeries(el as any);
			});
		} else {
			this.configurarChart(this.resultado);
		}
	}
	configurarChart(preciptacoesAgrupadas: ClimatologiaResponseDTO[]) {
		const categorias = this.extrairNomes(preciptacoesAgrupadas);

		const meses: string[] = this.extrairMeses();

		this.chart?.xAxis[0].setCategories(meses);
		this.chart?.setTitle({
			text: `Gráfico de Climatologia`,
			align: 'center',
		});
		this.series = categorias.map(res => {
			return {
				name: res,
				data: meses.map(mes => {
					const entry = this.resultado.find(
						item =>
							item.nome === res &&
							capitalizeFirstLetter(this.verificaMes(item.mes)) === mes
					);

					return entry
						? {
								name: this.verificaMes(entry.mes),
								y: Number.parseFloat(entry?.climatologia.toFixed(1)),
						  }
						: null;
				}),
			};
		});

		this.series.forEach(el => {
			this.chart?.addSeries(el as any);
		});
	}
	verificaMes(mes: string) {
		return mes === 'marco' ? 'Março' : capitalizeFirstLetter(mes);
	}
	extrairNomes(preciptacoesAgrupadas: ClimatologiaResponseDTO[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome))];
	}

	extrairMeses() {
		return [
			...new Set(
				this.resultado.map((item: { mes: string }) => {
					if (item.mes === 'marco') {
						return capitalizeFirstLetter('março');
					} else {
						return capitalizeFirstLetter(item.mes);
					}
				})
			),
		];
	}

	ordenarPorMes(
		resultados: ClimatologiaResponseDTO[]
	): ClimatologiaResponseDTO[] {
		return resultados.sort((a, b) => {
			const indiceA = this.mesesDoAno[a.mes.toLowerCase()];
			const indiceB = this.mesesDoAno[b.mes.toLowerCase()];
			return indiceA - indiceB;
		});
	}

	getDataFormatada(data: Date): string {
		return moment(data).format('DD/MM/YYYY');
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('grafico-climatologia');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	getDadosTabelaParaExportacao(
		dados: ClimatologiaResponseDTO[],
		isPdf: boolean
	) {
		const tableData: any[][] = [];
		const colunas: any[] = [];
		const agrupamento = this.inputs.form.get('agrupamento')?.value;

		colunas.push(
			{
				text: Agrupamento[agrupamento as keyof typeof Agrupamento],
				fillColor: '#DCDCDC',
			},
			{ text: 'Mês', fillColor: '#DCDCDC' },
			{ text: 'Climatologia (mm)', fillColor: '#DCDCDC' }
		);

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: ClimatologiaResponseDTO) => {
			const rowData = [];

			rowData.push(
				item.nome,
				capitalizeFirstLetter(item.mes),
				numberToBrNumber(item.climatologia, 1)
			);

			tableData.push(rowData);
		});

		return tableData;
	}

	retornarClimatologiaParaXLSX() {
		const tableData: any[] = [];

		this.resultado.map(({ nome, mes, climatologia }) => {
			tableData.push({
				nome: nome,
				mes: capitalizeFirstLetter(mes),
				climatologia: numberToBrNumber(climatologia, 1),
			});
		});

		return tableData;
	}

	async exportarPdf() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Climatologia (mm) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 600,
				height: 400,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.resultado, true);

		documentDefinition.content.push(
			{
				text: '',
				alignment: 'center',
				fontSize: 10,
			},
			{
				table: {
					widths: ['*', '*', '*'],
					body: tableData,
				},
			}
		);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.resultado, false);

		DocumentExporter.gerarCSV(
			tableData,
			`Climatologia (mm) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.resultado, false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Climatologia (mm) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		const nomeArquivo = `Climatologia (mm) - ${this.periodoTitulo}`;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};
		const headers = [
			{
				label: 'nome',
				text:
					agrupamento === 'MUNICIPIO_POSTO'
						? 'Município/posto'
						: agrupamento === 'MUNICIPIO'
						? 'Município'
						: agrupamento === 'MICRORREGIAO'
						? 'Microrregião'
						: agrupamento === 'MESORREGIAO'
						? 'Mesorregião'
						: agrupamento === 'BACIA'
						? 'Bacia'
						: agrupamento === 'REGIAO_PLUVIOMETRICA'
						? 'Região pluviométrica'
						: 'Sub-bacia',
				size: 24,
			},
			{
				label: 'mes',
				text: 'Mês',
				size: 48,
			},
			{ label: 'climatologia', text: 'Climatologia (mm)', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarClimatologiaParaXLSX(),
					columns: [
						{ key: 'mes' },
						{
							key: 'climatologia',
							style: { numFmt: '0.0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 2,
						text: nomeArquivo,
						styleId: 'title',
						alignment: 'center',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
