<div
	[@expand]="currentState"
	(mouseenter)="expand()"
	(mouseleave)="expand()"
	class="shadow p-1 bg-white"
	[style.position]="'relative'"
	[ngClass]="currentState">
	<div
		*ngIf="loading"
		style="height: 100%; width: 100%"
		class="d-flex align-items-center justify-content-center">
		<div
			class="spinner-border text-primary"
			style="width: 4rem; height: 4rem"
			role="status">
			<span class="visually-hidden">Carregando mapa...</span>
		</div>
	</div>
	<seira-mapa-paraiba-svg
		[id]="idMapa"
		(legendaItems)="mapaFoiGerado()"
		[asImage]="true"
		[customSetColorByValue]="funcaoCoresMapa"
		[atributosMunicipio]="imagem.historico"
		tipoMapa="municipios">
		<div class="date-container">
			<div *ngIf="imagem.historico[0].periodo === 'ANUAL'; else checkDiario">
				{{ imagem.data | date: 'yyyy' }}
			</div>
			<ng-template #checkDiario>
				<div *ngIf="imagem.historico[0].periodo === 'DIARIO'; else mensal">
					{{ imagem.data | date: 'dd/MM/yyyy' }}
				</div>
			</ng-template>
			<ng-template #mensal>
				<div>
					{{ imagem.data | date: 'MM/yyyy' }}
				</div>
			</ng-template>
		</div>
	</seira-mapa-paraiba-svg>
	<div
		style="position: absolute; left: 0; top: 100%"
		*ngIf="currentState === 'expanded'"
		class="w-100 bg-primary-light-2 rounded-bottom py-1 font-size-12 text-center shadow">
		<seira-public-a (click)="abrirModal()">Mais informações</seira-public-a>
	</div>
</div>
