import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
	FormularioRelatorio,
	IapmDTO,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '../../../interfaces';
import { GraficosPrecipitacaoService } from '../../../services';
import { Validators } from '@angular/forms';
import { Select } from '@layout/interfaces/select';
import { Subject, takeUntil } from 'rxjs';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import { DateTimeUtils } from '@utils/datetime-util';
import { formatISO } from 'date-fns';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	PERIODO_MAXIMO_IAPM_EM_ANOS,
	PERIODO_MAXIMO_IAPM_EM_MESES,
} from '../pluviometria-grafico-iapm/pluviometria-grafico-iapm.component';
import moment from 'moment';
import { filterSelectOptions } from '@utils';

@Component({
	selector: 'seira-pluviometria-tabela-iapm',
	templateUrl: './pluviometria-tabela-iapm.component.html',
	styleUrls: ['./pluviometria-tabela-iapm.component.scss'],
})
export class PluviometriaTabelaIapmComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	isLoading = false;
	tableData: IapmDTO[];
	titleTable = '';
	titlePeriodo = '';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];
	agrupamento: Select<string>[];

	colunas: ADTColumns[] = [
		{
			data: 'iapm',
			title: 'IAPM (mm)',
			className: 'text-center',
			render: value => Number(value).toFixed(1).replace('.', ','),
		},
		{
			data: 'periodo',
			title: 'Data',
			className: 'text-center',
			orderable: false,
		},
	];

	descricaoRelatorio =
		'Define-se como IAPM, Índice de Anomalia de Precipitação Modificado, a anomalia da pluviometria mensal em comparação com a média histórica, utilizado para identificar padrões fora do comum em determinado local ou região.';

	private _destroyed = new Subject();

	constructor(
		private readonly service: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.BACIA,
			Agrupamento.MESORREGIAO,
			Agrupamento.MICRORREGIAO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setDatasByPeriodoBusca(
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.BACIA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MESORREGIAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.clearValidators();
		this._destroyed.next(undefined);
	}

	gerarRelatorio() {
		if (this.inputs.form?.invalid) {
			return;
		}

		const agrupamento = this.inputs.form?.get('agrupamento')?.value;
		const periodoBusca = this.inputs.form?.get('periodoBusca')?.value;
		const dataInicio = this.inputs.form?.get('dataInicio')?.value;
		const dataFim = this.inputs.form?.get('dataFim')?.value;

		let id = 0;

		switch (agrupamento) {
			case 'MICRORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MICRORREGIAO)?.value;
				break;
			case 'MESORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MESORREGIAO)?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.getDataFromForm(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.value;
				break;
			case 'BACIA':
				id = this.getDataFromForm(FormularioRelatorio.BACIA)?.value;
				break;
		}

		if (id == 0 || !agrupamento || !periodoBusca || !dataInicio || !dataFim) {
			return;
		}

		this.titlePeriodo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		);
		this.handleFetchIAPM(
			id,
			agrupamento,
			this.handleFormatPeriodoBusca(periodoBusca),
			formatISO(new Date(dataInicio)),
			formatISO(new Date(dataFim))
		);
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Índice de anomalia de precipitação modificado (IAPM) - ${this.titlePeriodo}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.tableData) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (string | number)[][] = [];
		tableData.push(['IAPM (mm)', 'Data']);

		this.tableData.forEach(dado => {
			tableData.push([dado.iapm.toFixed(2).replace('.', ','), dado.periodo]);
		});
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData: (string | number)[][] = [];
		tableData.push(['IAPM (mm)', 'Data']);

		this.tableData.forEach(dado => {
			tableData.push([dado.iapm.toFixed(2).replace('.', ','), dado.periodo]);
		});
		DocumentExporter.gerarCSV(
			tableData,
			`iapm_${this.titleTable.toLowerCase()}_${this.titlePeriodo.toLowerCase()}`
		);
	}

	exportarTXT() {
		let txtData = '';

		txtData += `Nome: ${this.tableData[0].nome}\n\n`;

		this.tableData.forEach(dado => {
			txtData +=
				`IAPM (mm): ${dado.iapm.toFixed(2).replace('.', ',')}\n` +
				`Data: ${dado.periodo}\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`iapm_${this.titleTable.toLowerCase()}_${this.titlePeriodo.toLowerCase()}`
		);
	}

	setDatasByPeriodoBusca(periodoBuscaValue: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();

			switch (periodoBuscaValue) {
				case 'anual':
					dataFim.setValue(moment(hoje).subtract(1, 'year').endOf('year'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_ANOS, 'year')
							.startOf('year')
					);
					break;
				case 'mensal':
					dataFim.setValue(moment(hoje).subtract(1, 'month').startOf('month'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_MESES, 'month')
							.endOf('month')
					);
					break;
			}
		}
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MESORREGIAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);

		switch (agrupamentoValue) {
			case 'BACIA':
				this.inputs.form
					?.get(FormularioRelatorio.BACIA)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MESORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MESORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					?.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: (periodo: PeriodosLabel | null) => {
					this.setDatasByPeriodoBusca(periodo);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	private handleFetchIAPM(
		id: number,
		agrupamento: string,
		periodo: string,
		dataInicial: string,
		dataFinal: string
	) {
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.service
			.gerarGraficoIAPM(id, agrupamento, periodo, dataInicial, dataFinal)
			.subscribe({
				next: values => {
					this.tableData =
						periodo === 'MENSAL'
							? values.map(value => ({
									...value,
									periodo: value.periodo.padStart(7, '0'),
							  }))
							: values;
					this.titleTable = values[0].nome.toUpperCase();
				},
				error: err => {
					this.toastr.error('Erro ao buscar as informações');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form?.get(data);
	}

	private handleFormatPeriodoBusca(periodo: string) {
		if (periodo === 'anual') return 'ANUAL';
		if (periodo === 'mensal') return 'MENSAL';
		return 'PERIODO';
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}
}
