import { Moment } from 'moment';
import { QuantisCategoriasType } from '../types';

export interface BuscaQuantis {
	postoId: number;
	diaInicio?: Moment;
	diaFim: Moment;
}

export interface DadosGraficoQuantisMensal {
	tituloGeral: string;
	fileName?: string;
	legenda: string;
	dadosGraficosQuantis: DadoGraficoQuantis[];
}

export interface DadoGraficoQuantis {
	titulo?: string;
	categorias: QuantisDataHighcharts[];
}

export interface QuantisDataHighcharts {
	from: number;
	to: number;
	color: string;
	borderWidth: number;
	borderColor: string;
	categoria: QuantisCategoriasType;
}

export const QuantisCategoriasMapColors = new Map<
	QuantisCategoriasType,
	string
>([
	['Muito Chuvoso', '#035cf8'],
	['Chuvoso', '#407dec'],
	['Normal', '#22ee03'],
	['Seco', '#ffea00'],
	['Muito Seco', '#ec0808'],
	['Observado', '#000000'],
]);
