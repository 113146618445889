<div class="d-flex flex-column">
	<label class="text-tertiary text-center w-100 fw-semibold mt-3">
		Índice de anomalia de precipitação modificado (IAPM) - {{ titlePeriodo }}
	</label>

	<div
		class="d-flex my-3 flex-row justify-content-between align-items-end gap-2">
		<seira-card-info [texto]="descricaoRelatorio" />
	</div>
</div>

<div
	*ngIf="isLoading; else tabela"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<ng-template #tabela>
	<div style="max-width: 1300px" *ngIf="tableData; else semDados" class="mt-3">
		<seira-public-table
			id="tabela-iapm"
			[hasFooter]="false"
			[hasHeader]="false"
			[info]="false"
			[paging]="false"
			[searching]="false"
			[columns]="colunas"
			[scrollX]="true"
			[data]="tableData"
			[order]="[]"></seira-public-table>
	</div>
</ng-template>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
