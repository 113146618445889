import { DateTimeUtils } from './../../../../../../shared/utils/datetime-util';
import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { Observable, Subscription } from 'rxjs';

import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { FormGroup } from '@angular/forms';
import { DadosValoresExtremosResponse } from '../../interfaces/valores-extremos';
import { formataValorPrecipitacao } from '@utils';
import { FormularioRelatorio } from '../../interfaces';

@Component({
	selector: 'seira-tabela-relatorio-valores-extremos',
	templateUrl: './tabela-relatorio-valores-extremos.component.html',
	styleUrls: ['./tabela-relatorio-valores-extremos.component.scss'],
})
export class TabelaRelatorioValoresExtremosComponent
	implements OnInit, OnDestroy, OnChanges
{
	@Input()
	colunas: ADTColumns[] = [];
	@Input()
	resultado!: DadosValoresExtremosResponse[];
	@ViewChild('tabelaValoresExtremos', { static: false })
	tabela!: PublicTableComponent;
	data: any[] = [];
	@Input() filterObservable: Observable<string> | undefined;
	private filterSubscription?: Subscription = new Subscription();
	date: Date = new Date();
	@Input() scrollX = false;
	class = 'table';
	@Input() loading = false;
	subColunas: any[] = [];
	@Input()
	form!: FormGroup;
	getConditionClass(condicao: string): string {
		const conditionClasses: any = {
			Normal: 'bg-normal',
			Ácima: 'bg-acima',
			Abaixo: 'bg-abaixo',
			Inválido: 'bg-invalido',
		};
		return conditionClasses[condicao] || '';
	}
	getConditionIcon(condicao: string): string {
		switch (condicao) {
			case 'Acima':
				return 'ph ph-arrow-up';
			case 'Abaixo':
				return 'ph ph-arrow-down';
		}
		return '';
	}

	constructor(private renderer: Renderer2) {}

	getTitle() {
		const variaveis = [
			'Precipitação (mm)',
			'Temperatura do ar (°C)',
			'Umidade relativa do ar (%)',
			'Pressão Atmosférica (hPa)',
			'Radiação solar (W/m2)',
			'Evapotranspiração (mm)',
			'Velocidade do vento a 2 metros (m/s)',
			'Velocidade do vento a 10 metros (m/s)',
		];

		return variaveis;
	}

	getAgrupamento() {
		const agrupamento = this.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				return 'Município/Posto';
			case 'MICRORREGIAO':
				return 'Microrregião';
			case 'MUNICIPIO':
				return 'Município';
			case 'MESORREGIAO':
				return 'Mesorregião';
			case 'REGIAO_PLUVIOMETRICA':
				return 'Região Pluviométrica';
			case 'BACIA':
				return 'Bacia';
			case 'SUB_BACIA':
				return 'Sub bacia';
			default:
				return '-';
		}
	}

	formatarDatas(data: string) {
		return DateTimeUtils.formatarData(data, 'dd/MM/yyyy') || '-';
	}

	formatarNumeros(numero: number) {
		return formataValorPrecipitacao(numero) || '-';
	}

	ngOnDestroy(): void {
		this.filterSubscription?.unsubscribe();
	}

	@Input() previsoes: DadosValoresExtremosResponse[] = [];

	ngOnInit(): void {
		this.observeTabela();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['resultado']) {
			this.gerarColunas();
			this.gerarSubcolunas();
		}
	}
	observeTabela() {
		this.filterSubscription = this.form.get('filter')?.valueChanges.subscribe({
			next: async search => {
				await this.tabela.pesquisar(search || '');
			},
		});
	}
	gerarColunas() {
		this.colunas.push({
			title: this.getAgrupamento(),
			data: '',
			className: 'text-center',
		});
		this.getTitle().forEach(title => {
			this.colunas.push({
				title,
				data: '',
				className: 'text-center',
			});
		});
	}
	gerarSubcolunas() {
		this.subColunas.push({
			title: this.getAgrupamento(),
			data: 'titulo',
			className: 'text-center',
		});
		this.getTitle().forEach((dado, index) => {
			this.subColunas.push({
				title: 'Data',
				data: `dataMax${index + 1}`,
				createdCell: (cell: { textContent: string }, cellData: string) => {
					const text = cell.textContent;
					cell.textContent = '';
					const span = this.renderer.createElement('span');
					span.textContent = text;
					const icon = this.renderer.createElement('i');
					this.renderer.setAttribute(
						icon,
						'class',
						this.getConditionIcon(text)
					);
					span.appendChild(icon);
					this.renderer.setAttribute(
						span,
						'class',
						`box-span cond-box ${this.getConditionClass(cellData)} `
					);
					this.renderer.appendChild(cell, span);
				},
			});
			this.subColunas.push({
				title: 'Max',
				data: `max${index + 1}`,
				className: 'text-center',
			});
			this.subColunas.push({
				title: 'Data',
				data: `dataMin${index + 1}`,
				className: 'text-center',
			});
			this.subColunas.push({
				title: 'Min',
				data: `min${index + 1}`,
				className: 'text-center',
			});
		});
		this.gerarDado();
	}
	gerarDado() {
		this.resultado.forEach(dados => {
			const row: any = {};
			row['titulo'] = dados.nome;

			row[`dataMax${1}`] = this.formatarDatas(dados.dataColetaMaxPrecipitacao);
			row[`max${1}`] = this.formatarNumeros(dados.maxPrecipitacao);
			row[`dataMin${1}`] = this.formatarDatas(dados.dataColetaMinPrecipitacao);
			row[`min${1}`] = this.formatarNumeros(dados.minPrecipitacao);
			row[`dataMax${2}`] = this.formatarDatas(dados.dataColetaMaxTemperaturaAr);
			row[`max${2}`] = this.formatarNumeros(dados.maxTemperaturaAr);
			row[`dataMin${2}`] = this.formatarDatas(dados.dataColetaMinTemperaturaAr);
			row[`min${2}`] = this.formatarNumeros(dados.minTemperaturaAr);
			row[`dataMax${3}`] = this.formatarDatas(
				dados.dataColetaMaxUmidadeRelativa
			);
			row[`max${3}`] = this.formatarNumeros(dados.maxUmidadeRelativa);
			row[`dataMin${3}`] = this.formatarDatas(
				dados.dataColetaMinUmidadeRelativa
			);
			row[`min${3}`] = this.formatarNumeros(dados.minUmidadeRelativa);
			row[`dataMax${4}`] = this.formatarDatas(
				dados.dataColetaMaxPressaoAtmosferica
			);
			row[`max${4}`] = this.formatarNumeros(dados.maxPressaoAtmosferica);
			row[`dataMin${4}`] = this.formatarDatas(
				dados.dataColetaMinPressaoAtmosferica
			);
			row[`min${4}`] = this.formatarNumeros(dados.minPressaoAtmosferica);
			row[`dataMax${5}`] = this.formatarDatas(dados.dataColetaMaxRadiacao);
			row[`max${5}`] = this.formatarNumeros(dados.maxRadiacao);
			row[`dataMin${5}`] = this.formatarDatas(dados.dataColetaMinRadiacao);
			row[`min${5}`] = this.formatarNumeros(dados.minRadiacao);
			row[`dataMax${6}`] = this.formatarDatas(
				dados.dataColetaMaxEvapotranspiracao
			);
			row[`max${6}`] = this.formatarNumeros(dados.maxEvapotranspiracao);
			row[`dataMin${6}`] = this.formatarDatas(
				dados.dataColetaMinEvapotranspiracao
			);
			row[`min${6}`] = this.formatarNumeros(dados.minEvapotranspiracao);
			row[`dataMax${7}`] = this.formatarDatas(
				dados.dataColetaMaxVelocidadeVento2m
			);
			row[`max${7}`] = this.formatarNumeros(dados.maxVelocidadeVento2m);
			row[`dataMin${7}`] = this.formatarDatas(
				dados.dataColetaMinVelocidadeVento2m
			);
			row[`min${7}`] = this.formatarNumeros(dados.minVelocidadeVento2m);
			row[`dataMax${8}`] = this.formatarDatas(
				dados.dataColetaMaxVelocidadeVento10m
			);
			row[`max${8}`] = this.formatarNumeros(dados.maxVelocidadeVento10m);
			row[`dataMin${8}`] = this.formatarDatas(
				dados.dataColetaMinVelocidadeVento10m
			);
			row[`min${8}`] = this.formatarNumeros(dados.minVelocidadeVento10m);

			this.data.push(row);
		});
	}
}
