import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import {
	filterSelectOptions,
	getBase64ImageFromURL,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import * as pdfseira from '@utils/pdf-seira';
import { startOfMonth, subYears } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';
import { IapmDTO } from '@home/submodulos/dados-meteorologicos/interfaces';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import { LegendaCor } from '@utils/interpolacao/interpolacao';

export const PERIODO_MINIMO_SPI_EM_ANOS = 10;
export const PERIODO_MAXIMO_SPI_EM_ANOS = 30;

@Component({
	selector: 'seira-pluviometro-grafico-spi',
	templateUrl: './pluviometro-grafico-spi.component.html',
	styleUrls: ['./pluviometro-grafico-spi.component.scss'],
})
export class PluviometroGraficoSpiComponent
	implements InstanciaRelatorio, OnInit, OnDestroy
{
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	agrupamento: Select<string>[];
	private subscription = new Subscription();
	private inputs = inject(INPUTS_RELATORIOS);

	carregandoRelatorio = true;
	graficoSPIUrl?: string;
	grafico = 'SPI';
	valores: LegendaCor[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.graficoSPIUrl);
			},
		},
	];
	descricaoRelatorio =
		'Define-se como índice de precipitação padronizado (SPI), a anomalia, em número de desvios padrão, que a pluviometria observada se afasta da climatologia, auxiliando na classificação de períodos secos ou úmidos em um determinado local ou região.';

	periodoTitulo = '';

	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService
	) {
		this.setValidators();
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
	}

	ngOnInit() {
		const inicioDefault = startOfMonth(
			subYears(new Date(), PERIODO_MINIMO_SPI_EM_ANOS)
		);
		const fimDefault = new Date();

		this.inputs.form.patchValue({
			dataInicio: inicioDefault,
			dataFim: fimDefault,
		});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio(): void {
		const idRegiaoPluviometrica =
			this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO) ===
			'REGIAO_PLUVIOMETRICA'
				? this.getFormItemValue(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
				: null;
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);
		this.periodoTitulo = this.getPeriodo();
		this.relatoriosService
			.buscarGraficoSPI(dataInicio, dataFim, idRegiaoPluviometrica)
			.subscribe({
				next: grafico => {
					this.graficoSPIUrl = URL.createObjectURL(grafico);
					this.valoresLegenda();
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
				},
				complete: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
			});
	}
	valoresLegenda() {
		this.valores = [
			{
				infoAdicional: 'Excepcionalmente úmido',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#00008b',
			},
			{
				infoAdicional: 'Extremamente úmido',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#0000d0',
			},
			{
				infoAdicional: 'Muito úmido',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#1b4cda',
			},
			{
				infoAdicional: 'Moderadamente úmido',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#00cb33',
			},
			{
				infoAdicional: 'Anormalmente úmido',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#65ff65',
			},
			{
				infoAdicional: 'Normal',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#ffffff',
			},

			{
				infoAdicional: 'Anormalmente seco',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#ffff66',
			},
			{
				infoAdicional: 'Moderadamente seco',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#ffcc00',
			},
			{
				infoAdicional: 'Severamente Seco',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#ff3300',
			},
			{
				infoAdicional: 'Extremamente Seco',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#d00000',
			},
			{
				infoAdicional: 'Excepcionalmente seco',
				valorMin: 0,
				valorMax: 0,
				unidade: '',
				cor: '#8b0000',
			},
		];
	}

	getPeriodo() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');
		return DateTimeUtils.formatarDataPeriodo(dataInicio, dataFim);
	}

	getDadosTabelaParaExportacao(dados: IapmDTO[], isPdf: boolean) {
		const tableData: any[][] = [];

		const colunas = [
			{ text: 'IAPM', fillColor: '#DCDCDC' },
			{ text: 'Período', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: IapmDTO) => {
			const rowData = [numberToBrNumber(item.iapm, 1), item.periodo];
			tableData.push(rowData);
		});

		return tableData;
	}

	async exportarPDF(graficoSPIUrl?: string) {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.content.push({
			text: `Índice padronizado de precipitação (SPI) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!graficoSPIUrl) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		} else {
			documentDefinition.content.push({
				image: await getBase64ImageFromURL(graficoSPIUrl),
				alignment: 'center',
				width: 800,
				height: 400,
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.subscription.unsubscribe();
	}

	private getFormItemValue(name: string) {
		return this.inputs.form.get(name)?.value;
	}
}
