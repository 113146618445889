export const optionsChartDesvio: Highcharts.Options = {
	chart: {
		type: 'column',
		backgroundColor: 'transparent',
	},
	plotOptions: {
		column: {
			borderWidth: 0,
		},
	},
};
