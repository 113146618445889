<div class="row mt-3 mb-5">
	<seira-public-pagination [items]="relatorios" [itemsPerPage]="5">
		<ng-template let-item="item">
			<div class="mb-2">
				<label
					for="tabela-evapotranspiracao"
					class="text-tertiary mb-2 fw-semibold">
					{{
						item.data.at(0).nomeEstacao === item.municipio
							? item.data.at(0).nomeEstacao
							: item.data.at(0).nomeEstacao + ' / ' + item.municipio
					}}
				</label>
				<seira-public-table
					id="tabela-evapotranspiracao"
					[hasFooter]="false"
					[hasHeader]="false"
					[info]="false"
					[paging]="false"
					[searching]="false"
					[columns]="colunas"
					[data]="item.data"></seira-public-table>
			</div>
		</ng-template>
	</seira-public-pagination>
</div>
