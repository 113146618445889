import { Injectable } from '@angular/core';
import { Rotas } from '@core/enums/routes';
import { FiltroEstacoes } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/monitoramento-estacoes';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import {
	DataHistoricoEstacao,
	EstacaoMonitorada,
	EstacoesMonitoradasRequestParams,
	LabelVariaveis,
	ResponseHistoricoEstacao,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';

@Injectable({
	providedIn: 'root',
})
export class EstacoesService {
	constructor(private http: HttpClientProtectedService) {}
	estacoesMonitoramento(data: EstacoesMonitoradasRequestParams) {
		return this.http.post<
			EstacoesMonitoradasRequestParams,
			EstacaoMonitorada[]
		>(Rotas.ACOMPANHAMENTO_COLETA + '/estacoes-monitoramento', data);
	}

	filtros(params: { mesorregiaoId: number } | {}) {
		return this.http.get<FiltroEstacoes>(
			Rotas.ACOMPANHAMENTO_COLETA + '/filtros',
			{ ...params }
		);
	}

	historicoEstacao(
		estacaoId: number,
		diaInicio: Date,
		diaFim: Date,
		variavel: LabelVariaveis
	) {
		return this.http.post<DataHistoricoEstacao, ResponseHistoricoEstacao>(
			Rotas.GRAFICOS + '/variavel',
			{
				diaInicio,
				diaFim,
				postoId: estacaoId,
				variavel,
			}
		);
	}
}
