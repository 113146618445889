<div class="container">
	<label class="text-center text-tertiary fw-semibold w-100">
		Pluviometria interpolada - {{ periodoTitulo }}
	</label>
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />
	<div
		*ngIf="carregando; else mapa"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false" />
	</div>
	<ng-template #mapa>
		<div
			id="mapa-legenda"
			class="row"
			*ngIf="interpolacao; else nenhumResultado">
			<div class="col-12">
				<div class="mb-3" style="position: relative">
					<seira-mapa-interpolacao
						[mapaId]="'chuvaEstado'"
						[mapaNome]="'Pluviometria interpolada'"
						[imageOpacity]="0.8"
						[interpolacao]="interpolacao"
						[carregando]="carregando"
						[valores]="valores" />
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #nenhumResultado>
		<div class="row">
			<div class="col-12">
				<seira-sem-dados />
			</div>
		</div>
	</ng-template>
</div>
