import { formataValorPrecipitacao } from '@utils';

export class DesvioService {
	static calcularDesvioMilimetro(chuva: number, climatologia: number): number {
		const desvio = chuva - climatologia;
		return formataValorPrecipitacao(desvio);
	}

	static calcularDesvioPorcentagem(
		chuva: number,
		climatologia: number
	): number {
		if (climatologia === 0) {
			return 0;
		}
		const desvioPercentual = ((chuva - climatologia) / climatologia) * 100;
		return formataValorPrecipitacao(desvioPercentual);
	}
}
