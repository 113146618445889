import { FormGroup } from '@angular/forms';
import { InjectionToken } from '@angular/core';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	Estacao,
	Municipio,
} from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import moment from 'moment';
import { TiposDatePicker } from '@shared/interfaces/date-picker';

interface InputsRelatorio {
	form: FormGroup;
	postos: PostosRelatorios[];
	postosSudene: Select[];
	estacoes: Estacao[];
	regioes: Select[];
	microrregioes: Select[];
	mesorregioes: Select[];
	precipitacao: Select[];
	municipioPostos: Select[];
	municipios: Municipio[];
	agrupamento: Select[];
	bacias: Select[];
	subBacias: Select[];
	setLoading: (state: boolean) => void;
	setCondicoesMostrarItensForm: (fucoesForm: CondicoesMostrarItensForm) => void;
}
interface InputsRelatorioQuantis {
	form: FormGroup;
	estacoes: Estacao[];
	regioes: Select[];
	microrregioes: Select[];
	municipios: Municipio[];
	setLoading: (state: boolean) => void;
	setCondicoesMostrarItensForm: (fucoesForm: CondicoesMostrarItensForm) => void;
}
export interface ConjuntoLabelsDinamicasPeriodo {
	inicio?: LabelDinamicaPeriodo;
	fim?: LabelDinamicaPeriodo;
	unico?: LabelDinamicaPeriodo;
	mensal_por_nome?: boolean;
}
interface LabelDinamicaPeriodo {
	label: string;
	placeholder: string;
	dataType: TiposDatePicker;
}

export enum FormularioRelatorio {
	CATEGORIA = 'categoria',
	FORMATO = 'formato',
	TIPO = 'tipo',
	VARIAVEL = 'variavel',
	DATA_INICIO = 'dataInicio',
	DATA_FIM = 'dataFim',
	MES_INICIO = 'mesInicio',
	MES_FIM = 'mesFim',
	POSTO = 'posto',
	POSTO_SUDENE = 'postoSudene',
	ESTACAO = 'estacao',
	PERIODO = 'periodo',
	MICRORREGIAO = 'microrregiao',
	MESORREGIAO = 'mesorregiao',
	MUNICIPIO = 'municipio',
	PRECIPITACAO = 'precipitacao',
	LOADING_GERAR = 'loadingGerar',
	VARIAVEL_MAPA = 'variavelMapa',
	TIPO_PERIODO = 'tipoPeriodo',
	TIPO_PERIODO_VALORES_EXTREMOS = 'tipoPeriodoValoresExtremos',
	TIPO_MAPA = 'tipoMapa',
	JANELA_TEMPO = 'janelaDeTempo',
	AGRUPAMENTO = 'agrupamento',
	PERIODO_BUSCA = 'periodoBusca',
	TIPO_VISUALIZACAO = 'tipoVisualizacao',
	PERIODO_CHUVOSO = 'periodoChuvoso',
	REGIAO_PLUVIOMETRICA = 'regiao',
	BACIA = 'bacia',
	SUB_BACIA = 'subBacia',
	MAPA_PRECIPITACOES = 'mapaPrecipitacoes',
	VARIAVEIS_PLUVIOMETRICAS = 'variaveisPluviometricas',
	RADIO_POSTOS = 'radioPostos',
}

export const INPUTS_RELATORIOS = new InjectionToken<InputsRelatorio>(
	'Inputs dos relatórios'
);
export const INPUTS_RELATORIOS_QUANTIS =
	new InjectionToken<InputsRelatorioQuantis>(
		'Inputs dos relatórios de quantis'
	);

export interface InstanciaRelatorio {
	form?: FormGroup;
	postos?: PostosRelatorios[];
	estacoes?: Estacao[];
	regioes?: Select[];
	microrregioes?: Select[];
	mesorregioes?: Select[];
	municipioPostos?: Select[];
	municipios?: Municipio[];
	precipitacao?: Select[];
	gerarRelatorio: () => void;
	agrupamento?: Select[];
	botoesDeExportacao?: GroupButton[];
}

export interface ComponenteRelatorio {
	componente?: any;
	periodos?: Partial<{
		[K in PeriodosLabel]: PeriodosInterface;
	}>;
	condicoesMostrarItensForm?: CondicoesMostrarItensForm;
	inputs?: { form: FormGroup };
}

export interface PeriodosInterface {
	minAmount?: moment.DurationInputArg1;
	amount?: moment.DurationInputArg1;
	unit?: moment.unitOfTime.DurationConstructor | undefined;
}

export type PeriodosLabel =
	| 'periodo'
	| 'mensal'
	| 'anual'
	| 'mensal_unico'
	| 'anual_unico'
	| 'mensal_por_nome';

export type ListaRelatorios = {
	PLUVIOMETRICO: {
		GRAFICO: {
			SPI: ComponenteRelatorio;
			PLUVIOMETRIA_OBSERVADA: ComponenteRelatorio;
			DESVIO_MILIMETRO: ComponenteRelatorio;
			DESVIO_PORCENTAGEM: ComponenteRelatorio;
			CLIMATOLOGIA: ComponenteRelatorio;
			NUMERO_DIAS_COM_CHUVA: ComponenteRelatorio;
			PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA: ComponenteRelatorio;
			PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA: ComponenteRelatorio;
			IAPM: ComponenteRelatorio;
		};
		MAPA: {
			PLUVIOMETRIA_INTERPOLADA: ComponenteRelatorio;
			IAPM: ComponenteRelatorio;
			PLUVIOMETRIA_OBSERVADA: ComponenteRelatorio;
			DESVIO_MILIMETRO: ComponenteRelatorio;
			DESVIO_PORCENTAGEM: ComponenteRelatorio;
			MOSAICO_CHUVAS: ComponenteRelatorio;
			CLIMATOLOGIA: ComponenteRelatorio;
			NUMERO_DIAS_COM_CHUVA: ComponenteRelatorio;
		};
		TABELA: {
			PRECIPITACAO_DIARIA: ComponenteRelatorio;
			PRECIPITACAO_ANUAL: ComponenteRelatorio;
			PRECIPITACAO_ACUMULADA: ComponenteRelatorio;
			PLUVIOMETRICO_POR_POSTO: ComponenteRelatorio;
			PLUVIOMETRICO_DO_ESTADO: ComponenteRelatorio;
			HISTORICO_DA_SUDENE: ComponenteRelatorio;
			NUMERO_DIAS_COM_CHUVA: ComponenteRelatorio;
			DESVIO: ComponenteRelatorio;
			VALORES_EXTREMOS_PLUVIOMETRIA: ComponenteRelatorio;
			CLIMATOLOGIA: ComponenteRelatorio;
			SPI: ComponenteRelatorio;
			PLUVIOMETRIA_OBSERVADA: ComponenteRelatorio;
			IAPM: ComponenteRelatorio;
			CLIMATOLOGIA_DESVIO_PLUVIOMETRIA_OBSERVADA: ComponenteRelatorio;
		};
	};
	EVAPOTRANSPIRACAO: {
		TABELA: ComponenteRelatorio;
	};
	VARIAVEL_METEOROLOGICA: {
		TABELA: ComponenteRelatorio;
		GRAFICO: ComponenteRelatorio;
		MAPA: {
			HISTORICO: ComponenteRelatorio;
			ISOLINHA: ComponenteRelatorio;
		};
	};
	VALORES_EXTREMOS: {
		TABELA: ComponenteRelatorio;
	};
	QUANTIS: {
		MAPA: {
			QUANTIS_ANUAL: ComponenteRelatorio;
			QUANTIS_MENSAL: ComponenteRelatorio;
		};
		GRAFICO: {
			QUANTIS_ANUAL: ComponenteRelatorio;
			QUANTIS_MENSAL: ComponenteRelatorio;
			QUANTIS_PLUVIOMETRICOS_ANUAL: ComponenteRelatorio;
			QUANTIS_PLUVIOMETRICOS_REGIAO: ComponenteRelatorio;
		};
		TABELA: {
			QUANTIS_ANUAL: ComponenteRelatorio;
			QUANTIS_MENSAL: ComponenteRelatorio;
		};
	};
};

export type ValoresFormularioRelatorio = {
	[P in FormularioRelatorio]: any;
};

export type CondicoesMostrarItensForm = {
	[k in FormularioRelatorio]?: (val: ValoresFormularioRelatorio) => boolean;
};
