import { Component, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'seira-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent {
	@Input() cardHeaderBackgroud = '';
	@Input() iconHeader = '';
	@Input() textHeader = '';
	@Input() footerContent?: TemplateRef<void>;
	@Input() bodyClass?: string = '';
	@Input() hasButton?: boolean = false;
	@Input() buttonLabel?: string = '';
	@Input() buttonLink?: string = '';
}
