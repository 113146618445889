import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject } from 'rxjs';
import {
	DadosAgrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	PeriodosLabel,
} from '../../../interfaces';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { RelatoriosService } from '../../../services';
import { corrigeDuplicacaoNome } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { formataValorPrecipitacao, numberToBrNumber } from '@utils';
@Component({
	selector: 'seira-tabela-ndc',
	templateUrl: './tabela-ndc.component.html',
	styleUrls: ['./tabela-ndc.component.scss'],
})
export class TabelaNdcComponent implements OnInit, OnDestroy {
	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloTabela = 'Número de dias com chuva (NDC)';
	tituloColunaAgrupamento = 'Agrupamento';
	descricaoRelatorio =
		'O NDC, Número de Dias com Chuva, representa o número de dias em que a pluviometria diária, acumulada em 24 horas, foi igual ou superior a 0,1 mm.';

	carregando = true;
	disabledSelectList = false;

	colunasNDC: ADTColumns[] = [];
	resultados: RelatorioNDCResponse[] = [];
	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaNDC', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
	}

	ngOnInit() {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
	}
	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	setColunasSelecionadas(colunas: any) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.tituloTabela = `Número de dias com chuva (NDC) - ${DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		)}`;
		this.carregando = true;
		this.inputs.setLoading(true);

		this.colunasNDC = [];
		this.resultados = [];

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
			isRetornoAgrupado: true,
		};

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = 0;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = 0;
				break;
			case 'MUNICIPIO':
				dados.municipio = 0;
				break;
		}

		this.relatoriosService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.relatoriosService.verificaExistenciaDados(ndcAgrupados);
				this.resultados = ndcAgrupados;
				this.atualizarTitulosTabela();
				this.altualizarColunasTabelaNDC();
			},
			error: err => {
				this.toastrService.error(
					'Erro ao gerar tabela de NDC, tente novamente'
				);
				this.carregando = false;
				this.inputs.setLoading(false);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.carregando = false;
			},
		});
	}

	altualizarColunasTabelaNDC() {
		const periodoBusca = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		);
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		this.colunasNDC = [
			{
				data: 'nome',
				title: this.tituloColunaAgrupamento,
				type: 'text',
				className: 'text-left',
				render: (nome: string) => corrigeDuplicacaoNome(nome),
			},
		];

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'id',
					title: 'Id',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},

				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},

				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 1) : '-'),
				},

				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (regiao: string) => (regiao !== '' ? regiao : '-'),
				},

				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (bacia: string) => (bacia !== '' ? bacia : '-'),
				},

				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (subbacia: string) => (subbacia !== '' ? subbacia : '-'),
				},
				{
					data: null,
					title: 'Data',
					type: 'string',
					className: 'text-center',
					render: (Agrupamento: RelatorioNDCResponse) => {
						if (Agrupamento.mes !== null && Agrupamento.mes !== undefined) {
							if (Agrupamento.mes < 10) {
								return `0${Agrupamento.mes}/${Agrupamento.ano}`;
							}
							return `${Agrupamento.mes}/${Agrupamento.ano}`;
						} else if (
							Agrupamento.ano !== null &&
							Agrupamento.ano !== undefined
						) {
							return `${Agrupamento.ano}`;
						}

						return '-';
					},
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunasNDC.push(...colunasParaExibir);
		} else {
			this.disabledSelectList = true;
		}

		this.colunasNDC.push(
			...[
				{
					data: 'diasComChuva',
					title: 'Dias com chuva',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'somaPrecipitacao',
					title: 'Precipitação total (mm)',
					type: 'number',
					className: 'text-center',
					render: (precipitacao: number) => {
						return precipitacao
							? precipitacao.toFixed(1).replace('.', ',')
							: '-';
					},
				},
			]
		);
		if (periodoBusca!.value !== 'periodo') {
			this.colunasNDC.push({
				data: null,
				title: 'Data',
				type: 'string',
				className: 'text-center',
				render: (Agrupamento: RelatorioNDCResponse) => {
					if (Agrupamento.mes !== null && Agrupamento.mes !== undefined) {
						if (Agrupamento.mes < 10) {
							return `0${Agrupamento.mes}/${Agrupamento.ano}`;
						}
						return `${Agrupamento.mes}/${Agrupamento.ano}`;
					} else if (
						Agrupamento.ano !== null &&
						Agrupamento.ano !== undefined
					) {
						return `${Agrupamento.ano}`;
					}

					return '-';
				},
			});
		}
	}

	private getVisibleColumns(): string[] {
		return this.tabela!.columns.map(col => col.title as string);
	}
	private prepararDadosDaLinha(row: any, visibleColumns: string[]): string[] {
		return visibleColumns.map(columnTitle => {
			if (columnTitle === 'Município/Posto') {
				return corrigeDuplicacaoNome(row.nome);
			} else if (columnTitle === 'Data') {
				if (row.mes != null) {
					if (row.mes < 10) {
						return `0${row.mes}/${row.ano}`;
					}
					return `${row.mes}/${row.ano}`;
				} else {
					return `${row.ano}`;
				}
			} else {
				return this.getCellValue(row, columnTitle);
			}
		});
	}
	private getCellValue(row: any, columnTitle: string): string {
		const column = this.tabela!.columns.find(col => col.title === columnTitle);
		if (column && column.data) {
			return this.extractNestedValue(row, column.data as string) ?? '-';
		}
		return '-';
	}
	private extractNestedValue(obj: any, path: string): any {
		return path.split('.').reduce((value, key) => {
			if (key === 'somaPrecipitacao') {
				return formataValorPrecipitacao(value?.[key]);
			}
			return value?.[key];
		}, obj);
	}
	atualizarTitulosTabela() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloColunaAgrupamento = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloColunaAgrupamento = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloColunaAgrupamento = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloColunaAgrupamento = 'Mesorregião';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloColunaAgrupamento = 'Região Pluviométrica';
				break;
			case 'BACIA':
				this.tituloColunaAgrupamento = 'Bacia';
				break;
			case 'SUB_BACIA':
				this.tituloColunaAgrupamento = 'Sub bacia';
				break;
		}
	}

	private prepararDadosParaTabela(): string[][] {
		const visibleColumns = this.getVisibleColumns();
		const tableData = [visibleColumns];
		this.tabela?.data.forEach((row: any) => {
			const rowData = this.prepararDadosDaLinha(row, visibleColumns);
			tableData.push(rowData);
		});

		return tableData;
	}
	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.content.push({
			text: `Relatório - ${this.tituloTabela}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.resultados) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData = this.prepararDadosParaTabela();

		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.prepararDadosParaTabela();

		DocumentExporter.gerarCSV(tableData, this.tituloTabela);
	}

	exportarTXT() {
		let txtData = '';
		const tableData = this.prepararDadosParaTabela();

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}
		DocumentExporter.gerarTXT(txtData, this.tituloTabela);
	}
}
