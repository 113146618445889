import {
	AfterViewInit,
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	inject,
	Injector,
	OnDestroy,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import * as Leaflet from 'leaflet';
import { PublicAComponent } from '@componentes/public-a/public-a.component';
import { StatusEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/status-estacao';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { Validators } from '@angular/forms';
import { Select } from '@layout/interfaces/select';
import { HttpClient } from '@angular/common/http';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import {
	CORES,
	legendaInfoPluvObservadaPeriodo as legendaPeriodo,
	legendaInfoPluvObservada2MesesAAnual as legenda2MesesAAnual,
} from '@componentes/mapa-paraiba-svg/legenda';
import { AgrupamentoResponse } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { PopupAgrupamentoComponent } from '@home/submodulos/dados-meteorologicos/componentes/popup-agrupamento/popup-agrupamento.component';
import {
	AgrupamentoGeojson,
	agrupamentoGeojsonMap,
} from '@home/submodulos/dados-meteorologicos/enum/Agrupamento';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as pdfseira from '@utils/pdf-seira';
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';
import { DocumentExporter } from '@utils/document-exporter';
import { DateTimeUtils } from '@utils/datetime-util';
import { ColorUtils } from '@utils/color-utils';
import { numberToBrNumber, verificaSePDF } from '@utils';
import { corrigeDuplicacaoNome } from '../../../utils';
import { Geometry } from 'geojson';

type GeoWithCoordinates = Geometry & {
	coordinates: number[][][];
};

@Component({
	selector: 'seira-mapa-pluviometria-observada',
	templateUrl: './mapa-pluviometria-observada.component.html',
	styleUrls: ['./mapa-pluviometria-observada.component.scss'],
})
export class MapaPluviometriaObservadaComponent
	implements AfterViewInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild('linkRef', { static: false })
	linkRef!: TemplateRef<PublicAComponent>;
	map!: Leaflet.Map;

	loadingFiltrando = true;

	status: { label: StatusEstacao; value: keyof typeof StatusEstacao }[] = [];
	show = 'block';

	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;
	inputs = inject(INPUTS_RELATORIOS);
	agrupamento: Select[] | any = [];
	geoJsonLayer?: Leaflet.GeoJSON;
	legenda: Record<number, string>;
	agrupamentoResult: AgrupamentoResponse[] = [];
	periodoTitulo = '';

	descricaoRelatorio =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPdf(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	agrupamentoPluviometriaObsHeader: {
		label: string;
		text: string;
		size: number;
	} = {
		label: '',
		text: '',
		size: 0,
	};

	constructor(
		private resolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private cdr: ChangeDetectorRef,
		private httpClient: HttpClient,
		private relatorioService: RelatoriosService
	) {
		this.setValidators();
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	compilePopupAgrupamento(
		onAttach: (el: ComponentRef<PopupAgrupamentoComponent>) => void
	) {
		const compFactory = this.resolver.resolveComponentFactory(
			PopupAgrupamentoComponent
		);
		const compRef = compFactory.create(this.injector);

		// onAttach allows you to assign
		if (onAttach) onAttach(compRef);

		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}

	gerarRelatorio() {
		const diaInicio = this.getFormItemValue('dataInicio');
		const diaFim = this.getFormItemValue('dataFim');
		const agrupamento = this.getFormItemValue('agrupamento');

		//atribuição que servirá para utilizar o agrupamento selecionado em outras partes do código
		this.agrupamento = agrupamento;
		const periodo = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;

		this.atualizaLegenda();
		this.inputs.setLoading(true);
		this.loadingFiltrando = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodo
		);
		this.relatorioService
			.buscarRelatorioPorAgrupamento({
				diaInicio,
				diaFim,
				agrupamento,
				periodoAgrupamento: periodo.toUpperCase(),
			})
			.subscribe({
				next: (val: AgrupamentoResponse[]) => {
					this.relatorioService.verificaExistenciaDados(val);
					this.agrupamentoResult = this.maioresPreciptacoesPorMunicipio(
						val,
						agrupamento
					);
					this.setAgrupamentoHeader(agrupamento);
				},
				error: () => {
					this.loadingFiltrando = false;
				},
				complete: () => {
					this.loadMunicipiosMap(this.map);
					this.loadingFiltrando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	maioresPreciptacoesPorMunicipio(
		val: AgrupamentoResponse[],
		agrupamento: string
	) {
		const somaAgrupamento =
			this.handleCalculatePrecipitacaoAcumuladaByResponse(val);
		if (agrupamento === 'MUNICIPIO_POSTO') {
			const estacoesSoma = new Map<string, AgrupamentoResponse>();

			somaAgrupamento.forEach(agrupamento => {
				const [municipio] = agrupamento.nome.split('/');
				if (
					!estacoesSoma.has(municipio) ||
					(estacoesSoma.get(municipio)!.precipitacaoAcumulada &&
						estacoesSoma.get(municipio)!.precipitacaoAcumulada! <
							agrupamento.precipitacaoAcumulada!)
				) {
					agrupamento.nomeOriginal = agrupamento.nome;
					agrupamento.nome = municipio;
					estacoesSoma.set(municipio, agrupamento);
				}
			});

			return Array.from(estacoesSoma.values());
		} else {
			return somaAgrupamento;
		}
	}

	getGeojsonPorAgrupamento() {
		const agrupamentoStr = this.inputs.form?.get('agrupamento')?.value;
		const agrupamento = agrupamentoGeojsonMap[agrupamentoStr];

		return agrupamento ? agrupamento : AgrupamentoGeojson.MUNICIPIO;
	}

	loadMunicipiosMap(map: Leaflet.Map) {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}

		const geojson = this.getGeojsonPorAgrupamento();

		this.httpClient.get<GeoJSON.GeoJsonObject>(geojson).subscribe({
			next: value => {
				this.geoJsonLayer = Leaflet.geoJSON(value, {
					style: feature => this.estiloRegiao(feature),
					onEachFeature: (feature, layer) => {
						layer.on({
							click: e => {
								const nome = this.getPropertyNomeGeoson(feature);

								const dadosRegiao = this.agrupamentoResult.find(
									(d: AgrupamentoResponse) => d.nome === nome
								);
								const markerPopup = this.compilePopupAgrupamento(c => {
									const cor = this.getColor(dadosRegiao?.precipitacaoAcumulada);
									if (dadosRegiao) {
										c.instance.agrupamento = {
											...dadosRegiao,
											tipo: this.agrupamento,
										};
									} else {
										const geo: GeoWithCoordinates =
											feature.geometry as GeoWithCoordinates;
										const coordenadas = geo.coordinates.flat(1);
										const latitudes: number[] = [];
										const longitudes: number[] = [];
										coordenadas.forEach(([lat, long]) => {
											latitudes.push(lat);
											longitudes.push(long);
										});
										const mediaLatitude =
											latitudes.reduce((a, b) => a + b) / latitudes.length;
										const mediaLongitude =
											longitudes.reduce((a, b) => a + b) / longitudes.length;
										c.instance.agrupamento = {
											latitude: mediaLatitude,
											longitude: mediaLongitude,
											nome: feature.properties[
												this.chavePropriedadeFeatureGeoJSON
											],
											tipo: this.agrupamento,
										};
									}
									c.instance.headerColor = cor;
									c.instance.titleColor = ColorUtils.ehCorClara(cor)
										? 'gray'
										: CORES.BRANCO_NEVE;
								});

								layer.bindPopup(markerPopup).openPopup(e.latlng);
							},
						});
					},
				}).addTo(map);
			},
		});
	}

	private get chavePropriedadeFeatureGeoJSON(): string {
		switch (this.agrupamento) {
			case 'MUNICIPIO_POSTO':
			case 'MUNICIPIO':
			case 'REGIAO_PLUVIOMETRICA':
				return 'name';
			case 'MICRORREGIAO':
				return 'nome';
			case 'MESORREGIAO':
				return 'NM_MESO';
			case 'BACIA':
			case 'SUBBACIA':
				return 'Nome';
			default:
				return 'nome';
		}
	}

	atualizaLegenda(): void {
		const tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		const diaInicio = this.getFormItemValue('dataInicio');
		const diaFim = this.getFormItemValue('dataFim');
		const diffMeses = DateTimeUtils.getDiferencaMesesUTC(
			new Date(diaFim),
			new Date(diaInicio)
		);
		this.legenda =
			(tipoPeriodo === 'mensal' && diffMeses >= 2) || tipoPeriodo === 'anual'
				? legenda2MesesAAnual
				: legendaPeriodo;
	}

	getColor(valor?: number): string {
		if (valor === undefined || valor === null) {
			return CORES.CINZA;
		}
		for (const key of Object.keys(this.legenda)
			.map(Number)
			.sort((a, b) => b - a)) {
			if (valor >= key) {
				const legendas: { periodo: string; mensal: string; anual: string } = {
					periodo: legendaPeriodo[key],
					mensal: legenda2MesesAAnual[key],
					anual: legenda2MesesAAnual[key],
				};
				const tipoPeriodo = this.inputs.form.get(
					FormularioRelatorio.PERIODO_BUSCA
				)?.value as keyof typeof legendas;

				return legendas[tipoPeriodo] || legendaPeriodo[key];
			}
		}
		return CORES.CINZA;
	}

	getPropertyNomeGeoson(feature: {
		properties: {
			name: string;
			NM_MESO: string;
			Nome: string;
			nome: string;
			Regiao: string;
		};
	}) {
		return (
			feature.properties.name ||
			feature.properties.NM_MESO ||
			feature.properties.Nome ||
			feature.properties.nome ||
			feature.properties.Regiao
		);
	}

	estiloRegiao(feature: any): any {
		const nome = this.getPropertyNomeGeoson(feature);
		const dadosRegiao = this.agrupamentoResult.find(
			(d: AgrupamentoResponse) => d.nome === nome
		);
		const valorPrecipitacao = dadosRegiao?.precipitacaoAcumulada;
		return {
			fillColor: this.getColor(valorPrecipitacao),
			weight: 2,
			opacity: 1,
			color: '#607d8b',
			fillOpacity: 0.7,
		};
	}

	getFormItemValue(formValue: string) {
		return this.inputs.form.get(formValue)?.value;
	}

	getDadosTabelaParaExportacao(dados: AgrupamentoResponse[], isPdf: boolean) {
		const tableData: any[][] = [];
		const agrupamento = this.getFormItemValue('agrupamento');
		const colunas: any[] = [];

		if (agrupamento !== 'MUNICIPIO_POSTO') {
			colunas.push(
				{
					text: this.agrupamentoPluviometriaObsHeader.text,
					fillColor: '#DCDCDC',
				},
				{ text: 'Pluviometria Observada', fillColor: '#DCDCDC' },
				{ text: 'Data', fillColor: '#DCDCDC' }
			);
		} else {
			colunas.push(
				{ text: 'Município/Posto', fillColor: '#DCDCDC' },
				{ text: 'Pluviometria observada', fillColor: '#DCDCDC' },
				{ text: 'Data', fillColor: '#DCDCDC' },
				{ text: 'Latitude', fillColor: '#DCDCDC' },
				{ text: 'Longitude', fillColor: '#DCDCDC' },
				{ text: 'Mesorregião', fillColor: '#DCDCDC' },
				{ text: 'Microrregião', fillColor: '#DCDCDC' },
				{ text: 'Região pluviométrica', fillColor: '#DCDCDC' },
				{ text: 'Bacia', fillColor: '#DCDCDC' },
				{ text: 'Sub-bacia', fillColor: '#DCDCDC' }
			);
		}

		verificaSePDF(tableData, colunas, isPdf);

		const periodo = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;
		const formatoData =
			periodo === 'anual'
				? 'yyyy'
				: periodo === 'mensal'
				? 'MM/yyyy'
				: 'DD/MM/yyyy';

		dados.forEach((item: AgrupamentoResponse) => {
			const data = item.data
				? new Date(item.data?.replace(/\.0/g, '')).toISOString()
				: null;

			const rowData = [];

			if (agrupamento !== 'MUNICIPIO_POSTO') {
				rowData.push(
					item.dadosPosto && item.dadosPosto.microrregiao
						? item.dadosPosto.microrregiao
						: item.dadosPosto && item.dadosPosto.mesorregiao
						? item.dadosPosto.mesorregiao
						: item.dadosPosto && item.dadosPosto.bacia
						? item.dadosPosto.bacia
						: item.dadosPosto && item.dadosPosto.subbacia
						? item.dadosPosto.subbacia
						: item.dadosPosto && item.dadosPosto.regiao
						? item.dadosPosto.regiao
						: item.nome,
					item.precipitacaoAcumulada
						? numberToBrNumber(item.precipitacaoAcumulada, 1)
						: '-',
					data ? DateTimeUtils.formatarDataUTC(data, formatoData) : ''
				);
			} else {
				rowData.push(
					item.nome ? corrigeDuplicacaoNome(item.nome) : '-',
					item.precipitacaoAcumulada
						? numberToBrNumber(item.precipitacaoAcumulada, 1)
						: '-',
					data ? DateTimeUtils.formatarDataUTC(data, formatoData) : '-',
					item.dadosPosto?.latitude ? item.dadosPosto.latitude : '-',
					item.dadosPosto?.longitude ? item.dadosPosto.longitude : '-',
					item.dadosPosto && item.dadosPosto.mesorregiao
						? item.dadosPosto.mesorregiao
						: '-',
					item.dadosPosto && item.dadosPosto.microrregiao
						? item.dadosPosto.microrregiao
						: '-',
					item.dadosPosto && item.dadosPosto.regiao
						? item.dadosPosto.regiao
						: '-',
					item.dadosPosto && item.dadosPosto.bacia
						? item.dadosPosto.bacia
						: '-',
					item.dadosPosto && item.dadosPosto.subbacia
						? item.dadosPosto.subbacia
						: '-'
				);
			}

			tableData.push(rowData);
		});

		return tableData;
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	setAgrupamentoHeader(agrupamento: string) {
		agrupamento === 'BACIA'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'bacia',
					text: 'Bacia',
					size: 48,
			  })
			: agrupamento === 'MESORREGIAO'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'mesorregiao',
					text: 'Mesorregião',
					size: 48,
			  })
			: agrupamento === 'MICRORREGIAO'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'microrregiao',
					text: 'Microrregião',
					size: 48,
			  })
			: agrupamento === 'MUNICIPIO'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'municipio',
					text: 'Município',
					size: 48,
			  })
			: agrupamento === 'REGIAO_PLUVIOMETRICA'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'regiao',
					text: 'Região Pluviométrica',
					size: 48,
			  })
			: agrupamento === 'SUBBACIA'
			? (this.agrupamentoPluviometriaObsHeader = {
					label: 'subbacia',
					text: 'Sub-bacia',
					size: 48,
			  })
			: '';
	}

	getHeaders(): Array<{ label: string; text: string; size: number }> {
		const headers: Array<{ label: string; text: string; size: number }> = [];

		if (this.agrupamento !== 'MUNICIPIO_POSTO') {
			headers.push(
				this.agrupamentoPluviometriaObsHeader,
				{
					label: 'precipitacaoAcumulada',
					text: 'Pluviometria observada',
					size: 24,
				},
				{ label: 'data', text: 'Data', size: 24 }
			);
		} else {
			headers.push(
				{
					label: 'nome',
					text: 'Município/Posto',
					size: 48,
				},
				{
					label: 'precipitacaoAcumulada',
					text: 'Pluviometria observada',
					size: 24,
				},
				{ label: 'data', text: 'Data', size: 24 },
				{ label: 'latitude', text: 'Latitude', size: 24 },
				{ label: 'longitude', text: 'Longitude', size: 24 },
				{
					label: 'mesorregiao',
					text: 'Mesorregião',
					size: 24,
				},
				{ label: 'microrregiao', text: 'Microrregião', size: 24 },
				{ label: 'regiao', text: 'Região pluviométrica', size: 24 },
				{ label: 'bacia', text: 'Bacia', size: 24 },
				{ label: 'subbacia', text: 'Sub-bacia', size: 24 }
			);
		}

		return headers;
	}

	async exportarPdf() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.agrupamentoResult,
			true
		);
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.pageOrientation = 'portrait';

		documentDefinition.content.push([
			{
				text: `Pluviometria observada (mm) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 500,
				height: 400,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		const widthsTable =
			this.agrupamento === 'MUNICIPIO_POSTO'
				? ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
				: ['40%', '30%', '30%'];

		documentDefinition.content.push(
			{
				text: '',
				alignment: 'center',
				fontSize: 10,
			},
			{
				table: {
					widths: widthsTable,
					body: tableData,
					layout: {
						noWrap: false,
					},
				},
				fontSize: 7,
				marginLeft: 0,
			}
		);
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.agrupamentoResult,
			false
		);

		DocumentExporter.gerarCSV(
			tableData,
			`Pluviometria observada (mm) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.agrupamentoResult,
			false
		);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Pluviometria observada (mm) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `Pluviometria observada (mm) - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};

		const headers = this.getHeaders();
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.agrupamentoResult.map(agrupamento => {
						const periodo = this.getFormItemValue(
							FormularioRelatorio.PERIODO_BUSCA
						) as PeriodosLabel;
						const formatoData =
							periodo === 'anual'
								? 'yyyy'
								: periodo === 'mensal'
								? 'MM/yyyy'
								: 'dd/MM/yyyy';
						const data = agrupamento.data
							? new Date(agrupamento.data?.replace(/\.0/g, '')).toISOString()
							: '';

						return {
							nome: agrupamento.nome
								? corrigeDuplicacaoNome(agrupamento.nome)
								: '-',
							precipitacaoAcumulada: agrupamento.precipitacaoAcumulada
								? numberToBrNumber(agrupamento.precipitacaoAcumulada, 1)
								: '-',
							data: agrupamento.data
								? DateTimeUtils.formatarDataUTC(data, formatoData)
								: '-',
							latitude:
								agrupamento.dadosPosto && agrupamento.dadosPosto.latitude
									? agrupamento.dadosPosto.latitude
									: '-',
							longitude:
								agrupamento.dadosPosto && agrupamento.dadosPosto.longitude
									? agrupamento.dadosPosto.longitude
									: '-',
							mesorregiao:
								agrupamento.dadosPosto && agrupamento.dadosPosto.mesorregiao
									? agrupamento.dadosPosto.mesorregiao
									: agrupamento.nome,
							microrregiao:
								agrupamento.dadosPosto && agrupamento.dadosPosto.microrregiao
									? agrupamento.dadosPosto.microrregiao
									: agrupamento.nome,
							municipio:
								agrupamento.dadosPosto && agrupamento.dadosPosto.municipio
									? agrupamento.dadosPosto.municipio
									: agrupamento.nome,
							regiao:
								agrupamento.dadosPosto && agrupamento.dadosPosto.regiao
									? agrupamento.dadosPosto.regiao
									: agrupamento.nome,
							bacia:
								agrupamento.dadosPosto && agrupamento.dadosPosto.bacia
									? agrupamento.dadosPosto.bacia
									: agrupamento.nome,
							subbacia:
								agrupamento.dadosPosto && agrupamento.dadosPosto.subbacia
									? agrupamento.dadosPosto.subbacia
									: agrupamento.nome,
						};
					}),
					columns: [
						{ key: 'nome' },
						{ key: 'precipitacaoAcumulada' },
						{ key: 'data' },
						{ key: 'latitude', style: { numFmt: '0.0' } },
						{ key: 'longitude', style: { numFmt: '0.0' } },
						{
							key: 'mesorregiao',
						},
						{
							key: 'microrregiao',
						},
						{
							key: 'municipio',
						},
						{
							key: 'regiao',
						},
						{
							key: 'bacia',
						},
						{
							key: 'subbacia',
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo}`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	private handleCalculatePrecipitacaoAcumuladaByResponse(
		response: AgrupamentoResponse[]
	): AgrupamentoResponse[] {
		const mapResponse = new Map<string, AgrupamentoResponse>();

		response.forEach(value => {
			const nome = value.nome.split('/')[0];

			const agrupamento = mapResponse.get(nome) ?? {
				...value,
				precipitacaoAcumulada: 0,
			};
			if (agrupamento.precipitacaoAcumulada) {
				agrupamento.precipitacaoAcumulada += value.precipitacaoAcumulada ?? 0;
			}
			agrupamento.nome = nome;
			mapResponse.set(nome, agrupamento);
		});

		return Array.from(response.values());
	}
}
