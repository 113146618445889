import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import {
	Boletim,
	RespostaBoletimPeriodo,
} from '@home/submodulos/dados-meteorologicos/interfaces/boletim';
import { PeriodoBoletim } from '../enum/PeriodoBoletim';

@Injectable({
	providedIn: 'root',
})
export class BoletimDiarioService {
	constructor(private http: HttpClientProtectedService) {}

	listarBoletinsAgrupadoPorPeriodo(
		dataInicio: Date | null,
		dataFim: Date | null,
		periodo: PeriodoBoletim
	) {
		return this.http.get<RespostaBoletimPeriodo>(
			Rotas.BOLETIM_PUBLICO + '/boletins-agrupado-por-periodo',
			{
				dataInicio: dataInicio?.toISOString() || null,
				dataFim: dataFim?.toISOString() || null,
				periodo,
			}
		);
	}

	consultarUltimoBoletim(): Observable<Boletim> {
		return this.http.get<Boletim>(Rotas.BOLETIM_PUBLICO + `/ultimo-dia-atual`);
	}

	buscarBoletinsPorDataEspecifica(data: any) {
		return this.http.get<Boletim>(
			Rotas.BOLETIM_PUBLICO + `/por-data?data=${data}`
		);
	}
}
