import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'seira-transfer-list',
	templateUrl: './transfer-list.component.html',
	styleUrls: ['./transfer-list.component.scss'],
})
export class TransferListComponent<T extends Record<string, any>>
	implements OnChanges
{
	@Input() inputList: T[] = [];
	@Input() outputList: T[] = [];
	@Output() outputListChange = new EventEmitter<T[]>();
	@Input() inputLabel = '';
	@Input() outputLabel = '';
	@Input() labelProperty = 'label';
	@Input() valueProperty = 'value';
	@Input() loading = false;
	@Input() disabled = false;
	@Input() required = false;

	dirty = false;
	form: FormGroup = this.formBuilder.group({
		inputFilter: new FormControl(''),
		outputFilter: new FormControl(''),
	});

	constructor(private readonly formBuilder: FormBuilder) {}

	get filteredInputList() {
		const search = this.form.get('inputFilter')?.value;
		return this.inputList.filter(item => this.searchFilter(item, search));
	}

	get filteredOutputList() {
		const search = this.form.get('outputFilter')?.value;
		return this.outputList.filter(item => this.searchFilter(item, search));
	}

	searchFilter(item: T, search: string) {
		return item[this.labelProperty]
			.toLowerCase()
			.includes(search.toLowerCase());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['inputList'] || changes['outputList']) {
			const inputValues = this.inputList.reduce(
				(acc, item) => {
					acc[item[this.labelProperty]] = new FormControl(false);
					return acc;
				},
				{} as Record<string, FormControl>
			);
			const outputValues = this.outputList.reduce(
				(acc, item) => {
					acc[item[this.labelProperty]] = new FormControl(false);
					return acc;
				},
				{} as Record<string, FormControl>
			);

			for (const [key, value] of Object.entries({
				...inputValues,
				...outputValues,
			})) {
				if (!this.form.contains(key)) {
					this.form.addControl(key, value);
				}
			}
		}
	}

	addSelectedItems() {
		const { inputFilter, outputFilter, ...items } = this.form.getRawValue();

		Object.entries(items).forEach(([label, value]) => {
			if (!value) return;

			const item = this.inputList.find(
				item => item[this.labelProperty] === label
			);

			if (!item) return;

			this.outputList.push(item);
			this.outputListChange.emit(this.outputList);
			this.inputList.splice(this.inputList.indexOf(item), 1);
			this.form.get(label)?.setValue(false);
			this.sortAlphabetically();
		});

		this.dirty = true;
	}

	removeSelectedItems() {
		const { inputFilter, outputFilter, ...items } = this.form.getRawValue();

		Object.entries(items).forEach(([label, value]) => {
			if (!value) return;

			const item = this.outputList.find(
				item => item[this.labelProperty] === label
			);

			if (!item) return;

			this.inputList.push(item);
			this.outputList.splice(this.outputList.indexOf(item), 1);
			this.outputListChange.emit(this.outputList);
			this.form.get(label)?.setValue(false);
			this.sortAlphabetically();
		});

		this.dirty = true;
	}

	addAllItems() {
		this.outputList = this.inputList;
		this.inputList = [];
		this.outputListChange.emit(this.outputList);
		this.dirty = true;
	}

	removeAllItems() {
		this.inputList.push(...this.outputList);
		this.sortAlphabetically();
		this.outputList = [];
		this.outputListChange.emit(this.outputList);
		this.dirty = true;
	}

	sortAlphabetically() {
		this.inputList.sort((a, b) =>
			a[this.labelProperty].localeCompare(b[this.labelProperty])
		);
		this.outputList.sort((a, b) =>
			a[this.labelProperty].localeCompare(b[this.labelProperty])
		);
	}

	get showRequiredMessage() {
		return this.required && this.dirty && !this.outputList.length;
	}
}
