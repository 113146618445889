<div class="row mt-3 mb-5">
	<seira-public-pagination [items]="relatorios" [itemsPerPage]="5">
		<ng-template let-item="item">
			<div class="mb-2">
				<label
					for="tabela-precipitacao-por-posto"
					class="text-tertiary mb-2 fw-semibold">
					{{
						item.posto === item.municipio
							? item.municipio + ' - ' + item.tipo
							: item.municipio + ' - ' + item.posto + ' - ' + item.tipo
					}}
				</label>
				<seira-public-table
					id="tabela-precipitacao-por-posto"
					[hasFooter]="false"
					[hasHeader]="false"
					[info]="false"
					[paging]="false"
					[searching]="false"
					[columns]="colunas"
					[scrollX]="true"
					[data]="item.data" />
			</div>
		</ng-template>
	</seira-public-pagination>
</div>
