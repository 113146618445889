<div class="d-flex flex-colum justify-content-end mb-2">
	<seira-public-button-group
		[buttons]="botoesDeExportacao"></seira-public-button-group>
</div>
<div
	*ngIf="carregandoRelatorio"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<div class="d-flex flex-column w-100" *ngIf="!carregandoRelatorio">
	<label class="text-tertiary fw-semibold mt-3">
		RELATÓRIO DE VALORES EXTREMOS
	</label>
	<div
		class="max-width: 1300px"
		*ngIf="dadosTabelaValoresExtremos; else semDados">
		<seira-card-info
			aditionalClasses="mt-3"
			texto="Os valores extremos se referem aos valores máximos e mínimos de chuva,
			temperatura, umidade, pressão, radiação, evapotranspiração, velocidade do
			vento a 2 metros e velocidade do vento a 10 metros registrados em um
			determinado período, local ou região.">
		</seira-card-info>
		<seira-tabela-relatorio-valores-extremos
			#tabelaValoresExtremos
			[resultado]="dadosTabelaValoresExtremos"
			[form]="form">
		</seira-tabela-relatorio-valores-extremos>
	</div>
</div>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
