import { Component, inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
	AgrupamentoResponse,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	RelatorioDesvio,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { Subject, takeUntil } from 'rxjs';
import { Validators } from '@angular/forms';
import { format } from 'date-fns';
import { formataValorPrecipitacao, numberToBrNumber } from '@utils';
import { corrigeDuplicacaoNome } from '@home/submodulos/dados-meteorologicos/utils';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ToastrService } from 'ngx-toastr';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';
import { Select } from '@layout/interfaces/select';
import { Agrupamento } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';

@Component({
	selector: 'seira-tabela-desvio-climatologia-pluviometria-observada',
	templateUrl:
		'./tabela-desvio-climatologia-pluviometria-observada.component.html',
	styleUrls: [
		'./tabela-desvio-climatologia-pluviometria-observada.component.scss',
	],
})
export class TabelaDesvioClimatologiaPluviometriaObservadaComponent
	implements OnInit, InstanciaRelatorio, OnDestroy
{
	inputs = inject(INPUTS_RELATORIOS);
	resultado: any[] = [];
	colunas: ADTColumns[] = [];
	carregando = false;
	periodo = '';
	tituloTabela = 'Pluviometria Observada/Climatologia/Desvios';
	texto =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. Os desvios referem-se à variação da precipitação em relação à sua climatologia, que indica se o volume de chuva foi superior ou inferior ao esperado. A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';
	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaDesvioClimatologiaObservado', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	incluirDetalhes = false;

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];

	agrupamento: Select<string>[];

	_destroyed = new Subject();
	tituloColunaAgrupamento = '';
	disabledSelectList = false;
	constructor(
		private toastr: ToastrService,
		private readonly relatorioService: RelatoriosService
	) {
		this.setValidators();
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.clearValidators();
		this._destroyed.next(undefined);
	}

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	atualizarTituloColuna() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		this.tituloColunaAgrupamento =
			Agrupamento[agrupamento as keyof typeof Agrupamento];
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: null,
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-left',
					render: (data: AgrupamentoResponse) => {
						return data ? corrigeDuplicacaoNome(data.nome) : '-';
					},
				},
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},

				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},

				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 1) : '-'),
				},

				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},

				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},

				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'data',
					title: 'Data',
					className: 'text-start',
					render: (data: any, type: string) => {
						const dataCorrigida = data.replace(/\.0/g, '');
						const periodoAnual = dataCorrigida.length === 4;
						if (periodoAnual) {
							return dataCorrigida;
						}
						return type === 'display'
							? DateTimeUtils.formatarData(dataCorrigida, 'MM/yyyy')
							: data;
					},
				},
				{
					data: 'precipitacaoAcumulada',
					render: (data: number | string) => {
						return data;
					},
					title: 'Pluviometria observada (mm)',
					className: 'text-center',
				},
				{
					data: 'desvioMilimetro',
					render: (data: any) => {
						return data;
					},
					title: 'Desvio (mm)',
					className: 'text-center',
				},
				{
					data: 'desvioPorcentagem',
					render: (data: any) => {
						return data;
					},
					title: 'Desvio (%)',
					className: 'text-center',
				},
				{
					data: 'climatologia',
					title: 'Climatologia (mm)',
					className: 'text-center',
				},
			]
		);
	}

	clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	extrairDatasResultado(
		preciptacaoAgrupada: RelatorioDesvio,
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacaoAgrupada.desvios
					.filter((el: any) => el.data)
					.map((el: any) => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a: any, b: any) => +a - +b)
					.map((data: any) => {
						let formato = 'MM/yyyy';
						if (periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato);
					})
			),
		];
	}

	gerarRelatorio(): void {
		this.carregando = true;
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		const diaInicio =
			periodoAgrupamento !== 'anual'
				? dataInicio
				: new Date(`${new Date(dataInicio).getFullYear()}-01-01T00:00:00`);
		const diaFim =
			periodoAgrupamento !== 'anual'
				? dataFim
				: new Date(`${new Date(dataFim).getFullYear()}-12-31T23:59:59`);

		this.periodo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		let id = null;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.incluirDetalhes = false;
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				id = this.inputs.form.get('estacao')?.value;
				this.incluirDetalhes = true;
				break;
			case 'MICRORREGIAO':
				id = this.inputs.form.get('microrregiao')?.value;
				break;
			case 'MESORREGIAO':
				id = this.inputs.form.get('mesorregiao')?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.inputs.form.get('regiao')?.value;
				break;
			case 'BACIA':
				id = this.inputs.form.get('bacia')?.value;
				break;
			case 'MUNICIPIO':
				id = this.inputs.form.get('municipio')?.value;
				break;
		}

		this.inputs.setLoading(true);

		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento,
				idEntidade: id,
				dataFim: new Date(diaFim),
				dataInicio: new Date(diaInicio),
				periodo: periodoAgrupamento.toUpperCase(),
				incluirDetalhes: this.incluirDetalhes,
			})
			.subscribe({
				next: (precipitacoesAgrupadas: any) => {
					this.resultado = [];
					this.inputs.setLoading(false);
					this.carregando = false;

					if (!precipitacoesAgrupadas.length) return;

					const agrupamento = this.normalizarAgrupamento(
						this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO)
					);

					this.atualizarTituloColuna();
					this.atualizarColunasTabela();

					const dataResultado = this.extrairDatasResultado(
						precipitacoesAgrupadas[0],
						periodoAgrupamento
					);
					this.resultado =
						periodoAgrupamento === 'anual'
							? this.calcularDadosAnuais(
									precipitacoesAgrupadas[0][agrupamento],
									precipitacoesAgrupadas[0],
									dataResultado,
									agrupamento
							  )
							: this.formatarResultados(precipitacoesAgrupadas[0], agrupamento);
				},
				error: () => {
					this.inputs.setLoading(false);
					this.carregando = false;
				},
			});
	}

	private normalizarAgrupamento(agrupamento: string): string {
		agrupamento = agrupamento.toLowerCase();
		if (agrupamento === 'regiao_pluviometrica') {
			return 'regiaoPluviometrica';
		}
		if (agrupamento === 'subbacia') {
			return 'subBacia';
		}
		return agrupamento;
	}

	private calcularDadosAnuais(
		nome: string,
		resultadoDesvios: RelatorioDesvio,
		dataResultado: string[],
		agrupamento: string
	) {
		return dataResultado.map(ano => {
			const dadosDoAno = resultadoDesvios.desvios.filter((item: any) =>
				item.data.startsWith(ano)
			);
			const calculos = this.calcularTotaisAnuais(dadosDoAno);
			return {
				nome,
				data: ano,
				...calculos,
				...(agrupamento === 'municipio_posto'
					? this.obterDetalhesPosto(resultadoDesvios)
					: {}),
			};
		});
	}

	private calcularTotaisAnuais(dados: any[]) {
		const chuvaAnual = dados.reduce((total, item) => total + item.chuva, 0);
		const climatologiaAnual = dados.reduce(
			(total, item) => total + item.climatologia,
			0
		);
		const desvioMilimetroAnual = dados.reduce(
			(total, item) => total + item.desvioMilimetro,
			0
		);
		const desvioPorcentagemAnual =
			climatologiaAnual !== 0
				? (desvioMilimetroAnual / climatologiaAnual) * 100
				: 0;

		return {
			precipitacaoAcumulada: formataValorPrecipitacao(chuvaAnual) || '-',
			climatologia: formataValorPrecipitacao(climatologiaAnual) || '-',
			desvioMilimetro: formataValorPrecipitacao(desvioMilimetroAnual) || '-',
			desvioPorcentagem:
				formataValorPrecipitacao(desvioPorcentagemAnual) || '-',
		};
	}

	private obterDetalhesPosto(dadosPosto: any) {
		return {
			nome: `${dadosPosto.municipio}/${dadosPosto.estacao}`,
			municipio: dadosPosto.municipio,
			latitude: dadosPosto.latitude,
			longitude: dadosPosto.longitude,
			altitude: dadosPosto.altitude,
			microrregiao: dadosPosto.microrregiao,
			mesorregiao: dadosPosto.mesorregiao,
			regiao: dadosPosto.regiaoPluviometrica,
			bacia: dadosPosto.bacia,
			subbacia: dadosPosto.subBacia,
		};
	}

	private formatarResultados(resultados: any, agrupamento: string) {
		return resultados.desvios.map((resultado: any) => {
			if (agrupamento === 'municipio_posto') {
				return {
					...this.obterDetalhesPosto(resultados),
					data: resultado.data,
					desvioMilimetro: formataValorPrecipitacao(resultado.desvioMilimetro),
					desvioPorcentagem: numberToBrNumber(resultado.desvioPorcentagem),
					climatologia: formataValorPrecipitacao(resultado.climatologia),
					precipitacaoAcumulada: formataValorPrecipitacao(resultado.chuva),
				};
			}
			return {
				nome: resultados[agrupamento],
				data: resultado.data,
				desvioMilimetro:
					formataValorPrecipitacao(resultado.desvioMilimetro) || '-',
				desvioPorcentagem: numberToBrNumber(resultado.desvioPorcentagem) || '-',
				climatologia: formataValorPrecipitacao(resultado.climatologia) || '-',
				precipitacaoAcumulada: formataValorPrecipitacao(resultado.chuva) || '-',
			};
		});
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	async exportarTXT() {
		let txtData = '';

		const tableData = this.prepararDadosParaTabela();

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}
		DocumentExporter.gerarTXT(
			txtData,
			`Relatório de Pluviometria Observada/Climatologia/Desvios -(${this.periodo})`
		);
	}

	exportarCSV() {
		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			DocumentExporter.gerarCSV(
				tableData,
				`Relatório de Pluviometria Observada/Climatologia/Desvios -(${this.periodo})`
			);
		}
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';
		this.adicionarTitulo(documentDefinition);

		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			this.adicionarTabelaAoDocumento(documentDefinition, tableData);
		} else {
			this.adicionarMensagemSemDados(documentDefinition);
		}

		this.gerarDocumentoPDF(documentDefinition);
	}

	private adicionarTitulo(documentDefinition: any): void {
		documentDefinition.content.push({
			text: `Relatório de Pluviometria Observada/Climatologia/Desvios - ${this.periodo}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});
	}

	private temDadosParaTabela(): boolean {
		return !!this.resultado && !!this.tabela;
	}

	private prepararDadosParaTabela(): string[][] {
		const visibleColumns = this.getVisibleColumns();
		const tableData = [visibleColumns];

		this.tabela?.data.forEach((row: any) => {
			const rowData = this.prepararDadosDaLinha(row, visibleColumns);
			tableData.push(rowData);
		});

		return tableData;
	}

	private getVisibleColumns(): string[] {
		return this.tabela!.columns.map((col: any) => col.title as string);
	}

	private prepararDadosDaLinha(row: any, visibleColumns: string[]): string[] {
		return visibleColumns.map(columnTitle => {
			if (columnTitle === 'Município/Posto') {
				return this.formatarMunicipioPosto(row);
			} else {
				return this.getCellValue(row, columnTitle);
			}
		});
	}

	private formatarMunicipioPosto(row: any): string {
		return corrigeDuplicacaoNome(row.nome);
	}

	private getCellValue(row: any, columnTitle: string): string {
		const column = this.tabela!.columns.find(
			(col: any) => col.title === columnTitle
		);
		if (column && column.data) {
			return this.extractNestedValue(row, column.data as string) ?? '-';
		}
		return '-';
	}

	private extractNestedValue(obj: any, path: string): any {
		return path.split('.').reduce((value, key) => {
			if (key === 'data') {
				return format(new Date(value?.[key]), 'dd/MM/yyyy');
			}
			return value?.[key];
		}, obj);
	}

	private adicionarTabelaAoDocumento(
		documentDefinition: any,
		tableData: string[][]
	): void {
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: 'lightHorizontalLines',
			},
		});
	}

	private adicionarMensagemSemDados(documentDefinition: any): void {
		documentDefinition.content.push({
			text: 'Nenhum dado encontrado na tabela',
			alignment: 'center',
			fontSize: 10,
			margin: [0, 10],
		});
	}

	private gerarDocumentoPDF(documentDefinition: any): void {
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}
}
