import { Component, inject, OnDestroy } from '@angular/core';
import {
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS_QUANTIS,
	InstanciaRelatorio,
	QuantisRegiaoAnualTableValores,
	QuantisRegiaoMensalTableValores,
	QuantisResponse,
	Relatorios,
} from '../../../interfaces';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { QuantisMicrorregiaoService } from '../../../services';
import { Subject, takeUntil } from 'rxjs';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { CondicaoMap } from '../../../utils';
import { isNotNuloOuUndefined, numberToBrNumber, verificaSePDF } from '@utils';
import { Validators } from '@angular/forms';
import { DateTimeUtils } from '@utils/datetime-util';
import { DataModel } from 'mr-excel';
import * as ExcelTable from 'mr-excel';
import { Content } from 'pdfmake/interfaces';
import { Meses } from '../../../enum';
import { DocumentExporter } from '@utils/document-exporter';

@Component({
	selector: 'seira-pluviometro-tabela-quantis',
	templateUrl: './pluviometro-tabela-quantis.component.html',
	styleUrls: ['./pluviometro-tabela-quantis.component.scss'],
})
export class PluviometroTabelaQuantisComponent
	implements OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como desvio (mm), a diferença entre a quantidade de chuva registrada e a sua climatologia para um mesmo período, em um determinado local ou região.';

	descricaoRelatorioAnual =
		'Define-se como quantis (%) representa a variação da precipitação em relação à climatologia expresso em porcentagem, indicando se o acumulado de chuva foi superior ou inferior à climatologia.';

	inputs = inject(INPUTS_RELATORIOS_QUANTIS);
	estacoes?: Estacao[] | undefined;
	isLoading = true;
	tableData?: Relatorios<
		QuantisRegiaoMensalTableValores | QuantisRegiaoAnualTableValores
	>;
	city = '';
	year = 0;
	isAnual = false;
	quantisType = '';
	periodoTitulo = '';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	private _destroyed = new Subject();

	constructor(private readonly quantisService: QuantisMicrorregiaoService) {
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.inputs.form?.invalid) {
			return;
		}
		const estacao = this.inputs.form?.get('estacao')?.value;
		const periodo = this.inputs.form?.get('periodo')?.value;
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);

		this.descricaoRelatorio =
			tipoInput?.value === 'QUANTIS_MENSAL'
				? this.descricaoRelatorioMensal
				: this.descricaoRelatorioAnual;

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			periodo,
			'',
			'anual',
			true
		);

		if (!estacao || !periodo || !this.estacoes?.length) {
			return;
		}
		this.handleFetchQuantisByType(tipoInput?.value, estacao, periodo);
		tipoInput?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: values => {
				this.handleFetchQuantisByType(values, estacao, periodo);
			},
		});
	}

	handleFetchQuantisByType(type: string, estacao: number, periodo: string) {
		if (type === 'QUANTIS_MENSAL') {
			this.quantisType = 'Quantis mensal';
			this.handleFetchQuantisMensal(estacao, periodo);
		}
		if (type === 'QUANTIS_ANUAL') {
			this.quantisType = 'Quantis anual';
			this.handleFetchQuantilAnual(estacao, periodo);
		}
	}

	handleFetchQuantisMensal(estacao: number, periodo: string) {
		this.isAnual = false;
		this.inputs.form.get(FormularioRelatorio.LOADING_GERAR)?.setValue(true);
		this.isLoading = true;
		this.quantisService
			.handleFetchQuantisMensalTableByEstacaoId(
				estacao,
				new Date(periodo).getFullYear()
			)
			.subscribe({
				next: val => {
					this.city = val.municipio!;
					this.year = val.ano;
					this.tableData = this.handleFormatToTableData(val);
				},
				complete: () => {
					this.inputs.form
						.get(FormularioRelatorio.LOADING_GERAR)
						?.setValue(false);
					this.isLoading = false;
				},
			});
	}

	handleFetchQuantilAnual(estacao: number, periodo: string) {
		this.isAnual = true;
		this.inputs.form.get(FormularioRelatorio.LOADING_GERAR)?.setValue(true);
		this.isLoading = true;
		this.quantisService
			.handleFetchQuantilAnualTableByEstacaoId(
				estacao,
				new Date(periodo).getFullYear()
			)
			.subscribe({
				next: val => {
					this.city = val.municipio!;
					this.year = val.ano;
					this.tableData = this.handleFormatToTableData(val);
				},
				complete: () => {
					this.inputs.form
						.get(FormularioRelatorio.LOADING_GERAR)
						?.setValue(false);
					this.isLoading = false;
				},
			});
	}

	handleFormatToTableData(
		originalData: QuantisResponse<
			QuantisRegiaoMensalTableValores[] | QuantisRegiaoAnualTableValores[]
		>
	): Relatorios<
		QuantisRegiaoMensalTableValores | QuantisRegiaoAnualTableValores
	> {
		return {
			data: originalData.quantis,
			municipio: originalData.municipio!,
			ano: originalData.ano.toString(),
		};
	}

	async exportarPDF() {
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);
		let documentDefinition;

		if (tipoInput?.value === 'QUANTIS_ANUAL') {
			documentDefinition = await pdfseira.documentDefinitions();
			documentDefinition.content = this.gerarConteudoPDFAnual();
		} else {
			documentDefinition = await pdfseira.documentDefinitions('landscape');
			documentDefinition.pageOrientation = 'landscape';
			documentDefinition.content = this.gerarConteudoPDFMensal();
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(false);

		DocumentExporter.gerarCSV(
			tableData,
			`${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};
		const headers = this.getHeadersXLSX();
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data?.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarQuantisParaXLSX(),
					columns: [
						{ key: 'tipo' },
						{
							key: 'valor',
							style: { numFmt: '0.0' },
						},
						...Array.from({ length: 12 }, (_, i) => ({
							key: i + 1,
							style: { numFmt: '0.0' },
						})),
					],
					title: {
						consommeRow: 3,
						consommeCol: this.quantisType === 'Quantis anual' ? 2 : 13,
						text: nomeArquivo,
						styleId: 'title',
						alignment: 'center',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	retornarQuantisParaXLSX() {
		if (this.quantisType === 'Quantis anual') {
			const data = this.tableData as Relatorios<QuantisRegiaoAnualTableValores>;
			return data.data.map(data => {
				return {
					tipo: CondicaoMap.get(data.tipo)!,
					valor: this.formatNumber(data.valor, data.tipo === 'observado'),
				};
			});
		} else {
			const data = this
				.tableData as Relatorios<QuantisRegiaoMensalTableValores>;
			return data.data.map(data => {
				const ret = {
					tipo: CondicaoMap.get(data.tipo)!,
					...Array.from({ length: 12 }, (_, i) => ({
						[i + 1]: this.formatNumber(
							data.valores[i] || 0,
							data.tipo === 'observado'
						),
					})).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
				};
				return ret;
			});
		}
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];

		if (this.quantisType === 'Quantis anual') {
			const colunas = [
				{ text: 'Tipo', fillColor: '#DCDCDC' },
				{ text: 'Valor (mm)', fillColor: '#DCDCDC' },
			];

			verificaSePDF(tableData, colunas, isPdf);
			tableData.push(...this.getDadosTabelaParaExportacaoAnual());
		} else {
			const colunas = [
				{ text: 'Tipo', fillColor: '#DCDCDC' },
				...Array.from({ length: 12 }, (_, i) => ({
					text: `${Meses[i]} (mm)`,
					fillColor: '#DCDCDC',
				})),
			];

			verificaSePDF(tableData, colunas, isPdf);
			tableData.push(...this.getDadosTabelaParaExportacaoMensal());
		}

		return tableData;
	}

	private gerarConteudoPDFAnual(): Content {
		const response: any = [
			{
				text: `Quantis Anual - ${this.tableData?.municipio} - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 0, 0, 15],
			},
			{
				table: {
					widths: ['50%', '50%'],
					body: this.getDadosTabelaParaExportacao(true),
					layout: {
						noWrap: false,
						fontSize: 5,
					},
				},
			},
		];

		return response;
	}

	private gerarConteudoPDFMensal(): Content {
		const response: any = [
			{
				text: `Quantis Mensal - ${this.tableData?.municipio} - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 0, 0, 15],
			},
			{
				table: {
					body: this.getDadosTabelaParaExportacao(true),
					layout: {
						noWrap: false,
						fontSize: 5,
					},
				},
			},
		];

		return response;
	}

	private getDadosTabelaParaExportacaoAnual() {
		const rowsData: (string | number)[][] = [];
		const data = this.tableData as Relatorios<QuantisRegiaoAnualTableValores>;

		data.data.forEach(data =>
			rowsData.push([
				CondicaoMap.get(data.tipo)!,
				this.formatNumber(data.valor, data.tipo === 'observado'),
			])
		);

		return rowsData;
	}

	private getDadosTabelaParaExportacaoMensal() {
		const rowsData: (string | number)[][] = [];
		const data = this.tableData as Relatorios<QuantisRegiaoMensalTableValores>;

		data.data.forEach(data =>
			rowsData.push([
				CondicaoMap.get(data.tipo)!,
				...Array.from({ length: 12 }, (_, i) =>
					this.formatNumber(data.valores[i] || 0, data.tipo === 'observado')
				),
			])
		);

		return rowsData;
	}

	private getHeadersXLSX(): Array<{
		label: string;
		text: string;
		size: number;
	}> {
		const headers: Array<{ label: string; text: string; size: number }> = [];

		if (this.quantisType === 'Quantis anual') {
			headers.push(
				{
					label: 'tipo',
					text: 'Tipo',
					size: 24,
				},
				{
					label: 'valor',
					text: 'Valor (mm)',
					size: 24,
				}
			);
		} else {
			headers.push(
				{
					label: 'tipo',
					text: 'Tipo',
					size: 24,
				},
				...Array.from({ length: 12 }, (_, i) => ({
					label: (i + 1).toString(),
					text: `${Meses[i]} (mm)`,
					size: 12,
				}))
			);
		}

		return headers;
	}

	private formatNumber(value: number, ehObservado = false): string | number {
		let retorno = '';
		if (ehObservado) {
			const valorFormatado = numberToBrNumber(value, 1);
			return valorFormatado ?? '';
		}
		retorno = isNotNuloOuUndefined(value)
			? '≤ ' + numberToBrNumber(value, 1)
			: '-';
		return retorno === '≤ 0,0' ? '0,0' : retorno;
	}
}
