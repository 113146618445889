import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { FormGroup, Validators } from '@angular/forms';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import { EstacaoUltimasMedicoes } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/estacao-ultimas-medicoes';

import { TipoPCD } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { DateTimeUtils, Periodo } from '@utils/datetime-util';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { decapitalizeFirstLetter, numberToBrNumber } from '@utils';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { VariaveisService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/services/variaveis.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	LatLngValue,
} from '@utils/interpolacao/interpolacao';
import {
	getFormattedVariavel,
	getLabelCard,
	getUnidade,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/variaveis';
import { FeatureCollection, Polygon } from 'geojson';
import { Position } from 'pdfmake/interfaces';
import * as Leaflet from 'leaflet';
import { MapaInterpolacaoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/mapa-interpolacao/mapa-interpolacao.component';
import {
	OpcaoPagina,
	PublicPageOptionsComponent,
} from '@componentes/public-page-options/public-page-options.component';

@Component({
	selector: 'seira-mapa-interpolacao-estacoes-pcds',
	templateUrl: './mapa-interpolacao-estacoes-pcds.component.html',
	styleUrls: ['./mapa-interpolacao-estacoes-pcds.component.scss'],
})
export class MapaInterpolacaoEstacoesPCDSComponent
	implements InstanciaRelatorio, OnInit, OnDestroy, AfterViewInit
{
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPdf(),
		},

		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCsv(),
		},
		{
			label: 'mapa',
			size: 'small',
			icon: 'ph-file-jpg',
			onClick: () => this.exportarImagem('jpeg'),
		},
	];

	estacoes: Estacao[] = [];
	form!: FormGroup;

	postos: PostosRelatorios[] = [];

	periodo!: Periodo;
	variavel?: string = '';
	mapaNome = '';
	inputs = inject(INPUTS_RELATORIOS);
	medicoes: EstacaoUltimasMedicoes[] = [];
	@ViewChild('legenda') legendaMapaMobile!: TemplateRef<any>;
	@ViewChild(MapaInterpolacaoComponent) mapa!: MapaInterpolacaoComponent;
	unidade?: string = '';
	interpolacao?: InterpolacaoCanvasBounds;
	carregando = false;
	valores: EstacaoUltimasMedicoes[] = [];
	map!: Leaflet.Map;
	colunasTabelaEstacoesPCDs: Array<DataTables.ColumnSettings> = [
		{
			title: 'Município/Posto',
			data: null,
			className: 'text-tertiary  text-center',
			render: function (row) {
				return row.municipio + '/' + row.nomePosto;
			},
		},
		{
			title: 'Tipo de PCD',
			data: 'tipoPCD',
			className: 'text-tertiary  text-center',
			render: function (data: keyof typeof TipoPCD) {
				return TipoPCD[data] || null;
			},
		},
		{
			title: 'Valor mínimo',
			data: 'valorMin',
			render: function (valorMin: number, type: string) {
				const valor = Number(valorMin).toFixed(2);
				return type === 'display' ? numberToBrNumber(valor) : valor;
			},
			className: 'text-tertiary  text-center',
		},
		{
			title: 'Valor máximo',
			data: 'valorMax',
			render: function (valorMax: number, type: string) {
				const valor = Number(valorMax).toFixed(2);
				return type === 'display' ? numberToBrNumber(valor) : valor;
			},
			className: 'text-tertiary  text-center',
		},
		{
			title: 'Total observado',
			data: 'valor',
			render: function (valorMedia: any, type: string) {
				const valor = Number(valorMedia).toFixed(2);
				return type === 'display' ? numberToBrNumber(valor) : valor;
			},
			className: 'text-tertiary  text-center',
		},
	];
	disabled = false;
	regiao?: Position[][];
	buscaPersonalizada = false;
	protected readonly decapitalizeFirstLetter = decapitalizeFirstLetter;
	opcoesDaPagina: OpcaoPagina<any>[] = [];
	constructor(
		private httpClient: HttpClient,
		private variavelService: VariaveisService,
		private readonly modalService: BsModalService,
		private readonly toastr: ToastrService,
		private cdr: ChangeDetectorRef
	) {
		(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.VARIAVEL)
			?.setValidators(Validators.required);
		this.form?.get('variavel')?.setValue('precipitacao');
	}
	ngOnDestroy(): void {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.form.get(FormularioRelatorio.PRECIPITACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.JANELA_TEMPO)?.clearValidators();
		this.form.get(FormularioRelatorio.VARIAVEL)?.clearValidators();
		this.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
	}
	janelaTempoIsPersonalizada() {
		const campoControl = this.form.get('dataFim')!;
		campoControl.setValidators([Validators.required]);

		this.form.valueChanges.subscribe({
			next: value => {
				this.rangeDataValid();
				return value.janelaDeTempo === 'Personalizada'
					? (this.buscaPersonalizada = true)
					: (this.buscaPersonalizada = false);
			},
		});
	}
	ngAfterViewInit() {
		this.janelaTempoIsPersonalizada();
		this.adicionarOpcoesDaPagina();
	}
	rangeDataValid() {
		const dataFinal = this.form.get('dataFim')?.value;

		if (dataFinal === null) {
			this.disabled = true;
			return false;
		}
		this.disabled = false;
		return true;
	}
	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
			this.gerarRelatorio();
		});
		this.periodo = DateTimeUtils.getPeriodoPorHoras(6);
	}
	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
	}
	gerarRelatorio(): void {
		const variavel = this.form.get('variavel')?.value;
		this.carregando = true;
		this.unidade = getUnidade(variavel);
		this.variavel = this.getVariavelFromValor(variavel);
		this.mapaNome = getFormattedVariavel(variavel);
		const janelaTempo = this.form.get('janelaDeTempo')?.value;

		this.carregando = true;
		if (this.buscaPersonalizada && this.rangeDataValid()) {
			const dataInicial = this.form.get('dataInicio')?.value;
			const dataFinal = this.form.get('dataFim')?.value;
			this.periodo.diaInicio = dataInicial;
			this.periodo.diaFim = dataFinal;
			this.variavelService
				.medicoesPersonalizada(this.periodo, variavel)
				.subscribe({
					next: resp => {
						this.medicoes = resp;
						this.carregando = false;
						this.interpolarValores();
					},
				});
		} else {
			this.variavelService
				.ultimasMedicoes(janelaTempo, variavel.toLowerCase())
				.subscribe({
					next: resp => {
						this.medicoes = resp;

						this.carregando = false;

						this.interpolarValores();
						this.definirJanelaDeTempo();
					},
					error: (err: HttpErrorResponse) => {
						this.toastr.error(err.message, 'Erro ao gerar mapa');
						this.carregando = false;
					},
					complete: () => {
						this.carregando = false;
					},
				});
		}
	}
	interpolarValores() {
		this.carregando = true;
		if (this.medicoes.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;

				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}
	criarImagemInterpolacao() {
		const variavel = this.form.get('variavel')?.getRawValue();

		const valores: LatLngValue[] = this.medicoes.map(value => {
			const valorTipoPCD = value.tipoPCD;
			return {
				lat: value.latitude,
				lng: value.longitude,
				value: value.valor,
				municipio: value.municipio,
				valorMax: value.valorMax,
				valorMin: value.valorMin,
				nomePosto: value.nomePosto,
				tipoPCD: this.labelTipoPcd(valorTipoPCD),
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				variavel,
				this.regiao as number[][][],
				0.01
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	definirJanelaDeTempo() {
		const janelaDeTempo = this.form.get('janelaDeTempo')!.value;

		switch (janelaDeTempo) {
			case '6':
				return (this.periodo = DateTimeUtils.getPeriodoPorHoras(6));
			case '24':
				return (this.periodo = DateTimeUtils.getPeriodoPorHoras(24));
			case '48':
				return (this.periodo = DateTimeUtils.getPeriodoPorHoras(48));
			case '72':
				return (this.periodo = DateTimeUtils.getPeriodoPorHoras(72));
			default:
				return;
		}
	}
	getVariavelFromValor(variavel: string): string | undefined {
		return getLabelCard(variavel);
	}
	exportarCsv() {
		const header = [
			['Agência Executiva de Gestão das Águas do Estado da Paraíba\n\n'],
			[`Relatório - Estações automáticas - ${this.getPeriodoFormatado()}\n\n`],
			[`Variável: ${this.variavel}\n`],
		];

		const tableData = this.getDadosTabelaParaExportacao(this.medicoes);

		const csvContent = header.concat(tableData);
		const csvString = csvContent.map(row => row.join(';')).join('\n');

		DocumentExporter.gerarCSV(
			csvString,
			`relatorio-estacoes-automaticas-${
				this.variavel
			}-${this.getPeriodoFormatado()}`
		);
	}
	getPeriodoFormatado(): string {
		const diaInicio = DateTimeUtils.formatarISOParaFormato24hrs(
			this.periodo.diaInicio.toISOString()
		);
		const diaFim = DateTimeUtils.formatarISOParaFormato24hrs(
			this.periodo.diaFim.toISOString()
		);

		return `${diaInicio} a ${diaFim}`;
	}
	async exportarPdf() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push([
			{
				text: `Relatório - Estações automáticas - ${this.getPeriodoFormatado()}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},

			{ text: `Variável: ${this.variavel}`, margin: [0, 10, 0, 10] },
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.medicoes);
		documentDefinition.content.push({
			table: {
				body: tableData,
				alignment: 'center',
				layout: {
					noWrap: false,
					fontSize: 2,
				},
			},
		} as any);
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}
	getDadosTabelaParaExportacao(dados: EstacaoUltimasMedicoes[]) {
		let variavel;
		this.colunasTabelaEstacoesPCDs.filter(coluna => {
			if (coluna.data === 'valor') {
				variavel = coluna.title;
			}
		});
		const tableData: any[][] = [];
		const colunas = [
			'Município/Posto',
			'Tipo de PCD',
			'Valor mínimo',
			'Valor máximo',
			variavel,
		];
		tableData.push(colunas);

		dados.forEach((item: EstacaoUltimasMedicoes) => {
			const rowData = [
				item.municipio + '/' + item.nomePosto,
				this.labelTipoPcd(item.tipoPCD),
				numberToBrNumber(item.valorMin),
				numberToBrNumber(item.valorMax),
				numberToBrNumber(item.valor, 2),
			];
			tableData.push(rowData);
		});

		return tableData;
	}
	labelTipoPcd(tipo: string | null) {
		if (tipo === 'METEOROLOGICA') {
			return 'Meteorológica';
		} else if (tipo === 'AGROMETEOROLOGICA') {
			return 'Agrometeorológica';
		}
		return null;
	}
	exportarImagem(extensao: string) {
		const imageElement = document.getElementById('mapa-interpolacao');
		html2canvas(imageElement!).then(canvas => {
			const imageDataUrl = canvas.toDataURL('image/' + extensao);
			const link = document.createElement('a');
			link.href = imageDataUrl;
			link.download = 'mapa-estacoes-automaticas.' + extensao;
			link.click();
		});
	}

	ngOnInit(): void {
		this.form = this.inputs.form;

		setTimeout(() => {
			this.setValidators();
		}, 0);
		this.initialValues();
	}
	abrirModalOpcoesDaPagina(event: Event) {
		event.preventDefault();
		this.modalService.show(PublicPageOptionsComponent, {
			class: 'modal-dialog-centered',
			initialState: {
				opcoes: this.opcoesDaPagina,
			},
		});
	}
	adicionarOpcoesDaPagina() {
		this.opcoesDaPagina = [
			{
				label: 'Legenda',
				template: this.legendaMapaMobile,
			},
		];
	}
}
