<seira-popup-padrao-mapa-paraiba
	[headerColor]="headerColor"
	[headerTextColor]="handleTextColorBasedOnBackgroundColor(headerColor)">
	<ng-container header>{{ nome }}</ng-container>
	<ng-container data>
		<div class="d-flex flex-column" style="min-width: 180px">
			<div class="d-inline-flex align-items-center text-center">
				<i class="ph-drop fs-1"></i>
				<span class="fs-3"
					>{{
						isNotNuloOuUndefined(precipitacaoAcumulada)
							? precipitacaoAcumulada + ' mm'
							: 'Não medida'
					}}
				</span>
			</div>
			precipitação acumulada

			<div class="fw-semibold mt-2" *ngIf="informacaoExtra">
				{{ informacaoExtra }}
			</div>
		</div>
	</ng-container>
	<ng-container description *ngIf="postos && postos.length">
		<div>
			<span class="fw-semibold"> Postos do município: </span>

			<div *ngFor="let posto of postos">- {{ posto.posto }}</div>
		</div>
	</ng-container>
</seira-popup-padrao-mapa-paraiba>
