import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'seira-circular-progress-percentege',
	templateUrl: './circular-progress-percentege.component.html',
	styleUrls: ['./circular-progress-percentege.component.scss'],
})
export class CircularProgressPercentegeComponent implements OnInit {
	@Input() percentage = 0; // Percentage to be displayed in the circle
	@Input() color = '#0D6A92';
	circumference!: number;
	offset!: number;

	constructor() {}

	ngOnInit(): void {
		this.circumference = 2 * Math.PI * 90;
		let auxVal = this.percentage;
		if (this.percentage > 100) auxVal = 100;
		this.offset = this.circumference - (auxVal / 100) * this.circumference;
	}
}
