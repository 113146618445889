import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoletimDiarioComponent } from '@home/submodulos/dados-meteorologicos/pages/boletim-diario/boletim-diario.component';
import { MonitoramentoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/monitoramento/monitoramento.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/dados-meteorologicos/boletim-diario',
		pathMatch: 'full',
	},
	{
		path: '',
		title: 'Dados meteorológicos - SEIRA',
		children: [
			{ path: 'boletim-diario', component: BoletimDiarioComponent },
			{
				path: 'monitoramento',
				component: MonitoramentoComponent,
				loadChildren: () =>
					import('./submodulos/monitoramento/monitoramento.module').then(
						m => m.MonitoramentoModule
					),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DadosMeteorologicosRoutingModule {}
