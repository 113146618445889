import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OpcaoFiltroEstacao } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/monitoramento-estacoes';
import {
	EstacaoMonitorada,
	FiltrosEstacaoMonitordada,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { ValoresFormularioService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/estacoes/services/valoresFormulario.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { StatusEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/status-estacao';
import { EstacoesService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/services/estacoes.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'seira-modal-fitro-mapa-monitoramento',
	templateUrl: './modal-fitro-mapa-monitoramento.component.html',
	styleUrls: ['./modal-fitro-mapa-monitoramento.component.scss'],
})
export class ModalFitroMapaMonitoramentoComponent implements OnInit {
	@Input() filtro!: any;
	@Input() municipios!: OpcaoFiltroEstacao[];
	@Input() intervaloMonitoramento!: any;
	@Input() mesorregiaoSelecionada!: any;
	@Input() tiposEstacao!: OpcaoFiltroEstacao[];
	@Input() bacias!: OpcaoFiltroEstacao[];
	@Input() regioes!: OpcaoFiltroEstacao[];
	@Input() microrregioes!: OpcaoFiltroEstacao[];
	@Input() mesorregioes: OpcaoFiltroEstacao[] = [];
	estacoes: OpcaoFiltroEstacao[] = [];
	@Input() enviarFiltro!: (filtro: any) => void;
	@Input() estacoesMonitoradas: EstacaoMonitorada[] = [];
	@Input() status: {
		label: StatusEstacao;
		value: keyof typeof StatusEstacao;
	}[] = [];
	tituloBotao = 'Adicionar';
	listaFiltro: Set<string> = new Set();
	filtroModalAtivo$: Observable<string>;
	filtroModalAtivo = '';

	formEstacao: FormGroup;

	constructor(
		public bsModalRef: BsModalRef,
		private formBuilder: FormBuilder,
		private valoresFormularioService: ValoresFormularioService,
		private estacaoService: EstacoesService
	) {
		this.filtroModalAtivo$ = this.valoresFormularioService.filtroModalAtivo$;
		this.formEstacao = this.formBuilder.group({
			tipoEstacao: new FormControl<TipoEstacao | null>(null),
			municipio: new FormControl<number | null>(null),
			bacia: new FormControl<number | null>(null),
			regiao: new FormControl<number | null>(null),
			microrregiao: new FormControl<number | null>(null),
			statusEstacao: new FormControl<StatusEstacao | null>(null),
			mesorregiao: new FormControl<number | null>(null),
		});

		for (const statusEstacaoKey in StatusEstacao) {
			const key = statusEstacaoKey as keyof typeof StatusEstacao;
			this.status.push({ value: key, label: StatusEstacao[key] });
		}
	}

	ngOnInit() {
		this.setSelects(this.estacoesMonitoradas);
		this.formEstacao.setValue({
			tipoEstacao: this.filtro.tipoEstacao,
			municipio: this.filtro.municipioId,
			bacia: this.filtro.baciaId,
			regiao: this.filtro.regiaoId,
			microrregiao: this.filtro.microrregiaoId,
			statusEstacao: this.filtro.statusEstacao,
			mesorregiao: this.filtro.mesorregiaoId,
		});
		this.observeFiltroModalAtivo();
		this.tituloBotao = this.filtroModalAtivo ? 'Atualizar' : this.tituloBotao;
		const filtroAtual = this.filtroModalAtivo.split(', ');
		filtroAtual.forEach((f: string) => {
			this.listaFiltro.add(f);
		});

		this.observeRegioes();
		this.observeMicrorregioes();
		this.observeMesorregioes();
		this.observeMunicipios();
	}

	observeFiltroModalAtivo() {
		this.filtroModalAtivo$.subscribe({
			next: (value: string) => {
				this.filtroModalAtivo = value;
			},
		});
	}

	adicinarFiltros() {
		const { tipoEstacao, mesorregiao, municipio, bacia, regiao, microrregiao } =
			this.formEstacao.getRawValue();

		const novoFiltro = new FiltrosEstacaoMonitordada(
			null,
			tipoEstacao,
			mesorregiao,
			municipio,
			null,
			bacia,
			regiao,
			microrregiao
		);

		if (this.listaFiltro.size > 0) {
			this.atualizarFiltroAtivos();
		} else {
			this.valoresFormularioService.atualizarDados('');
		}
		this.enviarFiltro(novoFiltro);
		this.bsModalRef.hide();
	}

	limparFiltros() {
		this.listaFiltro.clear();
		this.formEstacao.setValue({
			tipoEstacao: null,
			municipio: null,
			bacia: null,
			regiao: null,
			microrregiao: null,
			statusEstacao: null,
			mesorregiao: null,
		});
		this.reloadCleanFiltros();
	}
	reloadCleanFiltros() {
		this.estacaoService.filtros({}).subscribe({
			next: resp => {
				this.municipios = resp.municipios;
				this.mesorregioes = resp.mesorregioes;
				this.microrregioes = resp.microrregioes;
				this.bacias = resp.bacias;
				this.regioes = resp.regioes;
			},
			error: (error: HttpErrorResponse) => {
				console.error('erro', error);
			},
		});
	}
	filtroAtivo(resultado: any) {
		if (resultado.value) {
			this.listaFiltro.add(resultado.label);
		} else {
			this.listaFiltro.delete(resultado.label);
		}

		this.listaFiltro.delete('');
	}
	filtraVariavel(listaVariavel: number[]) {
		const variaveis = [
			{ id: 'mesorregiaoId', variavel: 'mesorregiao', nome: 'nomeMesorregiao' },
			{ id: 'regiaoId', variavel: 'regiao', nome: 'nomeRegiao' },
			{
				id: 'microrregiaoId',
				variavel: 'microrregiao',
				nome: 'nomeMicrorregiao',
			},
			{ id: 'municipioId', variavel: 'municipio', nome: 'nomeMunicipio' },
			{ id: 'baciaId', variavel: 'bacia', nome: 'nomeBacia' },
			{ id: 'id', variavel: 'nomePosto', nome: 'nomePosto' },
		];
		listaVariavel.forEach((n: number) => {
			const id: string = variaveis[n]?.id;
			const alteraId: string = variaveis[n + 1]?.id;
			const variavel: string = variaveis[n]?.variavel;
			const nome: string = variaveis[n + 1]?.nome;
			const setVariavel: Set<number> = new Set();
			const variavelLista: OpcaoFiltroEstacao[] = [];

			if (this.formEstacao.get(variavel)?.value) {
				this.estacoesMonitoradas.filter((em: EstacaoMonitorada) => {
					return em[id] === this.formEstacao.get(variavel)?.value;
				});
			}

			this.estacoesMonitoradas.forEach((estacao: EstacaoMonitorada) => {
				const nomePosto = `${estacao.nomePosto}/ ${estacao.nomeMunicipio} (${
					estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
						? 'Convencional'
						: estacao.tipoEstacao
				})`;
				if (estacao[alteraId] && !setVariavel.has(estacao[alteraId])) {
					variavelLista.push({
						id: estacao[alteraId],
						nome: variavel === 'municipio' ? nomePosto : estacao[nome],
					});
					setVariavel.add(estacao[alteraId]);
				}
			});

			variavelLista?.sort((a, b) => a.nome.localeCompare(b.nome));

			if (variavel === 'mesorregiao') {
				this.regioes = variavelLista;
			}
			if (variavel === 'regiao') {
				this.microrregioes = variavelLista;
			}
			if (variavel === 'microrregiao') {
				this.municipios = variavelLista;
			}
			if (variavel === 'municipio') {
				this.estacoes.push(...variavelLista);
			}
		});
	}
	observeMesorregioes() {
		this.formEstacao.get('mesorregiao')?.valueChanges.subscribe({
			next: () => {
				this.filtraVariavel([0]);
				this.formEstacao.get('regiao')?.reset();
			},
		});
	}

	observeRegioes() {
		this.formEstacao.get('regiao')?.valueChanges.subscribe({
			next: () => {
				this.filtraVariavel([0, 1]);
				this.formEstacao.get('microrregiao')?.reset();
			},
		});
	}

	observeMicrorregioes() {
		this.formEstacao.get('microrregiao')?.valueChanges.subscribe({
			next: () => {
				this.filtraVariavel([0, 1, 2]);
				this.formEstacao.get('municipio')?.reset();
				this.limparFiltroModal('municipio');
			},
		});
	}
	limparFiltroModal(variavel: string) {
		const arrayVal = this.filtroModalAtivo
			? this.filtroModalAtivo
					.split(', ')
					.filter((item: string) => item.trim().toLowerCase() !== variavel)
			: [];

		this.valoresFormularioService.atualizarDados(arrayVal.join(', '));
	}
	/*	observeTipoEstacao() {
		this.formEstacao.get('tipoEstacao')?.valueChanges.subscribe({
			next: (tipoEstacao: TipoEstacao) => {
				if (tipoEstacao) {
					this.filtrarPostosPorTipoEstacao(tipoEstacao);
				} else {
					this.filtrarPostosPorTipoEstacao(tipoEstacao);
				}
			},
		});
	}*/

	observeMunicipios() {
		this.formEstacao.get('municipio')?.valueChanges.subscribe({
			next: val => {
				const municipioId = this.formEstacao.get('municipio')?.value;

				if (municipioId) {
					const postosFiltrados = this.estacoesMonitoradas.filter(
						estacao => estacao.municipioId === municipioId
					);
					const filteredPostos = postosFiltrados.map(estacao => ({
						id: estacao.id,
						nome: `${estacao.nomeMunicipio}/${estacao.nomePosto} (${
							estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
								? 'Convencional'
								: estacao.tipoEstacao
						})`,
					}));
					this.estacoes = filteredPostos;
				} else {
					this.estacoes = this.estacoesMonitoradas.map(estacao => ({
						id: estacao.id,
						nome: `${estacao.nomeMunicipio}/${estacao.nomePosto} (${
							estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
								? 'Convencional'
								: estacao.tipoEstacao
						})`,
					}));
				}
				this.filtraVariavel([0, 1, 2]);
			},
		});
	}

	setSelects(estacoesMonitoradas: EstacaoMonitorada[]) {
		const setMesorregiao: Set<number> = new Set();
		const setRegiao: Set<number> = new Set();
		const setMicrorregiao: Set<number> = new Set();
		const setMunicipio: Set<number> = new Set();
		const setBacia: Set<number> = new Set();
		const mesorregioes: OpcaoFiltroEstacao[] = [];
		const regioes: OpcaoFiltroEstacao[] = [];
		const microrregioes: OpcaoFiltroEstacao[] = [];
		const municipios: OpcaoFiltroEstacao[] = [];
		const bacias: OpcaoFiltroEstacao[] = [];
		const postos: OpcaoFiltroEstacao[] = [];

		estacoesMonitoradas.forEach((estacao: EstacaoMonitorada) => {
			if (estacao.mesorregiaoId && !setMesorregiao.has(estacao.mesorregiaoId)) {
				mesorregioes.push({
					id: estacao.mesorregiaoId,
					nome: estacao.nomeMesorregiao,
				});
				setMesorregiao.add(estacao.mesorregiaoId);
			}
			if (estacao.regiaoId && !setRegiao.has(estacao.regiaoId)) {
				regioes.push({
					id: estacao.regiaoId,
					nome: estacao.nomeRegiao,
				});
				setRegiao.add(estacao.regiaoId);
			}
			if (
				estacao.microrregiaoId &&
				!setMicrorregiao.has(estacao.microrregiaoId)
			) {
				microrregioes.push({
					id: estacao.microrregiaoId,
					nome: estacao.nomeMicrorregiao,
				});
				setMicrorregiao.add(estacao.microrregiaoId);
			}
			if (estacao.municipioId && !setMunicipio.has(estacao.municipioId)) {
				municipios.push({
					id: estacao.municipioId,
					nome: estacao.nomeMunicipio,
				});
				setMunicipio.add(estacao.municipioId);
			}
			if (estacao.baciaId && !setBacia.has(estacao.baciaId)) {
				bacias.push({
					id: estacao.baciaId,
					nome: estacao.nomeBacia,
				});
				setBacia.add(estacao.baciaId);
			}

			if (
				estacao.tipoEstacao === 'PCD' ||
				estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
			) {
				postos.push({
					id: estacao.id,
					nome: `${estacao.nomeMunicipio}/${estacao.nomePosto} (${
						estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
							? 'Convencional'
							: estacao.tipoEstacao
					})`,
				});
			}
		});

		mesorregioes.sort((a, b) => a.nome.localeCompare(b.nome));
		regioes.sort((a, b) => a.nome.localeCompare(b.nome));
		microrregioes.sort((a, b) => a.nome.localeCompare(b.nome));
		municipios.sort((a, b) => a.nome.localeCompare(b.nome));
		bacias.sort((a, b) => a.nome.localeCompare(b.nome));
		postos.sort((a, b) => a.nome.localeCompare(b.nome));

		if (this.mesorregioes.length < 1) {
			this.mesorregioes = mesorregioes;
			this.regioes = regioes;
			this.microrregioes = microrregioes;
			this.municipios = municipios;
			this.bacias = bacias;
			this.estacoes = postos;
		}
	}
	atualizarFiltroAtivos() {
		const filtrosAtivos = Array.from(this.listaFiltro).join(', ');

		this.valoresFormularioService.atualizarDados(filtrosAtivos);
	}
}
