<div class="d-flex flex-column w-100" *ngIf="!carregandoRelatorio">
	<label class="text-tertiary fw-semibold mt-3">
		RELATÓRIO DE EVAPOTRANSPIRAÇÃO (MM)
	</label>
	<div
		class="max-width: 1300px"
		*ngIf="dadosTabelaEvapotranspiracao.length; else semDados">
		<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />

		<seira-tabela-relatorio-evapotranspiracao
			[relatorios]="
				dadosTabelaEvapotranspiracao
			"></seira-tabela-relatorio-evapotranspiracao>
	</div>
</div>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
