import { Component, inject, OnDestroy } from '@angular/core';
import {
	DadosAgrupamento,
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	Municipio,
	PostosRelatorios,
} from '../../../interfaces';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { RelatoriosService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { HttpErrorResponse } from '@angular/common/http';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';
import { Agrupamento } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';
import { corrigeDuplicacaoNome } from '../../../utils';

@Component({
	selector: 'seira-pluviometro-mapa-ndc',
	templateUrl: './pluviometro-mapa-ndc.component.html',
	styleUrls: ['./pluviometro-mapa-ndc.component.scss'],
})
export class PluviometroMapaNdcComponent
	implements OnDestroy, InstanciaRelatorio
{
	postos?: PostosRelatorios[] | undefined;
	estacoes?: Estacao[] | undefined;
	regioes?: Select<string>[] | undefined;
	microrregioes?: Select<string>[] | undefined;
	mesorregioes?: Select<string>[] | undefined;
	municipios?: Municipio[] | undefined;
	precipitacao?: Select<string>[] | undefined;
	agrupamento?: Select<string>[] | undefined;
	inputs = inject(INPUTS_RELATORIOS);
	private _isLoading = true;
	response: RelatorioNDCResponse[] = [];
	isLoadingInitial = true;
	agrupamentoSelecionado: string;
	periodoTitulo = '';
	descricaoRelatorio =
		'O NDC, Número de Dias com Chuva, representa o número de dias em que a pluviometria diária, acumulada em 24 horas, foi igual ou superior a 0,1 mm.';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportPdf(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	constructor(
		private relatorioService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	set isLoading(value: boolean) {
		this._isLoading = value;
		this.inputs.setLoading(value);
	}

	gerarRelatorio() {
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		this.agrupamentoSelecionado = this.inputs.form.get(
			FormularioRelatorio.AGRUPAMENTO
		)?.value;

		if (!diaInicio || !diaFim || !periodo || this.inputs.form.invalid) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodo
		);

		this.isLoading = true;

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento: this.agrupamentoSelecionado,
			periodoAgrupamento: periodo.toUpperCase(),
		};

		switch (this.agrupamentoSelecionado) {
			case 'MUNICIPIO_POSTO':
				dados.posto = 0;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = 0;
				break;
			case 'MUNICIPIO':
				dados.municipio = 0;
				break;
		}

		this.relatorioService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: resp => {
				this.relatorioService.verificaExistenciaDados(resp);
				this.response = resp;
			},
			error: (erro: HttpErrorResponse) => {
				this.toastr.error('Nenhum resultado encontrado', erro.message);
				this.isLoadingInitial = false;
			},
			complete: () => {
				this.isLoadingInitial = false;
				this.isLoading = false;
			},
		});
	}

	getDadosTabelaParaExportacao(dados: RelatorioNDCResponse[]) {
		const tableData: any[][] = [];

		const colunas = [
			'Nome',
			'Latitude',
			'Longitude',
			'Altitude',
			'Município',
			'Microrregião',
			'Mesorregião',
			'Região',
			'Bacia',
			'Sub-bacia',
			'Dias com chuva',
			'Soma da precipitação',
		];
		tableData.push(colunas);

		dados.forEach((item: RelatorioNDCResponse) => {
			const rowData = [
				corrigeDuplicacaoNome(item.nome),
				item.latitude,
				item.longitude,
				item.altitude,
				item.microrregiao,
				item.mesorregiao,
				item.regiao,
				item.bacia,
				item.subbacia,
				item.diasComChuva,
				item.somaPrecipitacao,
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	async exportPdf() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Número de dias com chuva (NDC) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 500,
				height: 400,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		documentDefinition.content.push({
			table: {
				widths: ['50%', '50%'],
				body: this.tableData(),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
			marginLeft: 15,
			marginRight: 15,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.response);

		DocumentExporter.gerarCSV(
			tableData,
			`Número de dias com chuva (NDC) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.response);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Número de dias com chuva (NDC) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `Número de dias com chuva (NDC) - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{ label: 'nome', text: 'Nome', size: 24 },
			{ label: 'latitude', text: 'Latitude', size: 24 },
			{ label: 'longitude', text: 'Longitude', size: 24 },
			{ label: 'altitude', text: 'Altitude', size: 24 },
			{ label: 'municipio', text: 'Município', size: 24 },
			{ label: 'microrregiao', text: 'Microrregião', size: 24 },
			{ label: 'mesorregiao', text: 'Mesorregiao', size: 24 },
			{ label: 'regiao', text: 'Região', size: 24 },
			{ label: 'bacia', text: 'Bacia', size: 24 },
			{ label: 'subbacia', text: 'Sub-bacia', size: 24 },
			{ label: 'diasComChuva', text: 'Dias com chuva', size: 24 },
			{ label: 'somaPrecipitacao', text: 'Soma da precipitação', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.response.map(ndc => {
						return {
							diasComChuva: ndc.diasComChuva,
							somaPrecipitacao: ndc.somaPrecipitacao,
							nome: corrigeDuplicacaoNome(ndc.nome),
							latitude: ndc.latitude ?? '',
							longitude: ndc.longitude ?? '',
							altitude: ndc.altitude ?? '',
							municipio: ndc.municipio ?? '',
							microrregiao: ndc.microrregiao ?? '',
							mesorregiao: ndc.mesorregiao ?? '',
							regiao: ndc.regiao ?? '',
							bacia: ndc.bacia ?? '',
							subbacia: ndc.subbacia ?? '',
						};
					}),
					columns: [
						{ key: 'nome' },
						{
							key: 'latitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'longitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'altitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'municipio',
						},
						{
							key: 'microrregiao',
						},
						{
							key: 'mesorregiao',
						},
						{
							key: 'regiao',
						},
						{
							key: 'bacia',
						},
						{
							key: 'subbacia',
						},
						{
							key: 'diasComChuva',
							style: { numFmt: '0' },
						},
						{
							key: 'somaPrecipitacao',
							style: { numFmt: '0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo}`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	private tableData() {
		const dadosTabela: Array<any[]> = [];

		const cabecalho = [
			{
				text: Agrupamento[
					this.agrupamentoSelecionado as keyof typeof Agrupamento
				],
				fillColor: '#DCDCDC',
			},
			{ text: 'Número de dias com chuva', fillColor: '#DCDCDC' },
		];
		dadosTabela.push(cabecalho);

		const valores = this.response!.map(value => [
			value.nome === value.municipio + '/' + value.municipio
				? value.municipio
				: value.nome,
			value.diasComChuva,
		]);
		dadosTabela.push(...valores);

		return dadosTabela;
	}
}
