<div class="tooltip-borders mb-0 ms-0 me-0 border shadow-lg">
	<div class="tooltip-header p-2 text-white fw-semibold fs-6 bg-primary">
		Última Medição:
		{{ markerData.ultimaColeta | date: 'dd/MM/yyyy - HH:mm' }}
	</div>
	<div
		class="tooltip-footer px-3 py-1 text-tertiary font-size-15 bg-white"
		style="white-space: nowrap">
		<div class="d-flex col align-items-center">
			<i class="ph ph-broadcast fs-1 mt-1"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Nome:</span>
				{{ markerData.nomePosto }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-map-pin fs-1 mt-1"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Município:</span>
				{{ markerData.nomeMunicipio }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-cloud fs-1 mt-1"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Precipitação:</span>
				{{
					isNotNuloOuUndefined(markerData.precipitacao)
						? numberToBrNumber(markerData.precipitacao, 1) + ' mm'
						: 'Não medida'
				}}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-1 mt-1"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				{{ markerData.latitude | formatDecimal: 4 }}
			</div>
			<i class="ph ph-compass fs-1 mt-1"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				{{ markerData.longitude | formatDecimal: 4 }}
			</div>
		</div>
		<div class="d-flex justify-content-end">
			<seira-public-a class="mt-1" (click)="clickEstacao(markerData)"
				>Ver histórico</seira-public-a
			>
		</div>
	</div>
</div>
