<label class="text-center text-tertiary fw-semibold w-100 mt-3">
	Índice de precipitação padronizada (SPI) - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

<div class="d-flex justify-content-center align-items-center w-100">
	<div
		*ngIf="carregandoRelatorio; else graficoSPI"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #graficoSPI>
		<div
			*ngIf="!!graficoSPIUrl; else semDados"
			class="container d-flex flex-column justify-content-center align-items-center w-100"
			style="min-height: 500px">
			<img
				class="grafico-responsivo mb-3"
				[src]="graficoSPIUrl"
				alt="Gráfico SPI" />
			<seira-monitoramento-legenda-horizontal
				class="legenda"
				[mapaNome]="grafico"
				[valores]="valores"
				[carregando]="carregandoRelatorio"
				[muitosItensLegenda]="true">
			</seira-monitoramento-legenda-horizontal>
		</div>
	</ng-template>

	<ng-template #semDados>
		<seira-sem-dados />
	</ng-template>
</div>
