<div class="container">
	<label class="text-center text-tertiary fw-semibold w-100">
		Número de dias com chuva (NDC) - {{ periodoTitulo }}
	</label>
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

	<div
		*ngIf="isLoadingInitial; else mapa"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>
	<ng-template #mapa>
		<div id="mapa-legenda" class="row" *ngIf="response; else nenhumResultado">
			<seira-mapa-ndc-chuva
				[isLoading]="isLoading"
				[dados]="response"
				id="mapa-ndc" />
		</div>
	</ng-template>
	<ng-template #nenhumResultado>
		<div class="row">
			<div class="col-12">
				<seira-sem-dados></seira-sem-dados>
			</div>
		</div>
	</ng-template>
</div>
