<div class="tooltip-borders mb-0 ms-0 me-0 border shadow-lg">
	<div
		class="tooltip-header p-2 fw-semibold fs-6 radious   {{ headerColor }}"
		[style]="'background-color:  ' + headerColor + ';color: ' + titleColor">
		<i class="ph-map-pin me-2 mb-1"></i>
		{{ nome }}
	</div>
	<div
		class="tooltip-footer px-2 py-2 text-tertiary align-items-center font-size-6 bg-white">
		<div class="d-inline-flex px-1 py-2">
			<ng-container>
				<i class="ph ph-cloud fs-1 mb-2"></i>
				<span
					*ngIf="agrupamento && agrupamento.precipitacaoAcumulada !== undefined"
					class="fw-semibold"
					>Precipitação:</span
				>
			</ng-container>
			<span
				[class]="
					'me-1' + agrupamento.precipitacaoAcumulada !== undefined
						? ''
						: ' text-tertiary'
				">
				{{
					agrupamento.precipitacaoAcumulada !== undefined
						? agrupamento.precipitacaoAcumulada!.toFixed(1).replace('.', ',') +
						  ' mm'
						: 'Sem medições para ' +
						  generoAgrupamento +
						  ' ' +
						  labelTipoAgrupamento +
						  ' nesse período.'
				}}
			</span>
		</div>
	</div>
</div>
