<div class="d-flex align-items-center">
	<seira-page-title class="w-100 align-items-center">
		<div class="d-flex justify-content-between align-items-center">
			<div style="color: #728187; font-weight: 400; font-size: 24px !important">
				Dados meteorológicos
				<i class="dot bg-primary dot-space"></i>
				Quantis
			</div>
			<div class="d-flex justify-content-end">
				<seira-public-page-about
					class="size-about"
					[textosInformativos]="informacoesGerais"></seira-public-page-about>
			</div>
		</div>
	</seira-page-title>
</div>

<div>
	<form
		[formGroup]="formQuantis"
		class="form-group"
		id="form"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-4 col-xl-3">
				<div class="col-lg-11 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="opcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"></seira-public-radio-group>
				</div>

				<div class="col-lg-11 mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo">
					</seira-public-select>
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectPostos">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Município/Posto"
						[searchable]="true"
						[options]="municipioPostos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao">
					</seira-public-select>
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMicrorregioes">
					<seira-public-select
						*ngIf="!loadingInicialData"
						placeholder="Selecione uma microrregião"
						label="Microrregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao"
						[searchable]="true"
						[options]="microrregioes">
					</seira-public-select>
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectRegioes">
					<seira-public-select
						placeholder="Selecione uma região pluviométrica"
						label="Região pluviométrica"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma região pluviométrica encontrada"
						formControlName="regiao"
						[searchable]="true"
						[options]="regioes">
					</seira-public-select>
				</div>

				<div class="col-lg-11 col-sm-6 mt-1" *ngIf="variaveisPeriodoChuvoso">
					<seira-public-select
						placeholder="Selecione um período chuvoso"
						label="Periodo Chuvoso"
						[options]="tiposPeriodosChuvosos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum período encontrado"
						formControlName="periodoChuvoso">
					</seira-public-select>
				</div>

				<div class="col-lg-12 col-md-4 col-sm-6" *ngIf="mostrarPeriodoBusca">
					<seira-public-radio-group
						[controlName]="'periodoBusca'"
						[options]="opcoesPeriodo"></seira-public-radio-group>
				</div>

				<ng-container *ngIf="relatorioAtual?.periodos && labelAdptativaPeriodo">
					<ng-container
						*ngIf="labelAdptativaPeriodo.mensal_por_nome; else unicoPicker">
						<div class="col-lg-11 d-flex flex-column">
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês inicial"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesInicio"
								[searchable]="true"
								[options]="meses">
							</seira-public-select>
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês final"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesFim"
								[searchable]="true"
								[options]="meses">
							</seira-public-select>
						</div>
					</ng-container>

					<ng-template #unicoPicker>
						<ng-container *ngIf="labelAdptativaPeriodo.unico; else rangePicker">
							<div class="col-lg-11 d-flex flex-column mt-1">
								<seira-public-date-picker
									class="w-100 mt-1"
									controlName="periodo"
									[label]="labelAdptativaPeriodo.unico.label"
									[placeholder]="labelAdptativaPeriodo.unico.placeholder || ''"
									[datePickerType]="
										labelAdptativaPeriodo.unico.dataType || 'month'
									"
									[tipoFormulario]="getFormItemValue('tipo')" />
							</div>
						</ng-container>
					</ng-template>

					<ng-template #rangePicker>
						<div class="col-lg-11 d-flex flex-column mt-1">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataInicio"
								[label]="labelAdptativaPeriodo.inicio?.label"
								[placeholder]="labelAdptativaPeriodo.inicio?.placeholder || ''"
								[datePickerType]="
									labelAdptativaPeriodo.inicio?.dataType || 'year'
								"
								[tipoFormulario]="getFormItemValue('tipo')" />
						</div>
						<div class="col-lg-11 d-flex flex-row align-items-end">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataFim"
								[label]="labelAdptativaPeriodo.fim?.label"
								[placeholder]="labelAdptativaPeriodo.fim?.placeholder || ''"
								[datePickerType]="labelAdptativaPeriodo.fim?.dataType || 'year'"
								[minDate]="dataInicio"
								[tipoFormulario]="getFormItemValue('tipo')"
								[maxDate]="limiteDataFimSuperior" />
						</div>
					</ng-template>
				</ng-container>
				<div
					class="col-lg-11 mt-1 d-flex justify-content-between align-items-end"
					style="margin-top: 1rem !important">
					<seira-public-button
						[disabledButton]="formQuantis.invalid || !instanceRelatorio"
						(clicked)="gerarRelatorio()"
						[loading]="loadingForm"
						>Gerar</seira-public-button
					>
				</div>
			</div>
			<div class="col-lg-6">
				<div
					class="d-flex flex-colum justify-content-end mb-2"
					*ngIf="
						relatoriosService.getExibirBotoesExportacao() &&
						formQuantis.value.tipo
					">
					<seira-public-button-group
						[buttons]="botoesDeExportacao"></seira-public-button-group>
				</div>

				<div
					*ngIf="loadingInicialData"
					class="d-flex justify-content-center align-items-center w-100 h-50"
					style="min-height: 500px">
					<seira-loading [pageCentered]="false"></seira-loading>
				</div>
				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>
	</form>
</div>

<div class="row" *seiraIfViewportSize="['xl', 'lg', 'md']">
	<div class="col-lg-6">
		<seira-info-gerais
			id="informacoes-gerais"
			[textosInformativos]="informacoesGerais"></seira-info-gerais>
	</div>
</div>
