import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';
import { HTMLInputTypeAttribute } from '@layout/interfaces/htmlTypeInput';

@Component({
	selector: 'seira-input-file',
	templateUrl: './input-file.component.html',
	styleUrls: ['./input-file.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class InputFileComponent implements OnInit {
	@Input() id!: string;
	@Input() label!: string;
	@Input() required = false;
	@Input() controlName!: string;
	@Input() accept!: string;
	@Input() placeholder!: string;
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() type: HTMLInputTypeAttribute = 'text';
	@Input() file?: File | null;
	@Input() clearable = false;

	control = new FormControl();
	@Output() fileSelected: EventEmitter<File | null> =
		new EventEmitter<File | null>();
	@Output() fileCleared: EventEmitter<string> = new EventEmitter<string>();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}

	onFileSelected(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		const file = inputElement.files?.[0] || null;
		this.file = file;
		this.fileSelected.emit(file);
	}
	limparCampo() {
		this.file = null;
		this.control.setValue(null);
		this.fileCleared.emit(this.controlName);
	}
	downloadFile(file: File) {
		const url = window.URL.createObjectURL(file);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.download = file.name;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	}

	download() {
		if (this.file) {
			this.downloadFile(this.file);
		}
	}

	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}
	get seiraError() {
		if (this.control.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				if (erros[0] === 'required') {
					if (this.control.touched) return 'Campo obrigatório.';
				} else {
					return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
