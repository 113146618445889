import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { HighchartsChartComponent } from 'highcharts-angular';

@Component({
	selector: 'seira-high-stock',
	templateUrl: './high-stock.component.html',
	styleUrls: ['./high-stock.component.scss'],
})
export class HighStockComponent implements OnDestroy {
	@ViewChild('highchartsGrafico') chartRef!: HighchartsChartComponent;
	@Output() chartInstance = new EventEmitter<Highcharts.StockChart>();
	@Input() classChart = 'highcharts-light h-auto';
	@Input() options!: Highcharts.Options;
	@Input() styleChart = 'width: 100%; height: 1200px; display: block';

	protected readonly Highcharts = Highcharts;

	private chart: Highcharts.StockChart | undefined;
	private height = 1200;
	updateFlag = false;

	chartCallback: Highcharts.ChartCallbackFunction = chart => {
		chart.update(this.options, true);
		this.chart = chart;
		this.chartInstance.emit(chart);
	};

	ngOnDestroy() {
		if (this.chart && Object.keys(this.chart).length) {
			this.chart.destroy();
		}
	}

	capturaAltura(filtro: number) {
		this.height = filtro * 200 + (180 - 20 * filtro);
	}

	reiniciarGrafico(options: Highcharts.Options, filtro: number) {
		if (this.chart) {
			this.chart = new Highcharts.StockChart('highchartsGrafico', options);
			this.capturaAltura(filtro);
			this.chart!.setSize(null, this.height);
			this.chartInstance.emit(this.chart);
		}
	}
	geraURLdoGrafico() {
		const chartOptions: Highcharts.Options = {
			chart: {
				width: 1200,
			},
		};

		const svg = this.chart?.getSVG(chartOptions) || '';
		const blob = new Blob([svg], { type: 'image/svg+xml' });
		return URL.createObjectURL(blob);
	}

	update() {
		this.chart?.destroy();
		// @ts-ignore
		this.chartRef.chart = undefined;
		this.chart = undefined;
		this.updateFlag = true;
	}
}
