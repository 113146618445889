export abstract class ColorUtils {
	/**
	 * Verifica se uma cor é clara ou não
	 * @param corHexa cor hexadecimal incluindo o #
	 * @returns boolean que diz se a cor é clara
	 */
	static ehCorClara(corHexa: string): boolean {
		const [r, g, b] = this.hexToRGB(corHexa);
		// Calcula a luminância relativa aproximada (https://alienryderflex.com/hsp.html)
		const luminancia = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
		return luminancia > 0.65;
	}

	/**
	 * Converte uma cor hexadecimal em RGB
	 * @param corHexa cor hexadecimal incluindo o #
	 * @param opacidade transparência
	 * @returns array com os valores RGB e opacidade
	 */
	static hexToRGB(
		corHexa: string,
		opacidade = 1
	): [number, number, number, number] {
		const cor = corHexa.replace('#', '');
		const r = parseInt(cor.substring(0, 2), 16);
		const g = parseInt(cor.substring(2, 4), 16);
		const b = parseInt(cor.substring(4, 6), 16);
		return [r, g, b, opacidade];
	}
}
