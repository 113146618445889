import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@core/services/user/user.service';
import { User } from '@core/interfaces/user';

@Component({
	selector: 'seira-form-recuperar-senha',
	templateUrl: './form-recuperar-senha.component.html',
	styleUrls: ['./form-recuperar-senha.component.scss'],
})
export class FormRecuperarSenhaComponent implements OnInit {
	recuperarSenhaForm!: FormGroup;
	carregando = false;
	user: User | null;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private toastr: ToastrService,
		private router: Router,
		private userService: UserService
	) {
		this.user = null;
	}

	ngOnInit(): void {
		this.userService.getUser().subscribe((user: User | null) => {
			this.user = user;
		});

		this.recuperarSenhaForm = this.formBuilder.group({
			login: ['', [Validators.required]],
			email: ['', [Validators.required]],
		});
	}

	recuperarSenha() {
		const login = this.recuperarSenhaForm.get('login')?.value;
		const email = this.recuperarSenhaForm.get('email')?.value;

		this.carregando = true;
		this.authService.recoverPassword(login, email).subscribe({
			next: () => {
				this.toastr.success(
					'Link de recuperação enviado para o e-mail fornecido.'
				);
				this.router.navigate(['home']).then();
				this.carregando = false;
			},
			error: err => {
				const erroDetalhes: string | undefined = err.error?.mensagem;
				if (erroDetalhes === undefined) {
					this.toastr.error('Falha ao recuperar a senha.', 'Erro');
				} else {
					this.toastr.error(`${erroDetalhes}`, 'Falha ao recuperar a senha.');
				}
				this.carregando = false;
			},
		});
	}

	conditionShowTextRequired(field: string) {
		return (
			this.recuperarSenhaForm.get(field)?.errors?.['required'] &&
			this.recuperarSenhaForm.get(field)?.touched
		);
	}
}
