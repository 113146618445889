import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import {
	DadosEvapotranspiracao,
	PostosRelatorios,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { format, formatISO } from 'date-fns';
import { FormGroup, Validators } from '@angular/forms';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import * as moment from 'moment';
import * as pdfseira from '@utils/pdf-seira';
import { isNotNuloOuUndefined } from '@utils';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';

@Component({
	selector: 'seira-evapotranspiracao-tabela',
	templateUrl: './evapotranspiracao-tabela.component.html',
	styleUrls: ['./evapotranspiracao-tabela.component.scss'],
})
export class EvapotranspiracaoTabelaComponent
	implements OnDestroy, InstanciaRelatorio, OnInit
{
	descricaoRelatorio =
		'Define-se como evapotranspiração a quantidade total de água transferida para a atmosfera, resultado da soma da evaporação do solo e da transpiração das plantas de uma determinada área.';
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaEvapotranspiracao: Relatorios<DadosEvapotranspiracao>[] = [];
	carregandoRelatorio = false;
	private subscription = new Subscription();
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.dadosTabelaEvapotranspiracao);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV(this.dadosTabelaEvapotranspiracao);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT(this.dadosTabelaEvapotranspiracao);
			},
		},
	];
	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.form = this.inputs.form;
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
	}

	ngOnInit() {
		setTimeout(() => {
			this.setValidators();
			this.definirPeriodoPadrao();
		});
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	private definirPeriodoPadrao() {
		const hoje = moment();
		const semanaAnterior = moment(hoje).subtract(1, 'week');

		this.form.get('dataInicio')?.setValue(semanaAnterior);
		this.form.get('dataFim')?.setValue(hoje);
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.subscription.unsubscribe();
	}
	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	public gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		this.inputs.setLoading(true);
		const postoIds = this.postos.map(posto => posto.id);
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');
		this.carregandoRelatorio = true;
		this.relatoriosService
			.buscarRelatorioEvapotranspiracao(
				postoIds,
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim))
			)
			.subscribe({
				next: evapotranspiracao => {
					this.dadosTabelaEvapotranspiracao = evapotranspiracao;
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
			});
	}

	getPeriodo() {
		const dataInicio = this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.value.format('DD/MM/yyyy');
		const dataFim = this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.value.format('DD/MM/yyyy');

		return dataInicio + ' a ' + dataFim;
	}

	async exportarPDF(
		dadosTabelaEvapotranspiracao: Relatorios<DadosEvapotranspiracao>[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Evapotranspiração - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaEvapotranspiracao.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		dadosTabelaEvapotranspiracao.forEach(dadoRelatorio => {
			const tableData: (string | number)[][] = [];

			tableData.push(['Posto', 'Data', 'Evapotranspiração potencial (mm)']);

			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dado.nomeEstacao,
					format(new Date(dado.dataColeta), 'dd/MM/yyyy HH:mm'),
					isNotNuloOuUndefined(dado.evapotranspiracaoPotencial)
						? dado.evapotranspiracaoPotencial
						: '-',
				]);
			});

			documentDefinition.content.push(
				{
					text: dadoRelatorio.municipio,
					fontSize: 12,
					margin: [0, 10],
				},
				{
					table: {
						body: tableData,
						layout: {
							noWrap: false,
							fontSize: 5,
						},
					},
				}
			);
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(
		dadosTabelaEvapotranspiracao: Relatorios<DadosEvapotranspiracao>[]
	) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Data',
			'Evapotranspiração potencial (mm)',
		]);

		dadosTabelaEvapotranspiracao.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dadoRelatorio.municipio,
					dado.nomeEstacao,
					format(new Date(dado.dataColeta), 'dd/MM/yyyy HH:mm'),
					isNotNuloOuUndefined(dado.evapotranspiracaoPotencial)
						? dado.evapotranspiracaoPotencial
						: '-',
				]);
			});
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-evapotranspiracao-${this.getPeriodo()}`
		);
	}

	exportarTXT(
		dadosTabelaEvapotranspiracao: Relatorios<DadosEvapotranspiracao>[]
	) {
		let txtData = '';

		dadosTabelaEvapotranspiracao.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData +=
					`Município/Posto: ${dadoRelatorio.municipio}/${dado.nomeEstacao}\n` +
					`Data: ${format(new Date(dado.dataColeta), 'dd/MM/yyyy HH:mm')}\n` +
					`Evapotranspiração potencial (mm): ${
						isNotNuloOuUndefined(dado.evapotranspiracaoPotencial)
							? dado.evapotranspiracaoPotencial
							: '-'
					}\n\n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-evapotranspiracao-${this.getPeriodo()}`
		);
	}
}
