import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import { Validators } from '@angular/forms';
import { optionsChartPluviometriaObservada } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada/chartOptions';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import * as Highcharts from 'highcharts/highstock';
import { Subject, takeUntil } from 'rxjs';
import { corrigeDuplicacaoNome, gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { Select } from '@layout/interfaces/select';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import {
	criarImagemBase64FromChart,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';
import { ToastrService } from 'ngx-toastr';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces';
import { Agrupamento } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';

@Component({
	selector: 'seira-grafico-pluviometria-observada',
	templateUrl: './grafico-pluviometria-observada.component.html',
	styleUrls: ['./grafico-pluviometria-observada.component.scss'],
})
export class GraficoPluviometriaObservadaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	chart: Highcharts.Chart;
	chartOptions: Highcharts.Options = optionsChartPluviometriaObservada;
	_destroyed = new Subject();
	descricaoRelatorio =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período.';
	periodoTitulo = '';
	loading = true;
	response: AgrupamentoResponse[];
	microrregioes: Select<string>[];
	grafico?: Highcharts.Chart;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	tituloAgrupamento = '';
	postos: PostosRelatorios[] = [];
	agrupamentoSelecionado = '';

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		const todasMicrorregioes = this.inputs.microrregioes;
		this.microrregioes = [
			{ value: '0', name: 'Estado completo' },
			...todasMicrorregioes,
		];
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValue(this.microrregioes[0].value);

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
		this.configurarNewGrafico(this.response);
	}

	checaAgrupamento(dados: DadosAgrupamento) {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.inputs.form.get(FormularioRelatorio.ESTACAO)?.value;
				break;

			case 'MICRORREGIAO':
				dados.microrregiao = this.inputs.form.get(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				break;
			case 'MUNICIPIO':
				dados.municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO)
					?.value;
				break;
		}
	}

	gerarRelatorio() {
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};
		this.checaAgrupamento(dados);
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);
		this.loading = true;
		this.inputs.setLoading(true);
		this.relatoriosService.buscarRelatorioPorAgrupamento(dados).subscribe({
			next: preciptacoesAgrupadas => {
				this.relatoriosService.verificaExistenciaDados(preciptacoesAgrupadas);
				this.response = preciptacoesAgrupadas;
				const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
					?.value;
				const categories = this.extrairDatasPrecipitacao(
					this.response,
					periodo
				);
				this.chartOptions = {
					...this.chartOptions,
					exporting: {
						...this.chartOptions.exporting,
						filename: gerarFilenameGrafico(
							`grafico_pluviometria_observada_${this.inputs.form
								.get(FormularioRelatorio.AGRUPAMENTO)
								?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
						),
					},
					xAxis: [categories as Highcharts.XAxisOptions],
				};

				this.periodoTitulo = `${DateTimeUtils.formatarDataPeriodo(
					diaInicio,
					diaFim,
					periodoAgrupamento
				)}`;
			},
			complete: () => {
				this.loading = false;
				this.inputs.setLoading(false);
			},
		});
	}

	extrairDatasPrecipitacao(
		preciptacoesAgrupadas: AgrupamentoResponse[],
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacoesAgrupadas
					.map(precipitacoes => {
						/**
						 * Esse regex é necessário por questões de algum bug que acontece no backend
						 * onde a data é retornada como "2024.0" por exemplo.
						 * Esse regex é uma solução provisória para resolver esse bug.
						 */
						const data = new Date(precipitacoes.data!.replace(/\.0/g, ''));
						return DateTimeUtils.formatarDataPeriodoSemDia(
							data.setHours(data.getHours() + 3),
							'',
							periodoAgrupamento,
							true
						);
					})
					.sort((a, b) => +a - +b)
			),
		];
	}

	extrairNomes(preciptacoesAgrupadas: AgrupamentoResponse[]) {
		return [
			...new Set(
				preciptacoesAgrupadas.map(el => corrigeDuplicacaoNome(el.nome))
			),
		];
	}

	configurarNewGrafico(preciptacoesAgrupadas: AgrupamentoResponse[]) {
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		const extractedSeries = this.extrairNomes(preciptacoesAgrupadas);
		const categories = this.extrairDatasPrecipitacao(
			preciptacoesAgrupadas,
			periodo
		);

		this.chart.xAxis[0].setCategories(categories);
		const series = extractedSeries.map(serie => ({
			name: corrigeDuplicacaoNome(serie),
			data: categories.map(categoria => {
				const pluviometriaFindedByName = preciptacoesAgrupadas.find(
					agrupamento => {
						/**
						 * Esse regex é necessário por questões de algum bug que acontece no backend
						 * onde a data é retornada como "2024.0" por exemplo.
						 * Esse regex é uma solução provisória para resolver esse bug.
						 */
						const data = new Date(agrupamento.data!.replace(/\.0/g, ''));
						const dataFormatted = DateTimeUtils.formatarDataPeriodoSemDia(
							data.setHours(data.getHours() + 3),
							'',
							periodo,
							true
						);
						return (
							dataFormatted.replace(/\.0/g, '') === categoria &&
							corrigeDuplicacaoNome(agrupamento.nome) === serie
						);
					}
				);
				return pluviometriaFindedByName
					? {
							name: corrigeDuplicacaoNome(pluviometriaFindedByName.nome),
							y: +pluviometriaFindedByName.precipitacaoAcumulada.toFixed(2),
					  }
					: null;
			}),
		}));
		series.forEach(serie => this.chart.addSeries(serie as any));
	}

	getDadosTabelaParaExportacao(dados: AgrupamentoResponse[], isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas: any[] = [];
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		colunas.push({
			text: Agrupamento[agrupamento as keyof typeof Agrupamento],
			fillColor: '#DCDCDC',
		});

		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		colunas.push(
			{ text: 'Pluviometria observada (mm)', fillColor: '#DCDCDC' },
			{
				text:
					periodoAgrupamento === 'periodo'
						? 'Data'
						: periodoAgrupamento === 'mensal'
						? 'Mês/ano'
						: 'Ano',
				fillColor: '#DCDCDC',
			}
		);

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: AgrupamentoResponse) => {
			const rowData = [];

			rowData.push(
				item.nome && corrigeDuplicacaoNome(item.nome),
				item.precipitacaoAcumulada
					? numberToBrNumber(item.precipitacaoAcumulada, 1)
					: '-',
				item.data && periodoAgrupamento === 'periodo'
					? DateTimeUtils.formatarData(
							DateTimeUtils.formatToZonedDateTime(item.data),
							'dd/MM/yyyy'
					  )
					: item.data && periodoAgrupamento === 'mensal'
					? DateTimeUtils.formatarData(
							DateTimeUtils.formatToZonedDateTime(item.data),
							'MM/yyyy'
					  )
					: item.data?.substring(0, 4)
			);

			tableData.push(rowData);
		});

		return tableData;
	}

	retornarPluviometriaObservadaParaXLSX() {
		const tableData: any[] = [];
		const periodo = this.inputs.form.get('periodoBusca')?.value;

		this.response.map(resp => {
			tableData.push({
				nome: resp.nome && corrigeDuplicacaoNome(resp.nome),
				precipitacaoAcumulada: resp.precipitacaoAcumulada
					? numberToBrNumber(resp.precipitacaoAcumulada, 1)
					: '',
				data:
					resp.data && periodo === 'periodo'
						? DateTimeUtils.formatarData(resp.data, 'dd/MM/yyyy')
						: resp.data && periodo === 'mensal'
						? DateTimeUtils.formatarData(resp.data, 'MM/yyyy')
						: resp.data?.substring(0, 4),
			});
		});

		return tableData;
	}

	getXlsxHeaders(): Array<{ label: string; text: string; size: number }> {
		const headers: Array<{ label: string; text: string; size: number }> = [];
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		headers.push(
			{
				label: 'nome',
				text: Agrupamento[agrupamento as keyof typeof Agrupamento],
				size: 24,
			},
			{
				label: 'precipitacaoAcumulada',
				text: 'Pluviometria observada (mm)',
				size: 24,
			},
			{
				label: 'data',
				text:
					periodoAgrupamento === 'periodo'
						? 'Data'
						: periodoAgrupamento === 'mensal'
						? 'Mês/ano'
						: 'Ano',
				size: 24,
			}
		);

		return headers;
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];
		const agrupamento = this.inputs.form.get('agrupamento')?.value;

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: this.periodoTitulo,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const tableData = this.getDadosTabelaParaExportacao(this.response, true);

		documentDefinition.content.push(
			{
				text: '',
				alignment: 'center',
				fontSize: 10,
			},
			{
				table: {
					widths: ['35%', '35%', '30%'],
					body: tableData,
					fontSize: agrupamento != 'MUNICIPIO_POSTO' ? 12 : 10,
				},
				marginTop: 100,
			}
		);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.response, false);
		DocumentExporter.gerarCSV(tableData, this.periodoTitulo);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.response, false);
		let txtData = '';

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Pluviometria interpolada (${txtData}) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};
		const headers = this.getXlsxHeaders();
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPluviometriaObservadaParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: this.periodoTitulo,
						styleId: 'title',
					},
				},
			],
			fileName: this.periodoTitulo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
