import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutesFront } from '@core/enums/routes-front';

@NgModule({
	exports: [RouterModule],
	imports: [
		RouterModule.forChild([
			{
				path: RoutesFront.RESERVATORIOS,
				loadChildren: () =>
					import('./submodulos/reservatorio/reservatorio.module').then(
						m => m.ReservatorioModule
					),
			},
			{
				path: 'bacia',
				loadChildren: () =>
					import('./submodulos/bacia/bacia.module').then(m => m.BaciaModule),
			},
			{
				path: RoutesFront.CONFIGURACAO_CLASSIFICACAO_RESERVATORIO,
				loadChildren: () =>
					import(
						'./submodulos/configuracao-classificacao-reservatorio/configuracao-classificacao-reservatorio.module'
					).then(m => m.ConfiguracaoClassificacaoReservatorioModule),
			},
		]),
	],
})
export class RecursosHidricosRoutingModule {}
