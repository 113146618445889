import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { Observable } from 'rxjs';
import {
	ChuvasPorMicrorregiao,
	QuantisAnual,
} from '@home/submodulos/dados-meteorologicos/interfaces/quantis-anual';

@Injectable({
	providedIn: 'root',
})
export class MapasQuantisAnualService {
	constructor(private http: HttpClientProtectedService) {}
	buscarMapaQuantisAnual(
		request: QuantisAnual
	): Observable<ChuvasPorMicrorregiao[]> {
		return this.http.post<any, any>(
			Rotas.GRAFICOS + '/' + 'precipitacao/' + 'mapas-quantis-anual',
			request
		);
	}
}
