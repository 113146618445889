import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HTMLInputTypeAttribute } from '@layout/interfaces/htmlTypeInput';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';
import { InputType } from '@utils/directives/interfaces/input-type';

@Component({
	selector: 'seira-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class InputComponent implements OnInit {
	@Input() id?: string;
	@Input() label?: string;
	@Input() required = false;
	@Input() controlName!: string;
	@Input() accept?: string;
	@Input() step?: number;
	@Input() placeholder = '';
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() type: HTMLInputTypeAttribute = 'text';
	@Input() classInput = 'form-control';
	@Input() mask?: string | null = null;
	@Input() maxlength: string | number | null = null;
	@Input() minlength: string | number | null = null;
	@Input() inputmode?: string;
	@Input() inputType?: InputType;
	@Input() control!: FormControl;
	@Output() fileSelected: EventEmitter<File | null> =
		new EventEmitter<File | null>();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		if (!this.control) {
			this.control = this.controlContainer.control?.get(
				this.controlName
			) as FormControl;
		}
	}
	onFileSelected(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		const file = inputElement.files?.[0] || null;

		// Emitir o arquivo selecionado para o componente pai
		this.fileSelected.emit(file);
	}
	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}
	get seiraError() {
		if (this.control?.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				switch (erros[0]) {
					case 'required':
						if (this.control.touched) return 'Campo obrigatório.';
						break;
					case 'min':
						return (
							'O valor deve ser maior ou igual a ' +
							this.control?.errors[erros[0]]?.min
						);
					case 'max':
						return (
							'O valor deve ser menor ou igual a ' +
							this.control?.errors[erros[0]]?.max
						);
					case 'pattern':
						if (this.control?.errors[erros[0]].actualValue.includes('.')) {
							return 'É aceito apenas números com vírgulas';
						}
						return 'Está fora dos padrões necessários';
					default:
						return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
