<label class="text-tertiary w-100 text-center fw-semibold mt-3">
	Desvios - {{ periodoTitulo }}
</label>
<div class="row">
	<div
		class="d-flex flex-row justify-content-between my-3 align-items-end gap-2">
		<seira-card-info [texto]="descricaoRelatorio" />

		<seira-select-list-menu
			[opcoes]="opcoesColunas"
			[disabled]="taCarregando || !isMunicipioPosto"
			[opcoesSelecionadas]="colunasSelecionadas"
			[tituloBotao]="'Personalizar colunas'"
			(atualizarOpcoesSelecionadas)="
				setColunasSelecionadas($event)
			"></seira-select-list-menu>
	</div>
	<div
		*ngIf="taCarregando"
		style="height: 400px; width: 100%"
		class="d-flex align-items-center justify-content-center">
		<div
			class="spinner-border text-primary"
			style="width: 4rem; height: 4rem"
			role="status">
			<span class="visually-hidden">Carregando tabela...</span>
		</div>
	</div>
	<div *ngIf="!taCarregando">
		<div *ngIf="relatorios.length === 0">
			<seira-sem-dados />
		</div>
		<div *ngIf="isMunicipioPosto; else outros">
			<div>
				<div class="justify-between" *ngIf="!taCarregando">
					<label
						[for]="
							'tabela-relatorio-desvios-' +
							getTituloTabela(relatoriosDTO[0]).toLowerCase()
						"
						class="text-tertiary mb-2 fw-semibold">
						{{ getTituloTabela(relatoriosDTO[0]) }}
					</label>
				</div>
				<div class="mb-4">
					<seira-public-table
						[id]="
							'tabela-relatorio-desvios-' +
							getTituloTabela(relatoriosDTO[0]).toLowerCase()
						"
						[hasFooter]="true"
						[hasHeader]="false"
						[loading]="taCarregando"
						[info]="true"
						[paging]="true"
						[searching]="false"
						[scrollX]="true"
						[spaceReduceTh]="true"
						[columns]="colunasDesvios"
						[order]="[2, 'asc']"
						[data]="relatoriosDTO || []"></seira-public-table>
				</div>
			</div>
		</div>
		<ng-template #outros>
			<div *ngFor="let relatorio of relatorios">
				<div class="justify-between" *ngIf="!taCarregando">
					<label
						[for]="
							'tabela-relatorio-desvios-' +
							getTituloTabela(relatorio).toLowerCase()
						"
						class="text-tertiary mb-2 fw-semibold">
						{{ getTituloTabela(relatorio) }}
					</label>
				</div>
				<div class="mb-4">
					<seira-public-table
						[id]="
							'tabela-relatorio-desvios-' +
							getTituloTabela(relatorio).toLowerCase()
						"
						[hasFooter]="true"
						[hasHeader]="false"
						[loading]="taCarregando"
						[info]="true"
						[paging]="true"
						[searching]="false"
						[scrollX]="true"
						[spaceReduceTh]="true"
						[columns]="colunasDesvios"
						[order]="[2, 'asc']"
						[data]="relatorio.desvios || []"></seira-public-table>
				</div>
			</div>
		</ng-template>
	</div>
</div>
