<div>
	<form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-4 col-xl-3">
				<div class="col-lg-11 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="opcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"></seira-public-radio-group>
				</div>
				<div class="col-lg-11 mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo">
					</seira-public-select>
				</div>

				<div
					class="col-lg-11 mt-1"
					*ngIf="mostrarSelectVariaveisPluviometricas">
					<seira-public-select
						[options]="opcoesVariaveisMapa"
						optionLabel="label"
						optionValue="value"
						style="width: 100%"
						label="Variável"
						placeholder="Selecione uma variável"
						[formControlName]="FormularioRelatorio.VARIAVEL_MAPA" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectAgrupamento">
					<seira-public-select
						placeholder="selecione um agrupamento"
						label="Agrupamento"
						[options]="optionsAgrupamento"
						optionValue="value"
						optionLabel="name"
						formControlName="agrupamento">
					</seira-public-select>
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectPostos">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Município/Posto"
						[searchable]="true"
						[options]="municipioPostos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao">
					</seira-public-select>
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectPostosSudene">
					<seira-public-select
						placeholder="Selecione um posto Sudene"
						label="Posto Sudene"
						[searchable]="true"
						[options]="postosSudene"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum posto encontrado"
						formControlName="postoSudene">
					</seira-public-select>
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMicrorregioes">
					<seira-public-select
						placeholder="Selecione uma microrregião"
						label="Microrregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao"
						[searchable]="true"
						[options]="microrregioes">
					</seira-public-select>
				</div>

				<!-- DEPRECATED -->
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectRegioes">
					<seira-public-select
						placeholder="Selecione uma região pluviométrica"
						label="Região pluviométrica"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma região pluviométrica encontrada"
						formControlName="regiao"
						[searchable]="true"
						[options]="regioes">
					</seira-public-select>
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectBacias">
					<seira-public-select
						placeholder="Selecione uma bacia"
						label="Bacia"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma bacia encontrada"
						formControlName="bacia"
						[searchable]="true"
						[options]="bacias">
					</seira-public-select>
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMesorregioes">
					<seira-public-select
						placeholder="Selecione uma mesorregião"
						label="Mesorregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma mesorregião encontrada"
						formControlName="mesorregiao"
						[searchable]="true"
						[options]="mesorregioes">
					</seira-public-select>
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMunicipios">
					<seira-public-select
						placeholder="Selecione um município"
						label="Município"
						optionValue="id"
						optionLabel="nome"
						notFoundText="Nenhum município encontrado"
						formControlName="municipio"
						[searchable]="true"
						[options]="municipios">
					</seira-public-select>
				</div>

				<div class="col-lg-12 col-md-4 col-sm-6" *ngIf="mostrarPeriodoBusca">
					<seira-public-radio-group
						[controlName]="FormularioRelatorio.PERIODO_BUSCA"
						[options]="opcoesPeriodo"></seira-public-radio-group>
				</div>

				<ng-container *ngIf="relatorioAtual?.periodos && labelAdptativaPeriodo">
					<ng-container
						*ngIf="labelAdptativaPeriodo.mensal_por_nome; else unicoPicker">
						<div class="col-lg-11 d-flex flex-column">
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês inicial"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesInicio"
								[searchable]="true"
								[options]="meses">
							</seira-public-select>
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês final"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesFim"
								[searchable]="true"
								[options]="meses">
							</seira-public-select>
						</div>
					</ng-container>

					<ng-template #unicoPicker>
						<ng-container *ngIf="labelAdptativaPeriodo.unico; else rangePicker">
							<div class="col-lg-11 d-flex flex-column mt-1">
								<seira-public-date-picker
									class="w-100 mt-1"
									controlName="periodo"
									[label]="labelAdptativaPeriodo.unico.label"
									[placeholder]="labelAdptativaPeriodo.unico.placeholder || ''"
									[datePickerType]="
										labelAdptativaPeriodo.unico.dataType || 'month'
									"
									[tipoFormulario]="getFormItemValue('tipo')" />
							</div>
						</ng-container>
					</ng-template>

					<ng-template #rangePicker>
						<div class="col-lg-11 d-flex flex-column mt-1">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataInicio"
								[label]="labelAdptativaPeriodo.inicio?.label"
								[placeholder]="labelAdptativaPeriodo.inicio?.placeholder || ''"
								[datePickerType]="
									labelAdptativaPeriodo.inicio?.dataType || 'year'
								"
								[tipoFormulario]="getFormItemValue('tipo')" />
						</div>
						<div class="col-lg-11 d-flex flex-row align-items-end">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataFim"
								[label]="labelAdptativaPeriodo.fim?.label"
								[placeholder]="labelAdptativaPeriodo.fim?.placeholder || ''"
								[datePickerType]="labelAdptativaPeriodo.fim?.dataType || 'year'"
								[minDate]="dataInicio"
								[tipoFormulario]="getFormItemValue('tipo')"
								[maxDate]="limiteDataFimSuperior" />
						</div>
					</ng-template>
				</ng-container>

				<div
					*ngIf="mostrarRadioPostos"
					class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-end mt-2 gap-3">
					<div class="d-flex flex-column flex-md-row align-items-md-end gap-3">
						<div>
							<seira-public-radio-group
								classRadio="flex-row d-flex gap-3 align-items-center"
								idRadio="opcoesPostosRelatorios"
								[options]="opcoesPostos"
								label="Postos"
								controlName="posto">
							</seira-public-radio-group>
						</div>
					</div>
				</div>

				<div
					class="col-lg-11 mt-1 d-flex justify-content-between align-items-end"
					style="margin-top: 1rem !important">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>

					<seira-button
						*ngIf="
							getFormItemValue('posto') === 'personalizado' &&
							mostrarRadioPostos
						"
						classButton="btn btn-link p-0"
						type="button"
						buttonStyle="custom"
						(clicked)="openModal()"
						>Editar...</seira-button
					>
				</div>
			</div>
			<div class="col-lg-6">
				<div
					class="d-flex flex-colum justify-content-end mb-2"
					*ngIf="
						relatorioService.getExibirBotoesExportacao() && form.value.tipo
					">
					<seira-public-button-group
						[buttons]="botoesDeExportacao"
						[disabledButtons]="loadingForm"></seira-public-button-group>
				</div>

				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>

		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar</seira-public-button
			>
		</ng-template>
	</form>
</div>
