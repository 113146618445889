import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import {
	Estacao,
	Municipio,
} from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import {
	Agrupamento,
	Meses,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import {
	capitalizeFirstLetter,
	compararStrings,
	numberToBrNumber,
} from '@utils';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject, Subscription } from 'rxjs';
import { corrigeDuplicacaoNome } from '../../../utils';
import { Select } from '@layout/interfaces/select';

@Component({
	selector: 'seira-tabela-climatologia',
	templateUrl: './tabela-climatologia.component.html',
	styleUrls: ['./tabela-climatologia.component.scss'],
})
export class TabelaClimatologiaComponent
	implements OnDestroy, InstanciaRelatorio
{
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaClimatologia?: ClimatologiaResponseDTO[];
	private subscription = new Subscription();
	loading = true;
	periodo = '';
	tipoAgrupamento: Agrupamento = Agrupamento.MUNICIPIO_POSTO;
	descricaoRelatorio =
		'Define-se como climatologia pluviométrica, a média histórica de pluviometria para períodos longos, preferencialmente de 30 anos.';

	dadosColunas: any[] = [];
	colunas: ADTColumns[] = [];
	_destroyed = new Subject();

	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaClimatologia', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];

	meses = [
		{ n: 1, mes: 'Janeiro' },
		{ n: 2, mes: 'Fevereiro' },
		{ n: 3, mes: 'Março' },
		{ n: 4, mes: 'Abril' },
		{ n: 5, mes: 'Maio' },
		{ n: 6, mes: 'Junho' },
		{ n: 7, mes: 'Julho' },
		{ n: 8, mes: 'Agosto' },
		{ n: 9, mes: 'Setembro' },
		{ n: 10, mes: 'Outubro' },
		{ n: 11, mes: 'Novembro' },
		{ n: 12, mes: 'Dezembro' },
	];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV();
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT();
			},
		},
	];

	disabledSelectList = false;

	tituloColunaAgrupamento = 'Nome';

	constructor(
		private toastrService: ToastrService,
		private climatologiaService: RelatoriosService
	) {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
		this.setValidators();
	}
	form?: FormGroup<any> | undefined;
	regioes?: Select<string>[] | undefined;
	microrregioes?: Select<string>[] | undefined;
	mesorregioes?: Select<string>[] | undefined;
	municipios?: Municipio[] | undefined;
	precipitacao?: Select<string>[] | undefined;
	agrupamento?: Select<string>[] | undefined;

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.MES_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MES_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	atualizarTituloColuna() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloColunaAgrupamento = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloColunaAgrupamento = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloColunaAgrupamento = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloColunaAgrupamento = 'Mesorregião';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloColunaAgrupamento = 'Região Pluviométrica';
				break;
			case 'BACIA':
				this.tituloColunaAgrupamento = 'Bacia';
				break;
			case 'SUB_BACIA':
				this.tituloColunaAgrupamento = 'Sub bacia';
				break;
		}
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: null,
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-left',
					render: (data: ClimatologiaResponseDTO) =>
						corrigeDuplicacaoNome(data.dadosPosto.municipio + '/' + data.nome),
				},
				{
					data: 'dadosPosto.latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},
				{
					data: 'dadosPosto.longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 4) : '-'),
				},
				{
					data: 'dadosPosto.altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? numberToBrNumber(data, 1) : '-'),
				},
				{
					data: 'dadosPosto.microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
				{
					data: 'dadosPosto.mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'dadosPosto.regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
				{
					data: 'dadosPosto.bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
				{
					data: 'dadosPosto.subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'nmes',
					title: 'Mês',
					className: 'text-center',
					type: 'number',
					render: (data: number, type: string) => {
						return type === 'display' ? this.meses[data - 1]?.mes : data;
					},
				},
				{
					data: 'climatologia',
					render: (data: number) => {
						return numberToBrNumber(data, 1);
					},
					title: 'Climatologia (mm)',
					className: 'text-center',
				},
			]
		);
	}

	gerarRelatorio(): void {
		if (this.inputs.form.invalid) {
			return;
		}

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);

		const mesInicioFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesInicio)
		);
		const mesFimFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesFim)
		);

		this.periodo = ` ${mesInicioFormatado} a ${mesFimFormatado}`;
		this.tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;

		this.climatologiaService
			.buscarClimatologiaPorMunicipio(
				mesInicio.toLowerCase(),
				mesFim.toLowerCase(),
				this.tipoAgrupamento
			)
			.subscribe({
				next: climatologias => {
					const agrupamento: string = this.tipoAgrupamento;
					if (agrupamento == 'MUNICIPIO_POSTO') {
						this.dadosColunas = this.ordenaMes(climatologias, false);
					} else {
						this.dadosColunas = this.ordenaMes(climatologias, true);
					}
					this.dadosTabelaClimatologia = this.dadosColunas;
					this.atualizarTituloColuna();
					this.atualizarColunasTabela();
				},
				error: err => {
					this.toastrService.error(
						'Erro ao gerar tabela de Climatologia, tente novamente'
					);
					this.inputs.setLoading(false);
					this.loading = false;
					this.dadosTabelaClimatologia = undefined;
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.loading = false;
				},
			});
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	async exportarTXT() {
		let txtData = '';

		const tableData = this.prepararDadosParaTabela();

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}
		DocumentExporter.gerarTXT(
			txtData,
			`Relatório de climatologia -(${this.periodo})`
		);
	}

	exportarCSV() {
		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			DocumentExporter.gerarCSV(
				tableData,
				`Relatório de climatologia -(${this.periodo})`
			);
		}
	}

	async exportarPDF() {
		const documentDefinition: any = await this.criarDefinicaoDocumentoPDF();

		documentDefinition.pageOrientation = 'landscape';

		this.adicionarTitulo(documentDefinition);

		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			this.adicionarTabelaAoDocumento(documentDefinition, tableData);
		} else {
			this.adicionarMensagemSemDados(documentDefinition);
		}

		this.gerarDocumentoPDF(documentDefinition);
	}

	private criarDefinicaoDocumentoPDF(): any {
		return pdfseira.documentDefinitions('landscape');
	}

	private adicionarTitulo(documentDefinition: any): void {
		documentDefinition.content.push({
			text: `Relatório - Climatologia - ${this.periodo}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});
	}

	private temDadosParaTabela(): boolean {
		return !!this.dadosTabelaClimatologia && !!this.tabela;
	}

	private ordenaMes(dados: any[], possuiNome: boolean) {
		dados.forEach(item => {
			if (item.mes === 'marco') {
				item.mes = 'Março';
			} else {
				item.mes = capitalizeFirstLetter(item.mes);
			}

			const mesEncontrado = this.meses.find(m => m.mes === item.mes);
			if (mesEncontrado) {
				item.nmes = mesEncontrado.n;
			}
		});

		const dadosOrdenados = dados.sort((a, b) => {
			const municipioA = possuiNome ? a?.nome : a?.dadosPosto?.municipio;
			const municipioB = possuiNome ? b?.nome : a?.dadosPosto?.municipio;
			const nomeComparacao = municipioA.localeCompare(municipioB);

			if (nomeComparacao !== 0) {
				return nomeComparacao;
			}

			return a.nmes - b.nmes;
		});

		return dadosOrdenados;
	}

	private prepararDadosParaTabela(): string[][] {
		const visibleColumns = this.getVisibleColumns();
		const tableData = [visibleColumns];

		this.tabela?.data.forEach((row: any) => {
			const rowData = this.prepararDadosDaLinha(row, visibleColumns);
			tableData.push(rowData);
		});

		return tableData;
	}

	private getVisibleColumns(): string[] {
		return this.tabela!.columns.map(col => col.title as string);
	}

	private prepararDadosDaLinha(row: any, visibleColumns: string[]): any[] {
		return visibleColumns.map(columnTitle => {
			if (columnTitle === 'Município/Posto') {
				return corrigeDuplicacaoNome(row.nome);
			} else if (columnTitle === 'Latitude' || columnTitle === 'Longitude') {
				const value = this.getCellValue(row, columnTitle);
				const response = value !== '-' ? Number(value).toFixed(4) : value;
				return response;
			} else if (columnTitle === 'Climatologia (mm)') {
				const value = this.getCellValue(row, columnTitle);
				const response = value !== '-' ? numberToBrNumber(value, 1) : value;
				return response;
			} else {
				return this.getCellValue(row, columnTitle);
			}
		});
	}

	private getCellValue(row: any, columnTitle: string): string {
		const column = this.tabela!.columns.find(col => {
			return col.title === columnTitle;
		});
		if (column && column.data === 'nmes') {
			return this.extractNestedValue(row, 'mes') ?? '-';
		} else if (column && column.data) {
			return this.extractNestedValue(row, column.data as string) ?? '-';
		}
		return '-';
	}

	private extractNestedValue(obj: any, path: string): any {
		return path.split('.').reduce((value, key) => value?.[key], obj);
	}

	private adicionarTabelaAoDocumento(
		documentDefinition: any,
		tableData: string[][]
	): void {
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: 'lightHorizontalLines',
			},
		});
	}

	private adicionarMensagemSemDados(documentDefinition: any): void {
		documentDefinition.content.push({
			text: 'Nenhum dado encontrado na tabela',
			alignment: 'center',
			fontSize: 10,
			margin: [0, 10],
		});
	}

	private gerarDocumentoPDF(documentDefinition: any): void {
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}
}
